const pcMaxWidth = 1200;
const mobileMaxWidth = 768;

// eslint-disable-next-line no-shadow
export enum ScreenType {
  Large = 0,
  Small = 1,
}

export function logicWidth() {
  return document.documentElement.clientWidth || document.body.clientWidth;
}

export function isLarge() {
  return logicWidth() >= mobileMaxWidth;
}

export function isSmall() {
  return logicWidth() <= mobileMaxWidth;
}

export function getContentWidth() {
  if (isSmall()) {
    return logicWidth();
  }
  return logicWidth() > pcMaxWidth ? pcMaxWidth : logicWidth();
}
