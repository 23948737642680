import React from 'react';
import className from './Index.module.scss';
import title from '../../assets/parnter/parnterText.png';

import bankIcon from '../../assets/parnter/1.png';
import jdIcon from '../../assets/parnter/2.png';
import kuaishouIcon from '../../assets/parnter/3.png';
import zfbIcon from '../../assets/parnter/4.png';
import huaweiIcon from '../../assets/parnter/5.png';

import xiaomiIcon from '../../assets/parnter/6.png';
import softwareIcon from '../../assets/parnter/7.png';
import oppoIcon from '../../assets/parnter/8.png';
import vivoIcon from '../../assets/parnter/9.png';
import honorIcon from '../../assets/parnter/10.png';

import pandaIcon from '../../assets/parnter/11.png';
import loveIcon from '../../assets/parnter/12.png';
import gugongIcon from '../../assets/parnter/13.png';
import yscIcon from '../../assets/parnter/14.png';
import corpsIcon from '../../assets/parnter/15.png';

const partOne = [bankIcon, jdIcon, kuaishouIcon, zfbIcon, huaweiIcon, xiaomiIcon, softwareIcon, oppoIcon];
const partTwo = [vivoIcon, honorIcon, pandaIcon, loveIcon, gugongIcon, yscIcon, corpsIcon];

function Parnter() {
  const renderIcon = (icon: Array<Any>) => {
    return icon.map((item) => {
      return (
        <div className={className.iconItem} key={item}>
          <img src={item} alt='' />
        </div>
      );
    });
  };
  return (
    <div className={className.parnter}>
      <div className={className.top}>
        <div className={className.parnterTextContainer}>
          <img src={title} alt='' />
        </div>
        <div className={className.iconRow}>
          {
            renderIcon(partOne)
          }
        </div>
      </div>
      <div className={className.iconRow}>
        {
          renderIcon(partTwo)
        }
      </div>
    </div>
  );
}

export default Parnter;
