import { useEffect, useMemo } from 'react';
import { debounce } from 'underscore';

export default function useWindowResize(callback: () => void) {
  const handler = useMemo(() => debounce(() => {
    callback();
  }, 160), [ callback ]);

  useEffect(() => {
    window.addEventListener('resize', handler, false);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [ handler ]);
}
