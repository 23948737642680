/* eslint-disable max-len */
import React from 'react';

import classes from './Permissions.module.scss';

function Permissions() {
  return (
    <div className={classes.help}>
      <div className={classes.mainSection}>
        <div className={classes.privacyContent}>
          <h4 className={classes.title}>
            应用权限申请与使用情况说明
          </h4>
          <p>
            1、为保障产品或服务能实现与安全稳定运行之目的，我们可能会申请或使用操作系统的相关权限；
            <br />
            2、为保障您的知情权，我们通过下列列表将产品或服务可能申请、使用的相关操作系统权限进行展示，您可以根据实际需要对相关权限进行管理；
            <br />
            3、根据产品或服务的升级，申请、使用权限的类型与目的可能会有变动，我们将及时根据这些变动对列表进行调整，以确保您及时获悉权限的申请与使用情况；
            <br />
            4、本说明适用于爱回收APP，具体的适用范围将在以下列表中说明。
            <br />
          </p>
          <p>
            <strong>安卓操作系统应用权限列表</strong>
            <br />
            权限类型：位置权限
            <br />
            权限名称：android.permission.ACCESS_FINE_LOCATION(精准位置)； android.permission.ACCESS_COARSE_LOCATION(大概位置) ；android.permission.ACCESS_LOCATION_EXTRA_COMMANDS（允许访问位置命令）
            <br />
            功能/目的：获取您当时所处的地理位置
            <br />
            使用场景：为您提供本地化服务及附近爱回收门店距离信息
            <br />
          </p>
          <p>
            权限类型：摄像头（相机）
            <br />
            权限名称：android.permission.CAMERA
            <br />
            功能/目的：使用拍摄视频、照片、扫码
            <br />
            使用场景：供用户进行回收物品的拍摄、线下回收机扫码开门，或与在线客服沟通时拍摄视频、照片
            <br />
          </p>
          <p>
            权限类型：相册
            <br />
            权限名称：android.permission.WRITE_EXTERNAL_STORAGE(读取存储)；android.permission.READ_EXTERNAL_STORAGE（写入存储）；
            <br />
            功能/目的：上传照片/视频
            <br />
            使用场景：供用户进行回收物品情况拍摄上传及评价内上传图片功能
            <br />
          </p>
          <p>
            权限类型：外置存储器
            <br />
            权限名称：android.permission.WRITE_EXTERNAL_STORAGE(读取存储)；android.permission.READ_EXTERNAL_STORAGE（写入存储）
            <br />
            功能/目的：读取或向其中写入图片、文件、崩溃日志信息等必要信息
            <br />
            使用场景：允许读取或者写入图片、文件等内容，用于提供信息发布或在本地记录崩溃日志信息等功能，保障客户端的稳定运行
            <br />
          </p>
          <p>
            权限类型：剪切板
            <br />
            功能/目的：访问剪切板内容
            <br />
            使用场景：复制、分享商品信息、标题、短信验证码
            <br />
          </p>
          <p>
            权限名称：android.permission.INTERNET
            <br />
            功能/目的：访问网络权限
            <br />
            使用场景：实现应用程序联网
            <br />
          </p>
          <p>
            权限名称：android.permission.ACCESS_WIFI_STATE
            <br />
            功能/目的：获取WiFi状态权限
            <br />
            使用场景：监控网络变化，提示用户当前网络环境
            <br />
          </p>
          <p>
            权限名称：android.permission.ACCESS_NETWORK_STATE
            <br />
            功能/目的：获取网络状态权限
            <br />
            使用场景：监控网络变化，提示用户当前网络环境
            <br />
          </p>
          <p>
            权限名称：android.permission.CHANGE_NETWORK_STATE
            <br />
            功能/目的：改变网络连接状态
            <br />
            使用场景：允许应用改变网络连接状态
            <br />
          </p>
          <p>
            权限名称：android.permission.REQUEST_INSTALL_PACKAGES
            <br />
            功能/目的：允许安装应用
            <br />
            使用场景：用户升级app
            <br />
          </p>
          <p>
            <strong>IOS操作系统应用权限列表</strong>
            <br />
            权限类型：位置
            <br />
            权限名称：NSLocationAlwaysUsageDescription（持续获取）；NSLocationWhenInUseUsageDescription(使用App期间)
            <br />
            功能/目的：获取您当时所处的地理位置
            <br />
            使用场景：为您提供本地化服务及附近爱回收门店距离信息
            <br />
          </p>
          <p>
            权限类型：摄像头（相机）
            <br />
            权限名称：NSCameraUsageDescription
            <br />
            功能/目的：使用拍摄视频、照片、扫码
            <br />
            使用场景：供用户进行回收物品的拍摄、线下回收机扫码开门，或与在线客服沟通时拍摄视频、照片
            <br />
          </p>
          <p>
            权限类型：相册
            <br />
            权限名称：NSPhotoLibraryAddUsageDescription(相册添加)；NSPhotoLibraryUsageDescription(相册使用)
            <br />
            功能/目的：上传照片/视频，保存图片
            <br />
            使用场景：供用户进行回收物品情况拍摄上传及评价内上传图片功能
            <br />
          </p>
          <p>
            权限类型：IDFA
            <br />
            权限名称：NSUserTrackingUsageDescription
            <br />
            功能/目的：获取广告标识符
            <br />
            使用场景：用于标识设备
            <br />
          </p>
          <p>
            权限类型：剪切板
            <br />
            权限名称：UIPasteboard
            <br />
            功能/目的：访问剪切板内容
            <br />
            使用场景：复制、分享商品信息、标题、短信验证码
            <br />
          </p>
          <p>
            权限类型：通知
            <br />
            权限名称：UserNotifications.framework
            <br />
            功能/目的：推送通知
            <br />
            使用场景：用户开启接收通知开关后推送重要通知提醒
            <br />
          </p>
          <p>
            <strong>鸿蒙操作系统应用权限列表</strong>
            <br />
            权限类型：位置
            <br />
            权限名称：ohos.permission.LOCATION(精准位置)； ohos.permission.APPROXIMATELY_LOCATION(模糊位置)
            <br />
            功能/目的：获取您当时所处的地理位置
            <br />
            使用场景：为您提供本地化服务及附近爱回收门店距离信息
          </p>
          <p>
            权限类型：摄像头（相机）
            <br />
            权限名称：ohos.permission.CAMERA
            <br />
            功能/目的：使用拍摄视频、照片、扫码
            <br />
            使用场景：供用户进行回收物品的拍摄、线下回收机扫码开门，或与在线客服沟通时拍摄视频、照片
            <br />
          </p>
          <p>
            权限类型：剪切板
            <br />
            功能/目的：访问剪切板内容
            <br />
            使用场景：复制、分享商品信息、标题、短信验证码
          </p>
          <p>
            权限名称：ohos.permission.INTERNET
            <br />
            功能/目的：访问网络权限
            <br />
            使用场景：实现应用程序联网
          </p>
          <p>
            权限名称：ohos.permission.GET_WIFI_INFO
            <br />
            功能/目的：获取WiFi状态权限
            <br />
            使用场景：监控网络变化，提示用户当前网络环境
          </p>
          <p>
            权限名称：ohos.permission.GET_NETWORK_INFO
            <br />
            功能/目的：获取网络状态权限
            <br />
            使用场景：监控网络变化，提示用户当前网络环境
          </p>
        </div>
      </div>
    </div>
  );
}

export default Permissions;
