import React from 'react';
import className from './Index.module.scss';
import title from '../../assets/gongyi/title.png';
import img1 from '../../assets/gongyi/img-1.png';
import img2 from '../../assets/gongyi/img-2.png';
import voco from '../../assets/gongyi/voco.png';
import kaide from '../../assets/gongyi/kaide.png';

function Charity() {
  return (
    <React.Fragment>
      <div className={className.background}>
        <div className={className.title}>
          <img src={title} alt='' />
        </div>
        <div className={className.content}>
          <div className={className.item}>
            <img src={img1} alt='' className={className.left} />
            <div className={className.desc}>
              <div className={className.descTitle}>成立「宇宙回收联盟」，共同守护蓝色星球</div>
              <div className={className.descText}>2023年4月，爱回收联合多个可持续消费品牌及环保公益机构，成立「宇宙回收联盟」，以联盟的形式共同倡导绿色消费。</div>
            </div>
          </div>
          <div className={className.line} />
          <div className={className.item}>
            <div className={className.desc}>
              <div className={className.descTitle}>与全棉时代一起「回收万物」</div>
              <div className={className.descText}>2023年4月，爱回收牵手全棉时代，将旧衣回收箱投放至全棉时代10家门店，共同鼓励旧衣回收行动。</div>
            </div>
            <img src={img2} alt='' className={className.left} />
          </div>
          <div className={className.item} />
        </div>
      </div>
      <div className={className.bottom}>
        <div className={className.bottomContainer}>
          <div className={className.bottomItem}>
            <img src={voco} alt='' />
            <div className={className.rightContent}>
              <div className={className.title}>在voco酒店「回收万物」</div>
              <div className={className.desc}>
                2022年9月，爱回收与洲际酒店集团旗下voco酒店合作，定制回收机落地6家酒店，酒店住户可通过回收行为兑换环保好物。
              </div>
            </div>
          </div>
          <div className={className.bottomItem}>
            <img src={kaide} alt='' />
            <div className={className.rightContent}>
              <div className={className.title}>在凯德地产「回收万物」</div>
              <div className={className.desc}>
                2023年3月，爱回收助力凯德2023“Eco Green自然有能量”主题活动，3大城市8个商场联动，为消费者带来绿色消费新体验。
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Charity;
