/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { ossImg } from '../../utils/oss';
import classes from './Index.module.scss';

function PrivacyV16() {
  return (
    <div className={classes.help}>
      <div className={classes.mainSection}>
        <div className={classes.privacyContent}>
          <p>
            <strong>版本日期：2023 年 05 月 19 日</strong>
            <br />
            <strong>生效日期：2023 年 05 月 26 日</strong>
          </p>
          <p>
            尊敬的爱回收用户：我们对《爱回收隐私政策》进行了更新，此版本更新主要集中在文案汇总、归纳及优化，以便于您更加清晰、直观了解个人信息的保护政策。
          </p>
          <p>
            <strong>
              【特别提示】
            </strong>
            <br />
            <strong>
              本政策仅适用于上海万物新生环保科技集团有限公司及其关联公司（包括但不限于上海悦亿网络信息技术有限公司、常州悦亿网络信息技术有限公司，以下或称“我们”或“爱回收”）提供的产品和服务及其延伸的功能（以下简称“爱回收服务”），包括爱回收APP、网站、客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。如果我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用，该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。如果我们关联公司的产品或服务中使用了爱回收提供的产品或服务但未设置独立隐私政策的，则本隐私政策同样适用于该部分产品或服务。
            </strong>
            <br />
            <strong>请仔细阅读《爱回收隐私政策》（尤其是粗体内容）并确定了解我们对您个人信息的处理规则。</strong>
            阅读过程中，如果您有任何疑问，您可通过以下联系方式与我们联系：
            <br />
            联系电话：
            <strong>4001616365</strong>
            <br />
            注册地址：
            <strong>上海市杨浦区淞沪路 433 号 1101-1103 室</strong>
            <br />
            常用办公地址：

            <strong>上海市杨浦区淞沪路 433 号 1101-1103 室</strong>
            <br />
            <br />
          </p>
          <p>
            <strong>
              ※ 根据《常见类型移动互联网应用程序必要个人信息范围规定》，本APP属于“本地生活类”，基本功能服务为“二手闲置物品交易”，此功能下必要个人信息为：注册用户移动电话号码。
            </strong>
          </p>
          <p>
            <strong>
              ※ 您同意隐私政策仅代表您已了解应用提供的功能，以及功能运行所需的必要个人信息，并不代表您已同意我们可以收集非必要个人信息，非必要个人信息会根据您使用过程中的授权情况单独征求您的同意。
            </strong>
          </p>
          <p>
            <strong>
              ※ 相应设备权限并不会默认开启，当涉及重要或敏感的设备权限时，我们会在您使用到相应业务功能时，另行弹窗再次征得您的同意后开启；权限开启后，您还可以随时通过设备设置关闭权限；你不同意开启权限，将不会影响其他非相关业务功能的正常使用。针对个人敏感信息，我们会单独征得您的同意后进行处理。
            </strong>
          </p>
          <p>
            <strong>
              ※ 如果您拒绝本《隐私政策》，您仍然能够在无注册/登录状态下使用浏览、查看的基本功能。您也可以在后续使用服务过程中单独进行授权。
            </strong>
          </p>
          <h3>引言</h3>
          <p>
            <strong>
              欢迎您使用爱回收！我们非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们会收集和使用您的相关信息。我们希望通过《爱回收隐私政策》（“本隐私政策”）向您说明在您使用我们的产品与/或服务时我们如何收集、使用、保存、共享和转移这些信息，以及我们为您提供的访问、更新、获取、删除和保护这些信息的方式。
            </strong>
          </p>
          <p>
            本隐私权政策适用于爱回收所提供的产品及服务。但不适用于爱回收网站上由其他第三方向您提供的服务。
          </p>
          <p>
            <strong>本政策将帮助您了解以下内容：</strong>
            <br />
            <span>1 、我们如何收集和使用您的个人信息</span>
            <br />
            <span>2 、我们如何使用 Cookie 和同类技术</span>
            <br />
            <span>3 、我们如何共享、转让、公开披露您的个人信息</span>
            <br />
            <span>4 、我们如何保护和保存您的个人信息</span>
            <br />
            <span>5 、您如何管理个人信息</span>
            <br />
            <span>6 、未成年人的个人信息保护</span>
            <br />
            <span>7 、通知和修订</span>
            <br />
            <span>8 、如何联系我们</span>
          </p>
          <h4>
            一、我们如何收集和使用您的个人信息
          </h4>
          <p>
            <strong>个人信息</strong>
            是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。
            <strong>敏感个人信息</strong>
            是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹信息，以及不满十四周岁未成年人的个人信息。
          </p>
          <p>
            我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用产品与/或服务过程中主动提供或因使用产品与/或服务而产生的个人信息，以及从第三方获取您的相关个人信息。
            <strong>如果我们要将您的个人信息用于本政策未载明的其他用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。</strong>
          </p>
          <p>
            <strong>（一）您须授权我们收集和使用您个人信息的情形</strong>
          </p>
          <p>
            我们的产品与/或服务包括一些核心功能，这些功能包含了实现网上二手物品交易及购物所必须的功能、改进我们的服务所必须的功能及保障交易安全所必须的功能。
            <strong>
              我们会收集和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括:
            </strong>
          </p>
          <p>
            1 、
            <strong>登录、注册</strong>
          </p>
          <p>
            <strong>A. 个人信息使用范围</strong>
          </p>
          <p>
            （1）注册：您首先需要注册一个爱回收账户成为爱回收用户。
            <strong>当您注册爱回收账号时，您需要至少向我们提供您准备使用的爱回收账户名、密码、您本人的手机号码、电子邮箱地址(用于验证邮箱)，我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。</strong>
          </p>
          <p>
            <strong>请您注意，若您提供的手机号码或邮箱未在本平台进行注册过，我们将通过快速注册方式直接为您注册爱回收账号并为您登录。</strong>
          </p>
          <p>
            （2）登录：当您使用登录功能时，您需要向我们提供您注册时的手机号码、邮箱，并在输入验证码后进行登录。当您使用手机号、邮箱进行登录时，手机号、邮箱属于登录功能的必需信息，若您不提供您将无法正常登录。
          </p>
          <p>
            <strong>B. 使用第三方SDK</strong>
          </p>
          <p>
            <strong>一键登录：当您使用一键登录功能时，将通过中国移动、中国联通、中国电信的一键登录SDK来实现，</strong>
            SDK将直接收集您的
            <strong>设备网络信息（ip、网络制式、网络类型、运营商类型、网络类型、网络制式、私网IP、网络连接状态），设备标识信息（ICCID），设备基本信息（设备名称、机器型号、系统版本、bundleId、当前时间戳、屏幕宽高、设备系统版本、应用包名、应用包签名、subId），</strong>
            此类信息属于实现该SDK功能必需的信息。（根据运营商类型不同，分别应用不同的移动SDK、联通SDK、电信SDK）
          </p>
          <p>
            2 、
            <strong>电商功能</strong>
          </p>
          <p>
            <strong>A. 个人信息使用范围</strong>
          </p>
          <p>
            <strong>（1）产品信息展示和搜索</strong>
          </p>
          <p>
            <strong>在您使用我们服务过程中，为了让您快速地找到附近的爱回收门店或您所需要交易的商品，我们会请求您提供或收集您在访问或使用我们的产品/服务时产生的相关信息，包括：</strong>
          </p>
          <p>
            <strong>
              位置信息：
            </strong>
            当您通过系统授权开启移动设备的定位功能并使用基于位置提供的服务时，我们会收集和使用您的位置信息来判断您所处的地点，为您匹配所在区域适当的服务方式，您所在区域有爱回收门店的，爱回收将自动为您展示您所在区域距离您最近可以提供服务的门店。我们会使用有关技术获取您的位置信息（准确度会有所不同），这些技术包括IP 地址、GPS 以及能够提供相关信息的WLAN接入点、蓝牙和基站。您可以在移动设备的系统中关闭定位服务停止我们对您所在位置信息的收集，但因此无法使用我们基于地理位置为您提供的服务，或者无法达到相关服务的预期效果。
          </p>
          <p>
            <strong>设备信息：</strong>
            经您授权后，我们会在静默/后台状态中接收并记录
            <strong>设备相关信息（包括设备名称、设备型号、MAC 地址及 IMEI、操作系统和应用程序版本、设备设置、设备广告标识符、设备识别码(OAID/IDFA/IDFV/SIM 卡信息/IMSI/Android_ID)、服务提供商网络 ID(PLMN)、WI-FI 信息、应用进程列表、移动应用列表、分辨率、软硬件及设备环境信息）来对相应的产品或者服务内容进行排序，以适应您的设备界面。</strong>
          </p>
          <p>
            <strong>关键字及剪贴板内容：</strong>
            当您使用爱回收提供的搜索功能时，为方便您再次搜索相同的内容我们会记录和存储
            <strong>您查询的关键字信息，该关键词信息通常无法单独识别您的个人身份，不属于您的个人信息，不在本指引的规范范围内。只有当您搜索过的关键词信息与您的其他信息有联结并可识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，按照本指引对其进行处理与保护。</strong>
          </p>
          <p>
            在您分享或接收被分享的信息、参加活动等情形下，爱回收客户端需要在本地访问您的
            <strong>剪切板</strong>
            中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。爱回收客户端仅在本地识别出
            <strong>剪切板</strong>
            内容属于爱回收跳转、分享、活动联动等指令时才会将其上传我们的服务器。除此之外，爱回收客户端不会上传您
            <strong>剪切板</strong>
            的其他信息至我们的服务器。
            <br />
            有些手机设备的系统会提示应用 (App）正在读取
            <strong>剪切板</strong>
            ，但该系统提示无法区分是本地处理还是上传、且由于统计方式的差异，不排除误报的可能。因此，如果您发现类似提示并有疑问，请与我们联系，以便我们定位问题。
          </p>
          <p>
            <strong>（2）下单</strong>
          </p>
          <p>
            A．出售二手物品：
          </p>
          <p>
            当您准备对您拟交易的二手物品进行出售时，爱回收系统会生成您出售该物品的订单，
            <strong>您需要在订单中至少填写您的姓名、身份证号、收货地址以及手机号码及二手物品相关信息，同时该订单中会载明订单号、您拟出售的物品信息、回收总价及收款方式。</strong>
          </p>
          <p>
            如果您出售的二手物品为特殊类型商品时还需要您提供其他必要信息，在进行奢侈品回收时需要您上传回收品相关图片，需要您
            <strong>授权摄像头及相册存储权限</strong>
            ，以进行图片的拍摄和上传。
          </p>
          <p>
            <strong>根据《旧货流通管理办法》（试行）、《旧电器电子产品流通管理办法》相关法律法规的规定旧货经营者应当登记出售人的身份证信息。</strong>
          </p>
          <p>
            B．购买商品或服务：
          </p>
          <p>
            在您购买商品或服务时，您需要在订单中至少填写您的
            <strong>联系人姓名、收货地址</strong>
            以及
            <strong>手机号码</strong>
            ，在您为手机购买延保服务时，还需要您提供被保手机的IMEI信息。同时该订单中会载明订单号、您所购买的商品或服务信息、下单时间、您应支付的货款金额及支付方式。若您需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票的电子邮箱。
          </p>
          <p>
            上述所有信息构成您的“订单信息”，我们将使用您的订单信息(包括与为您提供上述服务的第三方)进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务。我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。
          </p>
          <p>
            <strong>（3）收款及支付功能</strong>
          </p>
          <p>
            在您下单后，您可以选择爱回收的关联方或与爱回收合作的第三方支付机构(包括微信支付、支付宝支付、银联、网联及其他支付通道，以下称
            <strong>“支付机构”</strong>
            )所提供的支付服务收取回收款。支付功能本身并不收集您的个人信息，
            <strong>但我们需要将您的爱回收订单号与交易金额信息，包括您的收款银行及银行卡号、有效期及其他银行卡（含储蓄卡、信用卡）收款/支付必要信息与这些支付机构共享以实现其确认您的收款/支付指令并完成收款或支付。</strong>
          </p>
          <p>
            <strong>“关联方”</strong>
            指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。
            <strong>“控制”</strong>
            是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
          </p>
          <p>
            <strong>
              （4）交付产品与/或服务功能
            </strong>
          </p>
          <p>
            当您选择“上门方式”或“快递方式”进行交易时，您在下单后，爱回收及其关联方或与爱回收合作的第三方配送公司(包括顺丰、京东，以下称
            <strong>“配送公司”</strong>
            将为您完成订单的取件/交付。您知晓并同意:
            <strong>爱回收及其关联方，或与爱回收合作的第三方配送公司会在上述环节内使用您的订单信息以保证您的出售或订购的物品能够安全取走/送达。</strong>
          </p>
          <p>
            当您选择“门店方式”进行交易时，您在下单后，需您到指定线下门店进行交易，由爱回收及其关联方或与爱回收合作的第三方将为您提供服务。您知晓并同意:爱回收及其关联方或与爱回收合作的第三方会在上述环节内使用您的订单信息以保证您的产品能够安全交易。
          </p>
          <p>
            当您选择“门店方式”或“上门方式”进行交易时，爱回收及其关联方或与爱回收合作的第三方公司会对您的
            <strong>交易过程进行录音录像</strong>
            ，如果交易中有您出售物品给爱回收的，则
            <strong>基于法律要求实名认证的需求，爱回收需收集您的身份信息(包括身份证、护照、台胞证、回乡证)</strong>
            ，目的是在于保证交易的安全性。
          </p>
          <p>
            <strong>（5）客服与售后功能</strong>
          </p>
          <p>
            我们的电话客服与售后功能会使用您的账号信息和订单信息。
          </p>
          <p>
            在您使用“在线客服”功能时，由于客服系统由第三方公司“北京沃丰时代数据科技有限公司”提供支持，为快速定位问题，客服系统页面会获取您的设备型号信息。
          </p>
          <p>
            为保证您的账号安全，我们的客服会使用您的账号信息与您
            <strong>核验您的身份</strong>
            。当您需要我们提供与您订单信息相关的客服和售后服务时，我们将会查询您的
            <strong>订单信息</strong>
            。您需在与我们的客服人员沟通时，提供给出上述信息外的其他信息，当您要求我们变更收件地址、联系人或联系电话。
          </p>
          <p>
            <strong>B. 调用的敏感权限</strong>
          </p>
          <p>
            当您使用回收估价功能时，在遇到问题与客服进行沟通，或部分品类需拍摄或上传实物照片或视频进行估价时，若您需要发送设备内已有的照片或视频，则需要调用您设备的
            <strong>存储权限（Android系统）/相册权限（iOS系统）</strong>
            。若您拒绝相关系统权限，您可能无法正常发送照片或视频。
          </p>
          <p>
            当您使用回收估价功能时，在您遇到问题与客服进行沟通，或部分品类需拍摄或上传实物照片或视频进行估价时，若您需要直接拍摄并发送照片时，需要调用您设备的
            <strong>相机权限</strong>
            。若您拒绝相关系统权限，您将无法正常拍摄并发送照片。
          </p>
          <p>
            当您在使用当前城市定位、门店导航、附近门店或下单时选择门店功能时，需要调用您设备的
            <strong>位置权限</strong>
            。若您拒绝相关系统权限，您将无法自动定位。
          </p>
          <p>
            <strong>C. 使用第三方SDK</strong>
          </p>
          <p>
            微信开放平台 SDK，用于支付功能。此SDK直接收集您的
            <strong>设备类型、设备型号、操作系统、已安装应用包名信息</strong>
            ，此类信息属于实现该SDK功能必需的信息。
          </p>
          <p>
            Alipay(支付宝)-SDK，用于支付功能。此SDK直接收集您的
            <strong>设备品牌、设备型号、设备名称、IP地址、MAC地址、设备软件版本信息、设备识别信息（IDFA、IDFV、OAID、AndroidID）、所在位置、网络使用习惯、已安装应用包名信息、读取传感器列表、SIM卡序列号、运营商信息以及其他与服务相关的日志信息</strong>
            ，此类信息属于实现该SDK功能必需的信息。
          </p>
          <p>
            百度地图SDK，用于实现地图展示、门店导航、门店距离计算及地理位置名称搜索。此SDK直接收集您的
            <strong>位置信息、设备信息（IDFA、Android ID、MEID、MAC地址、OAID、硬件序列号、设备型号）、WLAN状态信息、读取SIM卡状态、扫描wifi列表、BSSID、运营商信息</strong>
            ，此类信息属于实现该SDK功能必需的信息。
          </p>
          <p>
            3、
            <strong>其他功能</strong>
          </p>
          <p>
            <strong>（1）通知推送功能</strong>
          </p>
          <p>A. 个人信息使用范围</p>
          <p>
            请您了解，通知推送功能会默认关闭，用户在使用通知推送功能时需在
            <strong>【我的】-【设置】-【接收通知推送】</strong>
            下自主开启。您同样可以通过
            <strong>【我的】-【设置】-【接收通知推送】</strong>
            ，关闭通知推送功能。
          </p>
          <p>当您开启通知推送功能时，我们会将以下信息推送到您的手机桌面：系统通知消息。</p>
          <p>
            当您开启通知推送功能时，第三方SDK会收集和使用您
            <strong>网络信息、设备类型、设备型号、操作系统、设备平台、设备厂商、设备品牌</strong>
            。
          </p>
          <p>此类信息属于该功能的必需信息，若您不同意采集并共享这类信息，您可能无法正常使用推送功能。您可以按照本《隐私政策》来管理和控制这部分信息的推送，包括关闭系统推送。</p>
          <p>B. 使用第三方SDK</p>
          <p>
            个推 SDK，用于在用户主动开启“接收通知推送”功能后实现系统通知消息的推送，此SDK直接收集您的
            <strong>设备识别码（包含IMEI、IDFA、Android ID、MAC、OAID、SN、IMSI、UAID、SSID、BSSID）、网络信息（包含IP地址、WIFI状态信息、基站信息）、位置信息、应用列表信息，</strong>
            此类信息属于实现该SDK功能必需的信息。
          </p>
          <p>
            华为推送 SDK，用于华为手机用户接收 App 推送消息，此SDK直接收集您的
            <strong>应用匿名标识（AAID）、用户的标识符HMS Core openId、SIM卡序列号、运营商信息</strong>
            ，此类信息属于实现该SDK功能必需的信息。
          </p>
          <p>
            小米推送 SDK，用于小米手机用户接收 App 推送消息，此SDK直接收集您的OAID、
            <strong>加密的Android ID、应用基本信息（应用包名、版本号和运行状态)、设备相关信息（设备厂商、设备型号、设备内存、操作系统版本、小米推送SDK版本、SIM卡序列号、运营商信息、设备归属地、SIM卡运营商名称、当前网络类型、通知栏设置信息)</strong>
            ，此类信息属于实现该SDK功能必需的信息。
          </p>
          <p>
            魅族推送 SDK，用于魅族手机用户接收 App 推送消息，此SDK直接收集您的
            <strong>设备信息（设备名称、设备型号、地区和语言设置、设备识别码、IMEI、设备硬件信息与状态、使用习惯、IP 地址、Mac 地址、操作系统版本）、应用信息（应用列表、应用程序版本、应用状态记录、软件识别码、以及应用设置信息）、位置信息（设备位置、WiFi地理位置信息）</strong>
            ，此类信息属于实现该SDK功能必需的信息。
          </p>
          <p>
            OPPO推送 SDK，用于OPPO手机用户接收 App 推送消息，此SDK直接收集您的
            <strong>设备相关信息（IMEI或OAID，Serial Number，IMSI，User ID，Android ID，Google Advertising ID, 手机Region设置，设备型号，手机电量，手机操作系统版本及语言）、使用推送服务的应用信息（APP包名及版本号，运行状态）、推送SDK版本号、网络相关信息（IP或域名连接结果，当前网络类型）、消息发送结果、通知栏状态（通知栏权限、用户点击行为），锁屏状态（如是否锁屏，是否允许锁屏通知）</strong>
            ，此类信息属于实现该SDK功能必需的信息。
          </p>
          <p>
            VIVO推送 SDK，用于VIVO手机用户接收 App 推送消息，此SDK直接收集您的
            <strong>设备类型、设备型号、操作系统</strong>
            ，此类信息属于实现该SDK功能必需的信息。
          </p>
          <p>
            荣耀推送 SDK，用于荣耀手机用户接收 App 推送消息，此SDK直接收集您的
            <strong>设备信息、应用的基本信息</strong>
            ，此类信息属于实现该SDK功能必需的信息。
          </p>
          <p>
            <strong>（2）App 升级</strong>
          </p>
          <p>A. 个人信息使用范围</p>
          <p>当您使用App 内的升级功能或者通过App 内链接下载安装其他App 时，我们需要收集使用网络信息，此类信息为该功能的必需信息。App 有可升级的版本时会提示您，您可进行选择安装或拒绝。</p>
          <p>B. 调取的敏感权限</p>
          <p>
            您使用 App 的升级功能时，或通过爱回收 App 内的链接下载安装其他 App 时，会使用您设备的
            <strong>应用内安装其他应用权限</strong>
            。请您知悉，在应用内安装其他应用将会在您明确操作的情况下才能实现。
          </p>
          <p>
            4 、
            <strong>保障运营与交易安全所必须的功能</strong>
          </p>
          <p>
            <strong>A. 个人信息使用范围</strong>
          </p>
          <p>
            为保障您使用我们的产品与/或服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们需要收集您的
            <strong>设备信息（设备型号、设备Mac地址、应用进程列表、设备识别码、设备序列号、操作系统及软件版本、设备状态、网络状况）、历史上网记录、日志信息、面部信息、IP地址、位置信息、浏览信息、订单信息、常用软件列表、ssid、wifi信息、IMSI、SIM卡信息、电池使用情况来识别是否为真实自然人用户、是否为异常地点登录、判断您的账号风险</strong>
            ，并记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息用于对爱回收系统问题进行分析、统计流量并排查潜在的风险，在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用爱回收服务过程中对您的服务及账号安全进行保护。
          </p>
          <p>
            <strong>B. 使用的第三方SDK</strong>
          </p>
          <p>
            阿里云Web应用防火墙APP防护SDK，用于风险控制，防止黑、灰产攻击，保障APP及账户的交易安全。此SDK直接收集您的
            <strong>设备标识符（MAC地址、IMEI、android_id、IDFA、OAID）、网络信息、位置信息、设备基础信息（品牌、型号、操作系统）、读取传感器列表、设备加速度器软硬件信息</strong>
            ，此类信息属于该SDK功能必需的信息。
          </p>
          <p>
            神策数据SDK，用于App数据分析，该SDK仅提供工具服务，采用私有化部署的方式，我们收集的所有信息不会向该SDK的提供方共享。
          </p>
          <p>
            Sentry错误统计SDK，用于在应用发生未知异常错误时进行异常记录及上报，此SDK直接收集您的
            <strong>设备基础信息（机型\厂商）、旋转矢量传感器、加速度传感器、线性加速传感器、陀螺仪、传感器列表、运行中进程信息</strong>
            ，此类信息属于该SDK功能必需的信息。
          </p>
          <p>
            移动智能终端补充设备标识体系统一调用SDK，用于辅助开发，此SDK本身不会存储任何数据，只在APP调用时获取以下数据，用于本地判断，且不会进行任何网络传输。此SDK直接收集您的
            <strong>设备制造商、设备型号、设备品牌、设备网络运营商名称、OAID、VAID、AAID、已安装应用包名信息及签名信息，或在对应应用商店的APPID</strong>
            ，此类信息属于该SDK功能必需的信息。
          </p>
          <p>
            360加固保SDK，用于安全风控，此SDK直接收集您的
            <strong>设备基础信息（Android ID、设备标识符的MD5值、OAID、AAID、网络类型、系统运行语言环境、操作系统版本名称、操作系统版本、设备型号、系统版本、厂商、终端主板名称、品牌、设备内存大小、手机屏幕分辨率、运行环境）、网络信息（DNS 服务器IP、是否开启VPN）、运营商信息、SIM卡状态信息、已安装应用包名信息、wifi状态信息、获取wifi参数（SSID、BSSID）、每20分钟获取1次Android ID（包含静默状态）</strong>
            ，此类信息属于该SDK功能必需的信息。
          </p>
          <p>
            React Native SDK，用于辅助开发，以提升应用稳定性及用户体验，此SDK直接收集您的
            <strong>设备序列号、IMEI、IMSI、旋转矢量传感器、加速度传感器、线性加速传感器、陀螺仪</strong>
            ，此类信息属于该SDK功能必需的信息。
          </p>
          <p>
            Chromium SDK，用于辅助开发，安卓开发框架，此SDK直接收集您的
            <strong>设备版本、IMSI、设备应用安装列表、网络设备制造商、MAC地址、旋转矢量传感器、加速度传感器、线性加速传感器、陀螺仪</strong>
            ，此类信息属于该SDK功能必需的信息。
          </p>
          <p>
            <strong>（二）您可自主选择提供个人信息的情形</strong>
          </p>
          <p>
            为使您的物品交易更便捷或更有乐趣，从而提升您在爱回收的网上交易体验，我们的扩展附加功能中会收集和使用您的个人信息。
            <strong>如果您不提供这些个人信息，您依然可以进行网上交易，但您无法使用为您所带来交易乐趣的扩展功能或在交易时需要重复填写一些信息。</strong>
            关于这些扩展功能收集的信息和使用目的，请您详见

            <Link to='/privacy/permissions'>《爱回收权限列表》</Link>
            。
          </p>
          <p>
            <strong>
              请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
            </strong>
          </p>
          <p>
            <strong>
              （三）您应充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意:
            </strong>
          </p>
          <p>
            1 、
            <strong>与国家安全、国防安全有关的；</strong>
            <br />
            2 、
            <strong>与公共安全、公共卫生、重大公共利益有关的；</strong>
            <br />
            3 、
            <strong>与犯罪侦查、起诉、审判和判决执行等有关的；</strong>
            <br />
            4 、
            <strong>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</strong>
            <br />
            5 、
            <strong>所涉及的个人信息是个人信息主体或监护人自行向社会公众公开的；</strong>
            <br />
            6 、
            <strong>从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</strong>
            <br />
            7 、
            <strong>根据您的要求签订和履行合同所必需的；</strong>
            <br />
            8 、
            <strong>用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</strong>
            <br />
            9 、
            <strong>为合法的新闻报道所必需的；</strong>
            <br />
            10 、
            <strong>学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</strong>
            <br />
            11 、
            <strong>法律法规规定的其他情形。</strong>
          </p>
          <p>
            <strong>
              （四）我们从第三方获得您个人信息的情形
            </strong>
          </p>
          <p>
            我们有从第三方获取您授权共享的账户信息(头像、昵称)，并在您同意本隐私政策后将您的第三方账户与您的爱回收账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
          </p>
          <p>
            <strong>
              （五）爱回收APP涉及嵌入第三方代码、插件传输个人信息的情形逐项列举，
              <Link to='/privacy/vendor-person-date'>请见此处</Link>
              。
            </strong>
          </p>
          <p>
            <strong>
              （六）您个人信息使用的规则
            </strong>
          </p>
          <p>
            1、我们会根据本隐私政策的约定为实现我们的产品与/或服务功能而对所收集的个人信息进行使用。
          </p>
          <p>
            2 、在收集您的个人信息后，我们将通过技术手段对信息进行去标识化处理，去标识化处理的信息将无法识别主体。
            <strong>请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</strong>
          </p>
          <p>
            3、
            <strong>请您了解并同意，对于您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则被视为将在您使用我们的产品与/或服务期间持续授权同意我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。</strong>
          </p>
          <p>
            4 、我们会对我们的产品与/或服务使用情况进行统计，并会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
          </p>
          <p>
            <span>
              5 、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
            </span>
          </p>
          <p>
            6 、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的个人信息用于其他目的时，会事先征求您的明示同意。
            <span id='chapter-2' />
          </p>
          <p>
            <strong>请您理解，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。</strong>
          </p>
          <h3>二、我们如何使用 Cookie 和同类技术</h3>
          <p>
            <strong>
              （一）Cookies 的使用
            </strong>
          </p>
          <p>
            1、为使您获得更轻松的访问体验，我们会在您的计算机或移动设备上发送一个或多个名为 Cookies 的小数据文件，指定给您的 Cookies 是唯一的，它只能被将 Cookies 发布给您的域中的 Web 服务器读取。我们向您发送 Cookies 是为了简化您重复登录的步骤、帮助判断您的登录状态以及账户或数据安全。
          </p>
          <p>
            2、我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受 Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有 Cookies。但如果您这么做，您需要在每一次访问爱回收网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且会对您所使用服务的安全性有一定影响。
          </p>
          <p>
            <strong>
              （二）网络 Beacon 和同类技术的使用
            </strong>
          </p>
          <p>
            除 Cookie 外，我们还会在网站上使用网络 Beacon 及其他同类技术。我们的网页上常会包含一些电子图像(称为&quot;单像素&quot; GIF 文件或 &quot;网络 beacon&quot;)。我们使用网络 beacon 的方式有:
          </p>
          <p>
            1、通过在爱回收网站上使用网络 beacon，计算用户访问数量，并通过访问 cookie 辨认注册的爱回收用户。
            <span id='chapter-3' />
          </p>
          <p>
            2、通过得到的 cookies 信息，为您提供历史搜索记录便捷功能。
          </p>
          <h4>三、我们如何共享、转让、公开披露您的个人信息</h4>
          <p>
            <strong>（一）共享</strong>
          </p>
          <p>
            1、
            <strong>我们不会与爱回收以外的任何公司、组织和个人共享您的个人信息，但以下情况除外:</strong>

            <br />
            (1)事先获得您明确的同意或授权；
            <br />
            (2)根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
            <br />
            (3)在法律法规允许的范围内，为维护爱回收、爱回收的关联方或合作伙伴、您或其他爱回收用户或社会公众利益、财产或安全免遭损害而有必要提供；
            <br />
            (4)只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
            <br />
            (5)应您需求为您处理您与他人的纠纷或争议；
            <br />
            (6)符合与您签署的相关协议(包括在线签署的电子协议以及相应的平台规则)或其他的法律文件约定所提供；
            <br />
            (7)基于学术研究而使用；

            <br />
            (8)基于符合法律法规的社会公共利益而使用。
          </p>
          <p>
            2 、
            <strong>
              为向您提供相关产品或服务，保护您的账号与交易安全，我们会将您的个人信息与我们的关联方共享，共享内容及目的详见
              <Link to='/privacy/vendor-person-date'>《个人信息共享清单》</Link>
              。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
            </strong>
          </p>
          <p>
            <strong>请您注意，您在使用我们服务时自愿共享甚至公开分享的信息，会涉及您或他人的个人信息甚至敏感个人信息。请您共享时谨慎考虑并决定。</strong>
          </p>
          <p>
            3 、
            <strong>我们会向第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供产品与/或服务所必要的个人信息</strong>
            。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
          </p>
          <p>
            仅为实现本政策中声明的目的，我们的某些服务将由业务合作伙伴提供。为保障为您提供的服务顺利完成，我们会将您的个人信息共享给上述合作伙伴，包括配送业务、技术服务、支付服务。其中包括您的
            <strong>联络信息、订单信息、支付信息、地址信息</strong>
            ，以保障为您提供的服务顺利完成。在您使用我们涉及物流配送的功能时，我们必须与配送服务提供商共享您的订单和配送信息，以保证订单的安全准确送达；当您使用第三方服务商所提供的服务时，我们需要向第三方服务商提供您的订单和联络信息，以保障第三方服务商能顺利为您提供服务；我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付。
            <strong>其中针对您的联络信息和地址信息，我们已经采取了严格的加密举措尽全力保护您的个人信息。</strong>
            <br />
            我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、提供客户服务、支付便利或进行学术研究和调查。 请您知悉，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，对我们仅为实现本政策中声明的目的与之共享个人信息的公司、组织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。
          </p>
          <p>
            4 、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护个人敏感信息。
          </p>
          <p>
            5 、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护爱回收、您或其他爱回收客户的权利及其财产或安全，防止违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
          </p>
          <p>
            <strong>
              （二）转让
            </strong>
          </p>
          <p>
            <strong>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</strong>
          </p>
          <p>
            1 、事先获得您明确的同意或授权；
          </p>
          <p>
            2 、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
          </p>
          <p>
            3 、符合与您签署的相关协议(包括在线签署的电子协议以及相应的平台规则)或其他的法律文件约定所提供；
          </p>
          <p>
            4 、
            <strong>在涉及合并、收购、资产转让或类似的交易时，如果涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新征求您的授权同意。</strong>
          </p>
          <p>
            <strong>（三）公开披露</strong>
          </p>
          <p>
            我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息:
          </p>
          <p>
            1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
          </p>
          <p>
            2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽全力保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。
            <span id='chapter-4' />
          </p>
          <p>
            <strong>
              四、我们如何保护和保存您的个人信息
            </strong>
          </p>
          <p>
            <strong>
              （一）我们保护您个人信息的技术与措施
            </strong>
          </p>
          <p>
            我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息:
          </p>
          <p>
            1、
            <strong>数据安全技术措施</strong>
            <br />
            我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。
            <br />
            爱回收的网络服务采取了传输层安全协议加密技术，通过 https 及其他方式提供浏览服务， 确保用户数据在传输过程中的安全。
            <br />
            爱回收采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。
            <br />
            在个人信息使用时，个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性；
            <br />
            爱回收采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用；
            <br />
            爱回收采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。
          </p>
          <p>
            2 、
            <strong>爱回收为保护个人信息采取的其他安全措施</strong>
            <br />
            爱回收通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
            <br />
            爱回收通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
            <br />
            爱回收建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。
          </p>
          <p>
            3 、
            <strong>
              我们仅允许有必要知晓这些信息的爱回收及爱回收关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，会被追究法律责任或被中止与爱回收的合作关系。
            </strong>
          </p>
          <p>
            4 、
            <strong>
              我们会采取一切合理可行的措施，确保未收集无关的个人信息。
            </strong>
          </p>
          <p>
            5 、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。
          </p>
          <p>
            6 、
            <strong>安全事件处置</strong>
            <br />
            在通过爱回收网站与第三方进行网上服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
            <br />
            为应对个人信息泄露、损毁、丢失、用户安全的风险，爱回收制定了应急预案。
            <br />
            一旦发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知:安全事件的基本情况和产生的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
          </p>
          <p>
            7 、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
          </p>
          <p>
            8 、如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。
            <strong>
              如果您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。
            </strong>
          </p>
          <p>
            <strong>
              （二）您个人信息的保存
            </strong>
          </p>
          <p>
            1 、您的个人信息将存储于中华人民共和国境内。如果您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。
          </p>
          <p>
            2 、
            <strong>
              在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，根据《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
            </strong>
          </p>
          <p>
            3 、
            <strong>
              请您注意，当您成功注销爱回收账户后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以删除或实现匿名化。
            </strong>
          </p>
          <p>
            <span id='chapter-5' />
            4、
            <strong>如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</strong>
          </p>
          <p>
            <strong>
              五、您如何管理您的个人信息
            </strong>
          </p>
          <p>
            爱回收非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括:
          </p>
          <p>
            1 、
            <strong>
              访问和更正您的个人信息
            </strong>
          </p>
          <p>
            除法律法规规定外，您有权随时访问和更正您的个人信息，包括您的账户信息、地址信息、订单信息、评论信息，对于您无法访问和更正的信息，请您与我们联系，我们会尽快响应您的请求。
          </p>
          <p>
            2 、
            <strong>
              删除您的个人信息
            </strong>
          </p>
          <p>
            您在我们的服务页面中可以直接清除或删除的信息，包括搜索记录；
          </p>
          <p>
            <strong>
              在以下情形中，您可以向我们提出删除个人信息的请求:
            </strong>
            <br />
            （1）如果我们处理个人信息的行为违反法律法规；
            <br />
            （2）如果我们收集、使用您的个人信息，却未征得您的同意；
            <br />
            （3）如果我们处理个人信息的行为违反了与您的约定；
            <br />
            （4）如果您注销了爱回收账户；
            <br />
            （5）如果我们终止服务及运营。
          </p>
          <p>
            对于您提出的查询、更改、删除个人信息的请求，我们将在15个工作日内完成处理。若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体已经获得您的独立授权。
            <strong>当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们不能保证立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到可以删除或实现匿名化。</strong>
          </p>
          <p>
            3 、
            <strong>改变您授权同意的范围或撤回您的授权</strong>
          </p>
          <p>
            <strong>爱回收会通过短信息的方式向您发送商业广告，如果您不想接受爱回收给您发送的商业广告，您可通过短信息提示回复退订或爱回收提供的其他方式进行退订或关闭。</strong>
          </p>
          <p>
            <strong>您可以通过删除信息、关闭设备功能、在爱回收网站或软件中进行隐私设置的方式改变您授权我们继续收集个人信息的范围或撤回您的授权。</strong>
          </p>
          <p>
            <strong>如果您要撤回某项权限的授予，您需要打开手机设置，在应用列表内找到我们的应用，点击进入后可以查看或修改权限设置。</strong>
          </p>
          <p>
            <strong>
              您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。
            </strong>
          </p>
          <p>
            <strong>请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</strong>
          </p>
          <p>
            4 、
            <strong>注销账户</strong>
          </p>
          <p>
            <strong>
              您可以在我们的产品中直接申请注销您的账户。移动端访问路径为:【我的-设置-账号与安全-永久注销账号】。关于您注销账户的方式以及您应满足的条件，请详见
              <Link to='/privacy/log-out'>《爱回收账户注销须知》</Link>
              。在您满足注销账户申请条件并申请注销账户后，我们将在15个工作日内完成处理，停止为您提供服务，并根据适用法律的要求删除您的个人信息，或进行匿名化处理。
            </strong>
          </p>
          <p>
            5 、
            <strong>
              获取您的个人信息副本
            </strong>
          </p>
          <p>
            您有权获取您的个人信息副本。如果您需要获取我们收集的您的个人信息副本，您随时通过下列方式联系我们。在符合相关法律规定且技术可行的前提下，我们将在15个工组日内根据您的要求向您提供相应的个人信息副本。
            <br />
            联系电话：

            <strong>4001616365</strong>
            <br />
            注册地址：

            <strong>上海市杨浦区淞沪路 433 号 1101-1103 室</strong>
            <br />
            常用办公地址：

            <strong>上海市杨浦区淞沪路 433 号 1101-1103 室</strong>
            <br />
          </p>
          <p>
            6 、
            <strong>
              响应您的请求
            </strong>
          </p>
          <p>
            如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息，或您认为爱回收存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以通过本协议下方的方式与我们联系。为了保障安全，我们需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后尽快答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们会予以拒绝。
            <br />
            在以下情形中，按照法律法规要求，我们将无法响应您的请求:
            <br />
            （1）与国家安全、国防安全有关的；
            <br />
            （2）与公共安全、公共卫生、重大公共利益有关的；
            <br />
            （3）与犯罪侦查、起诉和审判有关的；
            <br />
            （4）有充分证据表明您存在主观恶意或滥用权利的；
            <br />
            （5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
          </p>
          <p>
            <strong>
              六、未成年人的个人信息保护
            </strong>
          </p>
          <p>
            1 、爱回收非常重视对未成年人个人信息的保护。由于我们的网站和服务主要面向成年人，爱回收不会收集、使用、储存未成年人的个人信息。若您是 18 周岁以下的未成年人，在使用我们的服务前，
            <strong>应事先取得您监护人的书面同意</strong>
            。爱回收根据国家相关法律法规的规定保护未成年人的个人信息。
          </p>
          <p>
            <strong>
              七、通知和修订
            </strong>
          </p>
          <p>
            1 、为给您提供更好的服务以及随着爱回收业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在爱回收网站、爱回收移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问爱回收以便及时了解最新的隐私政策。
          </p>
          <p>
            2 、对于重大变更，我们还会提供更为显著的通知(我们会通过包括但不限于邮件、短信或在浏览页面做特别提示的方式，说明隐私政策的具体变更内容)。
          </p>
          <p>
            3、本隐私政策所指的重大变更包括但不限于:

            <br />
            (1)我们的服务模式发生重大变化。处理个人信息的目的、处理的个人信息类型、个人信息的使用方式发生重大变化；
            <br />
            (2)我们在所有权结构、组织架构方面发生重大变化。业务调整、破产并购引起的所有者变更；
            <br />
            (3)个人信息共享、转让或公开披露的主要对象发生变化；
            <br />
            (4)我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
            <br />
            (5)您参与个人信息处理方面的权利及其行使方式发生重大变化；
            <br />
            (6)个人信息安全影响评估报告表明存在高风险时。
          </p>
          <p>
            4、为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。我们鼓励您在每次使用我们服务时都查阅本政策。
          </p>
          <p>
            <strong>
              八、如何联系我们
            </strong>
          </p>
          <p>
            1 、您对本隐私政策或您个人信息的相关事宜有任何投诉、疑问或建议，请通过爱回收的在线客服系统【我的】-【设置】-【反馈意见】，进入用户反馈界面与我们联系。
          </p>
          <p>
            2 、我们还设立了个人信息保护专项组，您可以通过客服电话（4001616365）与我们联系。需注意的是，我们可能不会回复与本政策或您的个人信息权利无关的问题。
          </p>
          <p>
            3 、我们会在15个工作日内响应您的请求，并依据本隐私政策及法律法规处理您的请求。
          </p>
          <p>
            4 、如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益， 您还可以向网信、电信、公安、工商及其他监管部门进行投诉或举报。
          </p>
          <p>
            附：个人信息及敏感个人信息示例（非爱回收APP或网站所收集的个人信息，仅作示例说明）
            <br />
            个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹的信息，以及不满十四周岁未成年人的个人信息。（本定义出自于《中华人民共和国个人信息保护法》）。
            <br />
            <br />
            <a href={ossImg('/privacy/personal-info-sample.png')}>
              <img src={ossImg('/privacy/personal-info-sample.png')} alt='' />
            </a>
            <br />
            <br />
            <a href={ossImg('/privacy/personal-security-info-sample.png')}>
              <img src={ossImg('/privacy/personal-security-info-sample.png')} alt='' />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyV16;
