import React from 'react';
import classNames from 'classnames';

import classes from './Index.module.scss';

function Index(props: {
  children: React.ReactNode;
  className?: string;
}) {
  const { children, className } = props;
  return (
    <div className={classNames(classes.Main, className)}>
      {
        children
      }
    </div>
  );
}

export default Index;
