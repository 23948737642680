/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink as ReactLink } from 'react-router-dom';

export interface LinkProps extends NavLinkProps {
  children?: React.ReactNode;
}

function Link(props: LinkProps) {
  const {
    to,
    children,
    ...otherProps
  } = props;
  if (!to) return <a {...otherProps}>{children}</a>;
  if (typeof to === 'string') {
    return (
      <a
        href={to}
        rel='nofollow noopener'
        target='_blank'
        {...otherProps}
      >
        {children}
      </a>
    );
  }
  return <ReactLink to={to} {...otherProps}>{children}</ReactLink>;
}

export default Link;
