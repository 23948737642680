import React from 'react';
import className from './Index.module.scss';
import title from '../../assets/moreServices/title.png';
import yxer from '../../assets/moreServices/yxes.png';
import yjhx from '../../assets/moreServices/yjhx.png';
import sjwx from '../../assets/moreServices/sjwx.png';
import ysqc from '../../assets/moreServices/ysqc.png';
import ybfw from '../../assets/moreServices/ybfw.png';
import smpj from '../../assets/moreServices/smpj.png';

function MoreServices() {
  return (
    <div className={className.background}>
      <div className={className.title}>
        <img className={className.titleImg} src={title} alt='' />
      </div>
      <div className={className.block}>
        <div className={className.blockItem}>
          <img src={yxer} alt='' className={className.blockImg} />
          <div className={className.desc}>严选二手</div>
        </div>
        <div className={className.blockMiddleItem}>
          <img src={yjhx} alt='' className={className.blockImg} />
          <div className={className.desc}>以旧换新</div>
        </div>
        <div className={className.blockItem}>
          <img src={sjwx} alt='' className={className.blockImg} />
          <div className={className.desc}>手机维修</div>
        </div>
      </div>
      <div className={className.block}>
        <div className={className.blockItem}>
          <img src={ysqc} alt='' className={className.blockImg} />
          <div className={className.desc}>隐私清除</div>
        </div>
        <div className={className.blockMiddleItem}>
          <img src={ybfw} alt='' className={className.blockImg} />
          <div className={className.desc}>延保服务</div>
        </div>
        <div className={className.blockItem}>
          <img src={smpj} alt='' className={className.blockImg} />
          <div className={className.desc}>数码配件</div>
        </div>
      </div>
    </div>
  );
}

export default MoreServices;
