import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Privacy from './PrivacyV22';

import { routerVersion } from './historyMap';

function CurrentPrivacies() {
  const { version } = useParams<{ version: string }>();
  const matchedVersion = useMemo(() => {
    return routerVersion.find((v) => v.path === version);
  }, [ version ]);
  if (matchedVersion) {
    return <matchedVersion.component />;
  }
  return <Privacy />;
}

export default CurrentPrivacies;
