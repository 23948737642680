import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import 'reseter.css/css/reseter.min.css';
import 'swiper/css/swiper.min.css';

import './index.scss';

const root = document.getElementById('root');

if (root!.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    root,
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    root,
  );
}
