import React, { useState, useEffect, useRef } from 'react';
import desc from '../../assets/download/desc.png';
import close from '../../assets/download/close.png';
import imgArr from '../../assets/code/index';
import classes from './Index.module.scss';

function Download() {
  const initialized = useRef(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      // 获取当前滚动位置
      const scrollPosition = window.scrollY;
      if (scrollPosition > 600 && !visible && !initialized.current) {
        setVisible(true);
        initialized.current = true;
      }
    });
  }, [ visible ]);

  useEffect(() => {
    if (visible) {
      document.body.classList.add('bottom-bar-visible');
    } else {
      document.body.classList.remove('bottom-bar-visible');
    }
  }, [ visible ]);

  const onClose = () => {
    setVisible(false);
  };
  return (
    <React.Fragment>
      {
        visible ? (
          <div className={classes.download}>
            <div className={classes.container}>
              <div className={classes.desc}>
                <img src={desc} alt='' />
              </div>
              <div className={classes.codeContainer}>
                <div className={classes.code}>
                  <img src={imgArr[1]} alt='' />
                </div>
                <p>微信扫码领福利</p>
              </div>
              <div onClick={onClose} className={classes.close} role='button'>
                <img src={close} alt='' />
              </div>
            </div>
            <div onClick={onClose} className={classes.close} role='button'>
              <img src={close} alt='' />
            </div>
          </div>
        ) : null
      }
    </React.Fragment>
  );
}

export default Download;
