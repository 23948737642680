import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import type { LocaleConfig } from 'locales';
import { useLocale } from 'locales';
import Main from 'components/Main/Index';
import Link from 'components/NavButton/Link';
import useScreen from 'utils/screen';
import type { ScreenType } from 'utils/view';
import { useLocation } from 'react-router-dom';
import Modal from 'antd/es/modal/Modal';
import help from '../../assets/help.jpeg';
import closeIcon from '../../pages/Home/assets/icons/close-icon.png';

import classes from './Index.module.scss';

type MenuLocal = 'both' | Locale;
type Menu = {
  text: string;
  link: string;
  locale?: MenuLocal;
  thirdLink?: boolean;
  subMenus?: Array<Menu>;
};

function getMenus(locale: LocaleConfig) {
  const menus: Array<Menu> = [{
    text: locale.pages.Business.title,
    link: 'https://neon.aihuishou.com/',
  }, {
    text: locale.pages.News.title,
    link: 'https://ir.atrenew.com/',
  }, {
    text: locale.pages.Investors.title,
    link: '',
  }, {
    text: locale.pages.ESG.title,
    link: 'https://activity.aihuishou.com/n/recruit',
  }, {
    text: locale.pages.AboutUs.title,
    link: '/about-us',
  }];

  return menus.filter((m) => !m.locale || m.locale === 'both' || m.locale === locale.code);
}

interface MenuComponentProps {
  data: Menu[];
  screenType?: ScreenType;
  hasParent?: boolean;
  closeMenu?: () => void;
}

function MenuComponent(props: MenuComponentProps) {
  const {
    data,
    screenType,
    hasParent,
  } = props;

  if (typeof screenType !== 'number') return null;

  return (
    <React.Fragment>
      {
        data.map((menu) => {
          const isMain = Boolean(menu.subMenus?.length);
          if (isMain) {
            return (
              <div
                key={menu.text}
              >
                <label className={classes.name}>
                  {menu.text}
                </label>
                <MenuComponent
                  data={menu.subMenus!}
                  screenType={screenType}
                  hasParent
                />
              </div>
            );
          }
          return (
            <div
              key={menu.text}
              className={
                classNames(
                  { [classes.alone]: !hasParent },
                )
              }
            >
              <Link
                activeClassName={classes.active}
                to={menu.link}
                exact
              >
                {menu.text}
              </Link>
            </div>
          );
        })
      }
    </React.Fragment>
  );
}

function Index() {
  const [isOpen, setIsOpen] = useState(false);
  const [special, setSpecial] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBlack, setIsBlack] = useState<boolean>(false);

  const location = useLocation();
  const currentPath = location.pathname;
  const isAboutUs = currentPath === '/about-us';

  const locale = useLocale();
  const menus = useMemo(() => getMenus(locale), [ locale ]);
  const { screenType } = useScreen();

  const hideSubMenu = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isParentMenu = (target as HTMLInputElement).name === 'menus'
      || (target.nextSibling as HTMLInputElement)?.name === 'menus';
    if (isParentMenu) return;
    const indicators = Array.prototype.slice.call(
      document.getElementsByClassName(classes.indicator),
    );
    indicators.forEach((input: HTMLInputElement) => {
      input.checked = false;
    });
  }, []);

  useEffect(() => {
    document.addEventListener('click', hideSubMenu, false);

    return () => {
      document.removeEventListener('click', hideSubMenu);
    };
  }, [ hideSubMenu ]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      // 获取当前滚动位置
      const scrollPosition = window.scrollY;

      if (scrollPosition > 10) {
        setSpecial(true);
        setIsBlack(true);
      } else {
        setSpecial(false);
        setIsBlack(false);
      }
    });
  }, []);

  return (
    <div className={classes.box}>
      <Main className={
        classNames(
          classes.AppHeader,
          {
            [classes.special]: special,
            [classes.specialAbout]: isAboutUs,
          },
        )
      }
      >
        <div className={classes.header}>
          <div className={classes.logo}>
            <Link to='/' target=''>
              <img src={(isAboutUs && !isBlack) ? locale.whiteLogo : locale.logo} alt={locale.title} />
            </Link>
          </div>
          <nav
            className={classes.menus}
          >
            {
              menus.map((menu) => (
                <div key={menu.text} className={classes.menu}>
                  <Link
                    className={
                      classNames(
                        classes.title,
                        {
                          [classes.inactive]: menu.thirdLink,
                          [classes.menuTitleWhite]: isAboutUs,
                          [classes.menuTitleBlack]: isBlack,
                        },
                      )
                    }
                    activeClassName={classes.active}
                    onClick={
                      () => {
                        if (!menu.link) {
                          setIsModalOpen(true);
                        }
                      }
                    }
                    to={menu.link}
                    exact
                  >
                    <span>{menu.text}</span>
                  </Link>
                </div>
              ))
            }
          </nav>
          <div
            className={
              classNames('hidden-large', classes.menusBox, { [classes.open]: isOpen })
            }
          >
            <MenuComponent
              data={menus}
              screenType={screenType}
              closeMenu={() => setIsOpen(false)}
            />
          </div>
        </div>
      </Main>
      <Modal
        title={
          (
            <div
              style={
                {
                  textAlign: 'center',
                  color: '#373A36',
                  fontSize: '22px',
                  fontWeight: 600,
                  marginBottom: 0,
                  paddingBottom: '20px',
                  borderBottom: '1px solid #ECECEC',
                }
              }
            >
              扫码查看常见问题
            </div>
          )
        }
        open={isModalOpen}
        footer={[]}
        onCancel={() => { setIsModalOpen(false); }}
        width={435}
        centered
        bodyStyle={
          { height: 340 }
        }
        closeIcon={
          (
            <img
              src={closeIcon}
              style={
                {
                  height: '20px',
                  width: '20px',
                }
              }
              alt='关闭'
            />
          )
        }
        style={
          { textAlign: 'center' }
        }
      >
        <img src={help} alt='' className={classes.helpCode} />
      </Modal>
    </div >
  );
}

export default Index;
