import { useContext, createContext } from 'react';

import { ScreenType, isLarge, isSmall } from './view';

export function getValue() {
  const width = window.screen.width;
  const height = window.screen.height;
  const screenType = isLarge() ? ScreenType.Large : ScreenType.Small;
  return {
    width,
    height,
    screenType,
    isLarge: isLarge(),
    isSmall: isSmall(),
  };
}

const defaultValue: {
  width: number;
  height: number;
  screenType?: ScreenType;
  isLarge: boolean;
  isSmall: boolean;
} = getValue();

export const ScreenContext = createContext(defaultValue);

const ScreenProvider = ScreenContext.Provider;

export default function useScreen() {
  return useContext(ScreenContext);
}

export { ScreenProvider };
