/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Index.module.scss';

function PrivacyV1() {
  return (
    <div className={classes.help}>
      <div className={classes.mainSection}>
        <dd>
          版本日期 ： 2019年07月29日
          {' '}
          <br />
          生效日期 ： 2019年08月05日
        </dd>
        <h1>引言</h1>
        <div className={classes.privacyContent}>
          <h2>爱回收（指上海万物新生环保科技集团有限公司运营之爱回收网站（域名为 aihuishou.com）、爱回收客户端及爱回收其他应用程序，以下称“我们”。上海万物新生环保科技集团有限公司注册地址为：上海市杨浦区淞沪路433号1101-1103室。个人信息保护负责人联系方式为：4001616365）深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过《爱回收隐私政策》（“本隐私政策”）向您说明我们在您使用我们的服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。</h2>
          <dl>
            <dd>本隐私权政策适用于爱回收所提供的服务。但不适用于爱回收网站上由其他第三方向您提供的服务。</dd>
            <dt>本政策将帮助您了解以下内容：</dt>
            <dd>1、我们如何收集和使用您的个人信息</dd>
            <dd>2、我们如何使用 Cookie 和同类技术</dd>
            <dd>3、我们如何共享、转让、公开披露您的个人信息</dd>
            <dd>4、我们如何保护和保存您的个人信息</dd>
            <dd>5、您如何管理个人信息</dd>
            <dd>6、未成年人信息的保护</dd>
            <dd>7、通知和修订</dd>
            <dd>8、如何联系我们</dd>
          </dl>
          <h2>本隐私政策与您所使用的爱回收服务以及该服务所包括的各种业务功能（以下统称“我们的服务”）息息相关，希望您在使用我们的服务前仔细阅读并确认您已经充分理解本政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，以便您更好地理解。您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的服务，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。</h2>
          <dl>
            <dd>
              如对本隐私政策或相关事宜有任何问题，如对本政策内容有任何疑问、意见或建议，您可通过本政策最下方
              <b>【如何联系我们】</b>
              约定的联系方式与我们联系。
            </dd>
          </dl>
          <h2>一、我们如何收集和使用您的个人信息</h2>
          <dl>
            <dd>
              个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中涉及的个人信息包括：基本信息（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱）；个人身份信息（包括身份证、护照）；面部特征；网络身份标识信息（包括系统账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人财产信息（交易和
              消费记录、以及余额、优惠券虚拟财产信息）；个人上网记录（包括网站浏览记录、软件使用记录、点击记录）；个人常用设备信息（包括硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息在内的描述个人常用设备基本情况的信息）；个人位置信息（包括精准定位信息）；
            </dd>
            <dd>个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇的个人信息，本隐私政策中涉及的个人敏感信息包括：您的财产信息（包括交易记录及爱回收账户总额、优惠券虚拟财产信息）；面部识别特征；个人身份信息（包括身份证、军官证、护照、驾驶证、户口本）；网络身份识别信息（包括账户名、邮箱地址及与前述有关的密码与密码保护问题和答案）；其他信息（包括个人电话号码、手机号码、网页浏览记录、精准定位信息）。</dd>
            <dd>我们仅会出于以下目的，收集和使用您的个人信息:</dd>
          </dl>
          <h2>（一）您须授权我们收集和使用您个人信息的情形</h2>
          <dl>
            <dd>
              我们的服务包括一些核心功能，这些功能包含了实现网上二手电子产品交易所必须的功能、改进我们的服务所必须的功能及保障交易安全所必须的功能
              <b>。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的服务。这些功能包括：</b>
            </dd>
          </dl>
          <h2>1、实现网上二手电子产品交易所必须的功能</h2>
          <dl>
            <dt>（1）用户注册</dt>
            <dd>
              您首先需要注册一个爱回收账户成为爱回收用户
              <b>。当您注册时，您需要至少向我们提供您准备使用的爱回收账户名、密码、您本人的手机号码、电子邮箱地址（如有，用于验证邮箱），我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。</b>
            </dd>
            <dd><b>另外，我们会收集您的位置信息（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，为您匹配所在区域适当的服务方式，如您所在区域有爱回收门店的，爱回收将自动为您展示您所在区域距离您最近可以提供服务的门店。</b></dd>
            <dt>（2）产品信息展示和搜索</dt>
            <dd><b>为了让您快速地找到与您需要交易产品一致或类似的产品，我们可能会收集您使用我们的服务的设备信息（包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、浏览器类型来为您提供产品信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息。</b></dd>
            <dd>
              您也可以通过搜索来精准地找到您所需要的产品或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的产品。
              <b>请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。</b>
            </dd>
            <dt>（3）下单</dt>
            <dd>
              当您准备对您拟交易的二手电子产品进行出售时，爱回收系统会生成您售出该产品的订单。在选择“上门回收”或“快递回收”这两种回收方式时，
              <b>您需要在订单中至少填写您的姓名、身份证号、收货地址以及手机号码，同时该订单中会载明订单号、您拟出售的产品信息、回收总价及收款方式。</b>
            </dd>
            <dd>上述所有信息构成您的“订单信息”，我们将使用您的订单信息来进行您的身份核验、确定交易、支付结算、完成回收、为您查询订单以及提供客服咨询与后续服务；我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。</dd>
            <dt>（4）收款功能</dt>
            <dd>在您下单后，您可以选择爱回收的关联方或与爱回收合作的第三方支付机构（包括微信支付、支付宝支付、银联、网联等支付通道， 以下称“支付机构”）所提供的支付服务收取回收款。支付功能本身并不收集您的个人信息，但我们需要将您的爱回收订单号与交易金额信息与这些支付机构共享以实现其确认您的收款指令并完成回收款收取。“关联方”指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。</dd>
            <dt>（5）交付产品或服务功能</dt>
            <dd>当您选择“上门回收”或“快递回收”回收方式时，您在下单后，爱回收及其关联方或与爱回收合作的第三方配送公司（包括顺丰、京东等，以下称“配送公司”）将为您完成订单的取件。您知晓并同意：爱回收及其关联方或与爱回收合作的第三方配送公司会在上述环节内使用您的订单信息以保证您的产品能够安全取件。</dd>
            <dd>当您选择“门店回收”回收方式时，您在下单后，爱回收及其关联方或与爱回收合作的第三方公司将为您完成收件的动作。您知晓并同意：爱回收及其关联方或与爱回收合作的第三方公司会在上述环节内使用您的订单信息以保证您的产品能够安全交易。另外，您在至爱回收及其关联方或与爱回收合作的第三方公司的门店进行回收时，您知晓并同意：我们将留取您的身份信息（包括身份证、护照、台胞证、回乡证），目的在于保证交易的安全性。</dd>
            <dd>当您选择“门店回收”或“上门回收”回收方式时，爱回收及其关联方或与爱回收合作的第三方公司可能会对您的交易过程进行录音录像，目的是在于保证交易的安全性。</dd>
            <dt>（6）客服功能</dt>
            <dd>我们的电话客服功能会使用您的账号信息和订单信息。</dd>
            <dd>为保证您的账号安全，我们的客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更收件地址、联系人或联系电话。</dd>

          </dl>
          <h2>2、改进我们的服务所必须的功能</h2>
          <dl>
            <dd>
              <b>我们可能会收集您的订单信息、浏览信息进行数据分析以形成用户画像，用来将您感兴趣的产品或服务信息展示给您；或在您搜索时向您展示您可能希望找到的产品。</b>
              我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。
            </dd>
          </dl>
          <h2>3、保障交易安全所必须的功能</h2>
          <dl>
            <dd>由于二手交易的特殊性，为保障交易的安全，根据交易方式不同，我们可能需要您向我们提供您的身份信息（包括身份证、护照、台胞证、回乡证）、芝麻信用、腾讯信用相关的个人敏感信息完成实名认证或者信用认证。如您拒绝提供上述信息，可能无法完成交易，但不会影响您使用信息浏览、搜索等服务。</dd>
            <dd>另外，为提高您使用我们的服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的浏览信息、订单信息、您常用的软件信息、设备信息手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接；我们也会收集您的设备信息对于爱回收系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。</dd>
          </dl>
          <h2>（二）您可选择是否授权我们收集和使用您的个人信息的情形</h2>
          <dl>
            <dd>
              1、为使您交易二手电子产品更便捷或更有乐趣，从而提升您在爱回收的网上交易体验，我们的以下附加功能中可能会收集和使用您的个人信息。
              <b>如果您不提供这些个人信息，您依然可以进行网上交易，但您可能无法使用这些可以为您所带来交易乐趣的附加功能或在交易时需要重复填写一些信息。</b>
              这些附加功能包括：
            </dd>
            <dd>（1）基于摄像头（相机）的附加功能：您可以使用这个附加功能完成视频拍摄、拍照、扫码的功能。</dd>
            <dd>
              （2）基于图片上传的附加功能：您可以在爱回收上传您的照片来实现晒单及评价功能，
              <b>我们会使用包含您所上传图片的评价。</b>
            </dd>
            <dd>
              2、上述附加功能可能需要您在您的设备中向我们开启您的相机（摄像头）、相册（图片库）的访问权限，以实现这些功能所涉及的信息的收集和使用。您可以在爱回收权限设置中逐项查看您上述权限的开启状态，并可以决定将这些权限随时的开启或关闭。
              <b>请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</b>
            </dd>
          </dl>
          <h2>（三）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</h2>
          <dl>
            <dt>1、与国家安全、国防安全有关的；</dt>
            <dt>2、与公共安全、公共卫生、重大公共利益有关的；</dt>
            <dt>3、与犯罪侦查、起诉、审判和判决执行等有关的；</dt>
            <dt>4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</dt>
            <dt>5、所收集的个人信息是个人信息主体自行向社会公众公开的；</dt>
            <dt>6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</dt>
            <dt>7、根据您的要求签订合同所必需的；</dt>
            <dt>8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</dt>
            <dt>9、为合法的新闻报道所必需的；</dt>
            <dt>10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</dt>
            <dt>11、法律法规规定的其他情形。</dt>
          </dl>
          <h2>（四）我们从第三方获得您个人信息的情形</h2>
          <dl>
            <dd>我们可能从第三方获取您授权共享的账户信息（头像、昵称），并在您同意本隐私政策后将您的第三方账户与您的爱回收账户绑定，使您可以通过第三方账户直接登录并使用我们的服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</dd>
          </dl>
          <h2>（五）您个人信息使用的规则</h2>
          <dl>
            <dd>1、我们会根据本隐私政策的约定并为实现我们的服务功能对所收集的个人信息进行使用。</dd>
            <dd>
              2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。
              <b>请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</b>
            </dd>
            <dt>3、请您注意，您在使用我们的服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。</dt>
            <dd>4、我们会对我们的服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</dd>
            <dd>5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</dd>
            <dd>6、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</dd>
          </dl>
          <h2>二、我们如何使用 Cookie 和同类技术</h2>
          <dl>
            <dt>（一）Cookies的使用</dt>
            <dd>1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies 是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的偏好或其他产品信息数据进而为您提供交易的偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账户或数据安全。</dd>
            <dd>2、我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问爱回收网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</dd>
            <dt>（二）网络Beacon和同类技术的使用</dt>
            <dd>除 Cookie 外，我们可能还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些电子图像（称为&quot;单像素&quot;GIF 文件或&quot;网络 beacon&quot;）。我们使用网络beacon的方式有：</dd>
            <dd>1、通过在爱回收网站上使用网络beacon，计算用户访问数量，并通过访问 cookie 辨认注册的爱回收用户。</dd>
            <dd>2、通过得到的cookies信息，为您提供个性化服务。</dd>
          </dl>
          <h2>三、我们如何共享、转让、公开披露您的个人信息</h2>
          <dl>
            <dt>（一）共享</dt>
            <dt>1、我们不会与爱回收以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</dt>
            <dd>
              （1）事先获得您明确的同意或授权；
              <br />
              （2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
              <br />
              （3）在法律法规允许的范围内，为维护爱回收、爱回收的关联方或合作伙伴、您或其他爱回收用户或社会公众利益、财产或安全免遭损害而有必要提供；
              <br />
              （4） 只有共享您的信息，才能实现我们的服务的核心功能或提供您需要的服务；
              <br />
              （5）应您需求为您处理您与他人的纠纷或争议；
              <br />
              （6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
              <br />
              （7）基于学术研究而使用；
              <br />
              （8）基于符合法律法规的社会公共利益而使用。
              <br />
            </dd>
            <dt>
              2、我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
              <br />
              3、我们可能会向合作伙伴这样的第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。 我们的合作伙伴包括以下类型：
            </dt>
            <dd>
              （1）产品或技术服务的供应商。
              <b>我们可能会将您的个人信息共享给支持我们功能的第三方。</b>
              这些支持包括为我们的提供基础设施技术服务、物流配送服务、支付服务、数据处理。我们共享这些信息的目的是可以实现我们产品与/或服务的核心交易功能，比如我们必须与物流服务提供商共享您的订单信息才能安排收件；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的收款指令并完成收款等。
              <br />
              （2）第三方商家。如您是通过第三方商家网站的“以旧换新”等类似功能使用我们所提供服务的，
              <b>我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来完成您的交易，并促使第三方商家可以完成后续的优惠券发放、使用等服务。</b>
              <br />
              （3）第三方信用服务提供商。如您选择了“信用回收”的方式，
              <b>我们必须将您的订单信息与交易有关的必要信息与第三方信用服务提供商共享来评估您是否符合我们信用回收的资质。</b>
              <br />
              （4）委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们服务的用户群提供促销推广的服务。
              <b>我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（“委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。</b>
              比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的产品/使用了委托方的服务，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。
              <br />
              4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。
              <br />
              5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护爱回收、您或其他爱回收客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
            </dd>
            <dt>（二）转让</dt>
            <dd>
              我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
              <br />
              1、事先获得您明确的同意或授权；
              <br />
              2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
              <br />
              3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
              <br />
              <b>4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。 </b>
            </dd>
            <dt>（三）公开披露</dt>
            <dd>
              我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
              <br />
              1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
              <br />
              2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
            </dd>
          </dl>
          <h2>四、我们如何保护和保存您的个人信息</h2>
          <dl>
            <dt>（一）我们保护您个人信息的技术与措施</dt>
            <dd>
              我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
              <br />
              1、数据安全技术措施
              <br />
              我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。
              <br />
              爱回收的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。
              <br />
              爱回收采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。
              <br />
              2、爱回收为保护个人信息采取的其他安全措施
              <br />
              爱回收通过建立数据分类分级制度、数据安全管理规范来管理规范个人信息的存储和使用。
              <br />
              爱回收通过信息接触者保密条款或保密协议来对数据进行全面安全控制。
              <br />
              3、我们仅允许有必要知晓这些信息的爱回收及爱回收关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与爱回收的合作关系。
              <br />
              4、我们会采取一切合理可行的措施，确保未收集无关的个人信息。
              <br />
              5、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。
              <br />
              6、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
              <br />
              7、安全事件处置
              <br />
              在通过爱回收网站与第三方进行网上服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
              <br />
              为应对个人信息泄露、损毁和丢失等可能出现的风险，爱回收制定了应急预案。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
              <br />
              8、如果您对我们的个人信息保护有任何疑问，可通过本政策最下方约定的联系方式联系我们。
              <b>如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策最下方【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。  </b>
            </dd>
            <dt>（二）您个人信息的保存</dt>
            <dd>
              1、您的个人信息将全被存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。
              <br />
              <b>2、请您注意，当您成功申请注销爱回收账户后，我们将在您提出申请的合理时间内完成对您的账户注销审核，审核通过后，我们将对您的个人信息进行删除或匿名化处理。</b>
              <br />
              <b>3、如果我们终止服务或运营，我们将以推送通知、公告等方式通知您，并在合理的期限内对您的个人信息进行删除或匿名化处理。</b>
              <br />
              <u>
                <b>4、我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求，</b>
                例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准主要包括：
                <br />
                （1）完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；
                <br />
                （2）保证我们为您提供服务的安全和质量；
                <br />
                （3）您是否同意更长的留存期间；
                <br />
                （4）是否存在保留期限的其他特别约定。
                <br />
                <b>在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</b>
              </u>
            </dd>
          </dl>
          <h2>五、您如何管理您的个人信息</h2>
          <dl>
            <dd>爱回收非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</dd>
            <dt>1、访问和更正您的个人信息</dt>
            <dd>除法律法规规定外，您有权随时访问和更正您的个人信息，包括您的账户信息、地址信息、订单信息、评论信息等，对于您无法访问和更正的信息，请您与我们联系，我们会尽快响应您的请求。</dd>
            <dt>2、删除您的个人信息</dt>
            <dd>
              您在我们的服务页面中可以直接清除或删除的信息，包括搜索记录等；
              <br />
              在以下情形中，您可以向我们提出删除个人信息的请求：
              <br />
              （1）如果我们处理个人信息的行为违反法律法规；
              <br />
              （2）如果我们收集、使用您的个人信息，却未征得您的同意；
              <br />
              （3）如果我们处理个人信息的行为违反了与您的约定；
              <br />
              （4）如果您注销了爱回收账户；
              <br />
              （5）如果我们终止服务及运营。
              <br />
              当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
            </dd>
            <dt>3、改变您授权同意的范围或撤回您的授权</dt>
            <dd>
              <b>您可以通过删除信息、关闭设备功能、在爱回收网站或软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。</b>
              <br />
              <b>请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</b>
            </dd>
            <dt>4、注销账户</dt>
            <dd>
              您可以在我们的产品中直接申请注销您的账户。移动端访问路径为：【我的-设置-账号与安全-永久注销账号】。
              <br />
              关于您注销账户的方式以及您应满足的条件，请详见
              <Link to='/privacy/log-out'>《爱回收账户注销须知》</Link>
              。 您注销账户后，我们将停止为您提供服务，并依据您的要求，除本隐私政策特别约定及法律法规另有规定外，我们将删除您的个人信息。
            </dd>
            <dt>5、响应您的请求</dt>
            <dd>
              如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息，或您认为爱回收存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以通过本协议下方的方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后尽快答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
              <br />
              在以下情形中，按照法律法规要求，我们将无法响应您的请求：
              <br />
              （1）与国家安全、国防安全有关的；
              <br />
              （2）与公共安全、公共卫生、重大公共利益有关的；
              <br />
              （3）与犯罪侦查、起诉和审判等有关的；
              <br />
              （4）有充分证据表明您存在主观恶意或滥用权利的；
              <br />
              （5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
            </dd>
          </dl>
          <h2>六、未成年人的个人信息保护</h2>
          <dl>
            <dd>
              1、爱回收非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的服务前，应事先取得您家长或法定监护人的书面同意。爱回收根据国家相关法律法规的规定保护未成年人的个人信息。
              <br />
              2、对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
              <br />
              3、如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
            </dd>
          </dl>
          <h2>七、通知和修订</h2>
          <dl>
            <dd>
              1、为给您提供更好的服务以及随着爱回收业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在爱回收网站、爱回收移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问爱回收以便及时了解最新的隐私政策。
              <br />
              2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
              <br />
              本政策所指的重大变更包括但不限于：
              <br />
              （1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
              <br />
              （2）个人信息共享、转让或公开披露的主要对象发生变化；
              <br />
              （3）您参与个人信息处理方面的权利及其行使方式发生重大变化；
              <br />
              （4）个人信息安全影响评估报告表明存在高风险时。
            </dd>
          </dl>
          <h2>八、如何联系我们</h2>
          <dl>
            <dd>
              1、如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请通过爱回收的在线客服系统或拨打我们的客服电话等多种方式与我们联系。我们将在收到您的请求后的合理时间内给您回复。
              <br />
              2、如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default PrivacyV1;
