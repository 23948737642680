/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { ossImg } from '../../utils/oss';
import classes from './Index.module.scss';

function PrivacyV11() {
  return (
    <div className={classes.help}>
      <div className={classes.mainSection}>
        <div className={classes.privacyContent}>
          <p>
            <strong>版本日期：2022 年 01 月 19 日</strong>
            <br />
            <strong>生效日期：2022 年 01 月 26 日</strong>
          </p>
          <p>
            尊敬的爱回收用户：我们对《爱回收隐私政策》进行了更新，此版本更新主要集中在文案汇总、归纳及优化，以便于您更加清晰、直观了解个人信息的保护政策。
          </p>
          <p>
            <strong>
              【特别提示】
            </strong>
            {' '}
            <br />
            <strong>
              本政策仅适用于上海万物新生环保科技集团有限公司及其关联公司（包括但不限于上海悦亿网络信息技术有限公司、常州悦亿网络信息技术有限公司，以下或称“我们”或“爱回收”）提供的产品和服务及其延伸的功能（以下简称“爱回收服务”），包括爱回收APP、网站、客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。如果我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用，该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。如果我们关联公司的产品或服务中使用了爱回收提供的产品或服务但未设置独立隐私政策的，则本隐私政策同样适用于该部分产品或服务。
            </strong>
            <br />
            <strong>请仔细阅读《爱回收隐私政策》（尤其是粗体内容）并确定了解我们对您个人信息的处理规则。</strong>
            阅读过程中，如果您有任何疑问，您可通过以下联系方式与我们联系：
            <br />
            联系电话：
            <strong>4001616365</strong>
            <br />
            注册地址：
            <strong>上海市杨浦区淞沪路 433 号 1101-1103 室</strong>
            <br />
            常用办公地址：
            {' '}
            <strong>上海市杨浦区淞沪路 433 号 1101-1103 室</strong>
            <br />
            <br />
          </p>
          <p>
            <strong>
              ※ 您同意隐私政策仅代表您已了解应用提供的功能，以及功能运行所需的必要个人信息，并不代表您已同意我们可以收集非必要个人信息，非必要个人信息会根据您使用过程中的授权情况单独征求您的同意。
            </strong>
          </p>
          <p>
            <strong>
              ※ 相应设备权限并不会默认开启，当涉及重要或敏感的设备权限时，我们会在您使用到相应业务功能时，另行弹窗再次征得您的同意后开启；权限开启后，您还可以随时通过设备设置关闭权限；你不同意开启权限，将不会影响其他非相关业务功能的正常使用。针对个人敏感信息，我们会单独征得您的同意后进行处理。
            </strong>
          </p>
          <p>
            <strong>
              ※ 如果您拒绝本《隐私政策》，您仍然能够在无注册/登录状态下使用浏览、查看的基本功能。您也可以在后续使用服务过程中单独进行授权。
            </strong>
          </p>
          <h3>引言</h3>
          <p>
            <strong>
              欢迎您使用爱回收！我们非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们会收集和使用您的相关信息。我们希望通过《爱回收隐私政策》（“本隐私政策”）向您说明在您使用我们的产品与/或服务时我们如何收集、使用、保存、共享和转移这些信息，以及我们为您提供的访问、更新、获取、删除和保护这些信息的方式。
            </strong>
          </p>
          <p>
            本隐私权政策适用于爱回收所提供的产品及服务。但不适用于爱回收网站上由其他第三方向您提供的服务。
          </p>
          <p>
            <strong>本政策将帮助您了解以下内容：</strong>
            <br />
            <span>1 、我们如何收集和使用您的个人信息</span>
            <br />
            <span>2 、我们如何使用 Cookie 和同类技术</span>
            <br />
            <span>3 、我们如何共享、转让、公开披露您的个人信息</span>
            <br />
            <span>4 、我们如何保护和保存您的个人信息</span>
            <br />
            <span>5 、您如何管理个人信息</span>
            <br />
            <span>6 、未成年人的个人信息保护</span>
            <br />
            <span>7 、通知和修订</span>
            <br />
            <span>8 、如何联系我们</span>
          </p>
          <h4>
            一、我们如何收集和使用您的个人信息
          </h4>
          <p>
            <strong>个人信息</strong>
            是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。
            <strong>敏感个人信息</strong>
            是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹信息，以及不满十四周岁未成年人的个人信息。
          </p>
          <p>
            我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用产品与/或服务过程中主动提供或因使用产品与/或服务而产生的个人信息，以及从第三方获取您的相关个人信息。
            <strong>如果我们要将您的个人信息用于本政策未载明的其他用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。</strong>
          </p>
          <p>
            <strong>（一）您须授权我们收集和使用您个人信息的情形</strong>
          </p>
          <p>
            我们的产品与/或服务包括一些核心功能，这些功能包含了实现网上二手物品交易及购物所必须的功能、改进我们的服务所必须的功能及保障交易安全所必须的功能。
            <strong>
              我们会收集和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括:
            </strong>
          </p>
          <p>
            1 、
            <strong>实现网上交易所必须的功能</strong>
          </p>
          <p>
            <strong>（1）账号注册、登录与验证</strong>
          </p>
          <p>
            您首先需要注册一个爱回收账户成为爱回收用户。
            <strong>当您注册爱回收账号时，您需要至少向我们提供您准备使用的爱回收账户名、密码、您本人的手机号码、电子邮箱地址(用于验证邮箱)，我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。</strong>
          </p>
          <p>
            <strong>（2）产品信息展示和搜索</strong>
          </p>
          <p>
            <strong>在您使用我们服务过程中，为了让您快速地找到附近的爱回收门店或您所需要交易的商品，向您提供更契合您需求的页面展示和搜索结果，我们会请求您提供或收集您在访问或使用我们的产品/服务时产生的相关信息，包括：</strong>
          </p>
          <p>
            <strong>
              A、位置信息：
            </strong>
            当您通过系统授权开启移动设备的定位功能并使用基于位置提供的服务时，我们会收集和使用您的位置信息来判断您所处的地点，为您匹配所在区域适当的服务方式，您所在区域有爱回收门店的，爱回收将自动为您展示您所在区域距离您最近可以提供服务的门店。我们会使用有关技术获取您的位置信息（准确度会有所不同），这些技术包括IP 地址、GPS 以及能够提供相关信息的WLAN接入点、蓝牙和基站。您可以在移动设备的系统中关闭定位服务停止我们对您所在位置信息的收集，但因此无法使用我们基于地理位置为您提供的服务，或者无法达到相关服务的预期效果。
          </p>
          <p>
            <strong>B、设备信息：</strong>
            我们会根据您在软件安装及使用中的具体操作，经您授权后我们会在静默/后台状态中接收并记录
            <strong>设备相关信息（包括设备名称、设备型号、MAC 地址及 IMEI、操作系统和应用程序版本、设备设置、设备广告标识符、设备识别码(OAID/IDFA/SIM 卡信息/IMSI/Android_ID)、服务提供商网络 ID(PLMN)、WI-FI 信息、应用进程列表、移动应用列表、分辨率、软硬件及设备环境信息）来对相应的产品或者服务内容进行排序，以适应您的设备界面。</strong>
          </p>
          <p>
            <strong>C、关键字及剪贴板内容：</strong>
            当您使用爱回收提供的搜索功能时，为方便您再次搜索相同的内容我们会记录和存储
            <strong>您查询的关键字信息，并访问您当前设备的剪切板内容。该关键词信息通常无法单独识别您的个人身份，不属于您的个人信息，不在本指引的规范范围内。只有当您搜索过的关键词信息与您的其他信息有联结并可识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，按照本指引对其进行处理与保护。</strong>
          </p>
          <p>
            <strong>（3）下单</strong>
          </p>
          <p>
            A．出售二手物品：
          </p>
          <p>
            当您准备对您拟交易的二手物品进行出售时，爱回收系统会生成您出售该物品的订单，
            <strong>您需要在订单中至少填写您的姓名、身份证号、收货地址以及手机号码及二手物品相关信息，同时该订单中会载明订单号、您拟出售的物品信息、回收总价及收款方式。</strong>
          </p>
          <p>
            如果您出售的二手物品为特殊类型商品时还需要您提供其他必要信息，在进行奢侈品回收时需要您上传回收品相关图片，需要您
            <strong>授权摄像头及相册存储权限</strong>
            ，以进行图片的拍摄和上传。
          </p>
          <p>
            <strong>根据《旧货流通管理办法》（试行）、《旧电器电子产品流通管理办法》相关法律法规的规定旧货经营者应当登记出售人的身份证信息。</strong>
          </p>
          <p>
            B．购买商品或服务：
          </p>
          <p>
            在您购买商品或服务时，您需要在订单中至少填写您的
            <strong>联系人姓名、收货地址</strong>
            以及
            <strong>手机号码</strong>
            ，在您为手机购买延保服务时，还需要您提供被保手机的IMEI信息。同时该订单中会载明订单号、您所购买的商品或服务信息、下单时间、您应支付的货款金额及支付方式。若您需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票的电子邮箱。
          </p>
          <p>
            上述所有信息构成您的“订单信息”，我们将使用您的订单信息(包括与为您提供上述服务的第三方)进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务。我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。
          </p>
          <p>
            <strong>（4）收款及支付功能</strong>
          </p>
          <p>
            在您下单后，您可以选择爱回收的关联方或与爱回收合作的第三方支付机构(包括微信支付、支付宝支付、银联、网联及其他支付通道，以下称
            <strong>“支付机构”</strong>
            )所提供的支付服务收取回收款。支付功能本身并不收集您的个人信息，
            <strong>但我们需要将您的爱回收订单号与交易金额信息，包括您的收款银行及银行卡号、有效期及其他银行卡（含储蓄卡、信用卡）收款/支付必要信息与这些支付机构共享以实现其确认您的收款/支付指令并完成收款或支付。</strong>
          </p>
          <p>
            <strong>“关联方”</strong>
            指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。
            <strong>“控制”</strong>
            是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
          </p>
          <p>
            <strong>
              （5）交付产品与/或服务功能
            </strong>
          </p>
          <p>
            当您选择“上门方式”或“快递方式”进行交易时，您在下单后，爱回收及其关联方或与爱回收合作的第三方配送公司(包括顺丰、京东，以下称
            <strong>“配送公司”</strong>
            将为您完成订单的取件/交付。您知晓并同意:
            <strong>爱回收及其关联方，或与爱回收合作的第三方配送公司会在上述环节内使用您的订单信息以保证您的出售或订购的物品能够安全取走/送达。</strong>
          </p>
          <p>
            当您选择“门店方式”进行交易时，您在下单后，需您到指定线下门店进行交易，由爱回收及其关联方或与爱回收合作的第三方将为您提供服务。您知晓并同意:爱回收及其关联方或与爱回收合作的第三方会在上述环节内使用您的订单信息以保证您的产品能够安全交易。
          </p>
          <p>
            当您选择“门店方式”或“上门方式”进行交易时，爱回收及其关联方或与爱回收合作的第三方公司会对您的
            <strong>交易过程进行录音录像</strong>
            ，如果交易中有您出售物品给爱回收的，则
            <strong>基于法律要求实名认证的需求，爱回收需收集您的身份信息(包括身份证、护照、台胞证、回乡证)</strong>
            ，目的是在于保证交易的安全性。
          </p>
          <p>
            <strong>（6）客服与售后功能</strong>
          </p>
          <p>
            我们的电话客服与售后功能会使用您的账号信息和订单信息。
          </p>
          <p>
            我们的电话客服和售后功能会使用您的账号信息和订单信息。
            <br />
            为保证您的账号安全，我们的客服会使用您的账号信息与您
            <strong>核验您的身份</strong>
            。当您需要我们提供与您订单信息相关的客服和售后服务时，我们将会查询您的
            <strong>订单信息</strong>
            。您需在与我们的客服人员沟通时，提供给出上述信息外的其他信息，当您要求我们变更收件地址、联系人或联系电话。
          </p>
          <p>
            2 、
            <strong>保障交易安全所必须的功能</strong>
          </p>
          <p>
            为保障您使用我们的产品与/或服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们需要收集您的
            <strong>设备信息（设备型号、设备Mac地址、应用进程列表、设备识别码、设备序列号、操作系统及软件版本、设备状态、网络状况）、历史上网记录、日志信息、面部信息、IP地址、位置信息、浏览信息、订单信息、常用软件列表、ssid、wifi信息、IMSI、SIM卡信息、电池使用情况来识别是否为真实自然人用户、是否为异常地点登录、判断您的账号风险</strong>
            ，并记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息用于对爱回收系统问题进行分析、统计流量并排查潜在的风险，在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用爱回收服务过程中对您的服务及账号安全进行保护。
          </p>
          <p>
            <strong>
              （二）您可自主选择提供个人信息的情形
            </strong>
          </p>
          <p>
            为使您的物品交易更便捷或更有乐趣，从而提升您在爱回收的网上交易体验，我们的扩展附加功能中会收集和使用您的个人信息。
            <strong>如果您不提供这些个人信息，您依然可以进行网上交易，但您无法使用为您所带来交易乐趣的扩展功能或在交易时需要重复填写一些信息。</strong>
            关于这些扩展功能收集的信息和使用目的，请您详见
            {' '}
            <Link to='/privacy/permissions'>《爱回收权限列表》</Link>
            。
          </p>
          <p>
            <strong>
              请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
            </strong>
          </p>
          <p>
            <strong>
              （三）您应充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意:
            </strong>
          </p>
          <p>
            1 、
            <strong>与国家安全、国防安全有关的；</strong>
            <br />
            2 、
            <strong>与公共安全、公共卫生、重大公共利益有关的；</strong>
            <br />
            3 、
            <strong>与犯罪侦查、起诉、审判和判决执行等有关的；</strong>
            <br />
            4 、
            <strong>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</strong>
            <br />
            5 、
            <strong>所涉及的个人信息是个人信息主体或监护人自行向社会公众公开的；</strong>
            <br />
            6 、
            <strong>从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</strong>
            <br />
            7 、
            <strong>根据您的要求签订和履行合同所必需的；</strong>
            <br />
            8 、
            <strong>用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</strong>
            <br />
            9 、
            <strong>为合法的新闻报道所必需的；</strong>
            <br />
            10 、
            <strong>学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</strong>
            <br />
            11 、
            <strong>法律法规规定的其他情形。</strong>
          </p>
          <p>
            <strong>
              （四）我们从第三方获得您个人信息的情形
            </strong>
          </p>
          <p>
            我们有从第三方获取您授权共享的账户信息(头像、昵称)，并在您同意本隐私政策后将您的第三方账户与您的爱回收账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
          </p>
          <p>
            <strong>
              （五）爱回收APP涉及嵌入第三方代码、插件传输个人信息的情形逐项列举，
              <Link to='/privacy/vendor-person-date'>请见此处</Link>
              。
            </strong>
          </p>
          <p>
            <strong>
              （六）您个人信息使用的规则
            </strong>
          </p>
          <p>
            1、我们会根据本隐私政策的约定为实现我们的产品与/或服务功能而对所收集的个人信息进行使用。
          </p>
          <p>
            2 、在收集您的个人信息后，我们将通过技术手段对信息进行去标识化处理，去标识化处理的信息将无法识别主体。
            <strong>请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</strong>
          </p>
          <p>
            3、
            <strong>请您了解并同意，对于您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则被视为将在您使用我们的产品与/或服务期间持续授权同意我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。</strong>
          </p>
          <p>
            4 、我们会对我们的产品与/或服务使用情况进行统计，并会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
          </p>
          <p>
            <span>
              5 、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
            </span>
          </p>
          <p>
            6 、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的个人信息用于其他目的时，会事先征求您的明示同意。
            <span id='chapter-2' />
          </p>
          <p>
            <strong>请您理解，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。</strong>
          </p>
          <h3>二、我们如何使用 Cookie 和同类技术</h3>
          <p>
            <strong>
              （一）Cookies 的使用
            </strong>
          </p>
          <p>
            1、为使您获得更轻松的访问体验，我们会在您的计算机或移动设备上发送一个或多个名为 Cookies 的小数据文件，指定给您的 Cookies 是唯一的，它只能被将 Cookies 发布给您的域中的 Web 服务器读取。我们向您发送 Cookies 是为了简化您重复登录的步骤、帮助判断您的登录状态以及账户或数据安全。
          </p>
          <p>
            2、我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受 Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有 Cookies。但如果您这么做，您需要在每一次访问爱回收网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且会对您所使用服务的安全性有一定影响。
          </p>
          <p>
            <strong>
              （二）网络 Beacon 和同类技术的使用
            </strong>
          </p>
          <p>
            除 Cookie 外，我们还会在网站上使用网络 Beacon 及其他同类技术。我们的网页上常会包含一些电子图像(称为&quot;单像素&quot;GIF 文件或&quot;网络 beacon&quot;)。我们使用网络 beacon 的方式有:
          </p>
          <p>
            1、通过在爱回收网站上使用网络 beacon，计算用户访问数量，并通过访问 cookie 辨认注册的爱回收用户。
            <span id='chapter-3' />
          </p>
          <p>
            2、通过得到的 cookies 信息，为您提供历史搜索记录便捷功能。
          </p>
          <h4>三、我们如何共享、转让、公开披露您的个人信息</h4>
          <p>
            <strong>（一）共享</strong>
          </p>
          <p>
            1、
            <strong>我们不会与爱回收以外的任何公司、组织和个人共享您的个人信息，但以下情况除外:</strong>
            {' '}
            <br />
            (1)事先获得您明确的同意或授权；
            <br />
            (2)根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
            <br />
            (3)在法律法规允许的范围内，为维护爱回收、爱回收的关联方或合作伙伴、您或其他爱回收用户或社会公众利益、财产或安全免遭损害而有必要提供；
            <br />
            (4)只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
            <br />
            (5)应您需求为您处理您与他人的纠纷或争议；
            <br />
            (6)符合与您签署的相关协议(包括在线签署的电子协议以及相应的平台规则)或其他的法律文件约定所提供；
            <br />
            (7)基于学术研究而使用；
            {' '}
            <br />
            (8)基于符合法律法规的社会公共利益而使用。
          </p>
          <p>
            2 、
            <strong>
              为向您提供相关产品或服务，保护您的账号与交易安全，我们会将您的个人信息与我们的关联方共享，共享内容及目的详见
              <Link to='/privacy/vendor-person-date'>《个人信息共享清单》</Link>
              。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
            </strong>
          </p>
          <p>
            <strong>请您注意，您在使用我们服务时自愿共享甚至公开分享的信息，会涉及您或他人的个人信息甚至敏感个人信息。请您共享时谨慎考虑并决定。</strong>
          </p>
          <p>
            3 、
            <strong>我们会向第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供产品与/或服务所必要的个人信息</strong>
            。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
          </p>
          <p>
            仅为实现本政策中声明的目的，我们的某些服务将由业务合作伙伴提供。为保障为您提供的服务顺利完成，我们会将您的个人信息共享给上述合作伙伴，包括配送业务、技术服务、支付服务。其中包括您的
            <strong>联络信息、订单信息、支付信息、地址信息</strong>
            ，以保障为您提供的服务顺利完成。在您使用我们涉及物流配送的功能时，我们必须与配送服务提供商共享您的订单和配送信息，以保证订单的安全准确送达；当您使用第三方服务商所提供的服务时，我们需要向第三方服务商提供您的订单和联络信息，以保障第三方服务商能顺利为您提供服务；我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付。
            <strong>其中针对您的联络信息和地址信息，我们已经采取了严格的加密举措尽全力保护您的个人信息。</strong>
            <br />
            我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、提供客户服务、支付便利或进行学术研究和调查。 请您知悉，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，对我们仅为实现本政策中声明的目的与之共享个人信息的公司、组织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。
          </p>
          <p>
            4 、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护个人敏感信息。
          </p>
          <p>
            5 、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护爱回收、您或其他爱回收客户的权利及其财产或安全，防止违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
          </p>
          <p>
            <strong>
              （二）转让
            </strong>
          </p>
          <p>
            <strong>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</strong>
          </p>
          <p>
            1 、事先获得您明确的同意或授权；
          </p>
          <p>
            2 、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
          </p>
          <p>
            3 、符合与您签署的相关协议(包括在线签署的电子协议以及相应的平台规则)或其他的法律文件约定所提供；
          </p>
          <p>
            4 、
            <strong>在涉及合并、收购、资产转让或类似的交易时，如果涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新征求您的授权同意。</strong>
          </p>
          <p>
            <strong>（三）公开披露</strong>
          </p>
          <p>
            我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息:
          </p>
          <p>
            1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
          </p>
          <p>
            2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽全力保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。
            <span id='chapter-4' />
          </p>
          <p>
            <strong>
              四、我们如何保护和保存您的个人信息
            </strong>
          </p>
          <p>
            <strong>
              （一）我们保护您个人信息的技术与措施
            </strong>
          </p>
          <p>
            我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息:
          </p>
          <p>
            1、
            <strong>数据安全技术措施</strong>
            <br />
            我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。
            <br />
            爱回收的网络服务采取了传输层安全协议加密技术，通过 https 及其他方式提供浏览服务， 确保用户数据在传输过程中的安全。
            <br />
            爱回收采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。
            <br />
            在个人信息使用时，个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性；
            <br />
            爱回收采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用；
            <br />
            爱回收采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。
          </p>
          <p>
            2 、
            <strong>爱回收为保护个人信息采取的其他安全措施</strong>
            <br />
            爱回收通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
            <br />
            爱回收通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
            <br />
            爱回收建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。
          </p>
          <p>
            3 、
            <strong>
              我们仅允许有必要知晓这些信息的爱回收及爱回收关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，会被追究法律责任或被中止与爱回收的合作关系。
            </strong>
          </p>
          <p>
            4 、
            <strong>
              我们会采取一切合理可行的措施，确保未收集无关的个人信息。
            </strong>
          </p>
          <p>
            5 、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。
          </p>
          <p>
            6 、
            <strong>安全事件处置</strong>
            <br />
            在通过爱回收网站与第三方进行网上服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
            <br />
            为应对个人信息泄露、损毁、丢失、用户安全的风险，爱回收制定了应急预案。
            <br />
            一旦发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知:安全事件的基本情况和产生的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
          </p>
          <p>
            7 、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
          </p>
          <p>
            8 、如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。
            <strong>
              如果您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。
            </strong>
          </p>
          <p>
            <strong>
              （二）您个人信息的保存
            </strong>
          </p>
          <p>
            1 、您的个人信息将存储于中华人民共和国境内。如果您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。
          </p>
          <p>
            2 、
            <strong>
              在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，根据《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
            </strong>
          </p>
          <p>
            3 、
            <strong>
              请您注意，当您成功注销爱回收账户后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以删除或实现匿名化。
            </strong>
          </p>
          <p>
            <span id='chapter-5' />
            4、
            <strong>如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</strong>
          </p>
          <p>
            <strong>
              五、您如何管理您的个人信息
            </strong>
          </p>
          <p>
            爱回收非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括:
          </p>
          <p>
            1 、
            <strong>
              访问和更正您的个人信息
            </strong>
          </p>
          <p>
            除法律法规规定外，您有权随时访问和更正您的个人信息，包括您的账户信息、地址信息、订单信息、评论信息，对于您无法访问和更正的信息，请您与我们联系，我们会尽快响应您的请求。
          </p>
          <p>
            2 、
            <strong>
              删除您的个人信息
            </strong>
          </p>
          <p>
            您在我们的服务页面中可以直接清除或删除的信息，包括搜索记录；
          </p>
          <p>
            <strong>
              在以下情形中，您可以向我们提出删除个人信息的请求:
            </strong>
            <br />
            （1）如果我们处理个人信息的行为违反法律法规；
            <br />
            （2）如果我们收集、使用您的个人信息，却未征得您的同意；
            <br />
            （3）如果我们处理个人信息的行为违反了与您的约定；
            <br />
            （4）如果您注销了爱回收账户；
            <br />
            （5）如果我们终止服务及运营。
          </p>
          <p>
            对于您提出的查询、更改、删除个人信息的请求，我们将在15个工作日内完成处理。若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体已经获得您的独立授权。
            <strong>当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们不能保证立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到可以删除或实现匿名化。</strong>
          </p>
          <p>
            3 、
            <strong>改变您授权同意的范围或撤回您的授权</strong>
          </p>
          <p>
            <strong>您可以通过删除信息、关闭设备功能、在爱回收网站或软件中进行隐私设置的方式改变您授权我们继续收集个人信息的范围或撤回您的授权。</strong>
          </p>
          <p>
            <strong>如果您要撤回某项权限的授予，您需要打开手机设置，在应用列表内找到我们的应用，点击进入后可以查看或修改权限设置。</strong>
          </p>
          <p>
            <strong>
              您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。
            </strong>
          </p>
          <p>
            <strong>请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</strong>
          </p>
          <p>
            4 、
            <strong>注销账户</strong>
          </p>
          <p>
            <strong>
              您可以在我们的产品中直接申请注销您的账户。移动端访问路径为:【我的-设置-账号与安全-永久注销账号】。关于您注销账户的方式以及您应满足的条件，请详见
              <Link to='/privacy/log-out' target='_blank'>《爱回收账户注销须知》</Link>
              。在您满足注销账户申请条件并申请注销账户后，我们将在15个工作日内完成处理，停止为您提供服务，并根据适用法律的要求删除您的个人信息，或进行匿名化处理。
            </strong>
          </p>
          <p>
            5 、
            <strong>
              获取您的个人信息副本
            </strong>
          </p>
          <p>
            您有权获取您的个人信息副本。如果您需要获取我们收集的您的个人信息副本，您随时通过下列方式联系我们。在符合相关法律规定且技术可行的前提下，我们将在15个工组日内根据您的要求向您提供相应的个人信息副本。
            <br />
            联系电话：
            {' '}
            <strong>4001616365</strong>
            <br />
            注册地址：
            {' '}
            <strong>上海市杨浦区淞沪路 433 号 1101-1103 室</strong>
            <br />
            常用办公地址：
            {' '}
            <strong>上海市杨浦区淞沪路 433 号 1101-1103 室</strong>
            <br />
          </p>
          <p>
            6 、
            <strong>
              响应您的请求
            </strong>
          </p>
          <p>
            如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息，或您认为爱回收存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以通过本协议下方的方式与我们联系。为了保障安全，我们需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后尽快答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们会予以拒绝。
            <br />
            在以下情形中，按照法律法规要求，我们将无法响应您的请求:
            <br />
            （1）与国家安全、国防安全有关的；
            <br />
            （2）与公共安全、公共卫生、重大公共利益有关的；
            <br />
            （3）与犯罪侦查、起诉和审判有关的；
            <br />
            （4）有充分证据表明您存在主观恶意或滥用权利的；
            <br />
            （5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
          </p>
          <p>
            <strong>
              六、未成年人的个人信息保护
            </strong>
          </p>
          <p>
            1 、爱回收非常重视对未成年人个人信息的保护。若您是 18 周岁以下的未成年人，在使用我们的服务前，
            <strong>应事先取得您监护人的书面同意</strong>
            。爱回收根据国家相关法律法规的规定保护未成年人的个人信息。
          </p>
          <p>
            2 、对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
          </p>
          <p>
            3 、如果有事实证明未成年人并未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。
          </p>
          <p>
            4 、对于涉及的不满 14 周岁的儿童个人信息，我们进一步采取以下措施予以保障:
            <br />
            （1）对于收集到的儿童个人信息，我们除遵守本隐私政策关于用户个人信息的约定外， 还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》及相关法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。我们会指定专人负责儿童个人信息保护事宜。
            <br />
            （2）当您作为监护人为被监护的儿童选择使用爱回收相关服务时，我们需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用晒单、评价及问答功能分享商品相关信息时会主动向我们提供儿童个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。若儿童本人需要注册或使用我们的产品与/或服务，您应正确引导并予以监护。
            <br />
            （3）儿童或其监护人有权随时访问和更正儿童个人信息，还可以向我们提出更正和删除的请求。如果您对儿童个人信息相关事宜有任何意见、建议或投诉、举报，请联系我们。我们会随时为您提供帮助。
          </p>
          <p>
            <strong>
              七、通知和修订
            </strong>
          </p>
          <p>
            1 、为给您提供更好的服务以及随着爱回收业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在爱回收网站、爱回收移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问爱回收以便及时了解最新的隐私政策。
          </p>
          <p>
            2 、对于重大变更，我们还会提供更为显著的通知(我们会通过包括但不限于邮件、短信或在浏览页面做特别提示的方式，说明隐私政策的具体变更内容)。
          </p>
          <p>
            3、本隐私政策所指的重大变更包括但不限于:
            {' '}
            <br />
            (1)我们的服务模式发生重大变化。处理个人信息的目的、处理的个人信息类型、个人信息的使用方式发生重大变化；
            <br />
            (2)我们在所有权结构、组织架构方面发生重大变化。业务调整、破产并购引起的所有者变更；
            <br />
            (3)个人信息共享、转让或公开披露的主要对象发生变化；
            <br />
            (4)我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
            <br />
            (5)您参与个人信息处理方面的权利及其行使方式发生重大变化；
            <br />
            (6)个人信息安全影响评估报告表明存在高风险时。
          </p>
          <p>
            4、为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。您在本政策更新生效后继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们鼓励您在每次使用我们服务时都查阅本政策。
          </p>
          <p>
            <strong>
              八、如何联系我们
            </strong>
          </p>
          <p>
            1 、您对本隐私政策或您个人信息的相关事宜有任何投诉、疑问或建议，请通过爱回收的在线客服系统或拨打我们的客服电话（4001616365）与我们联系，您也可以通过【我的】-【设置】-【反馈意见】，进入用户反馈界面与我们联系。我们将在收到您的请求后的15个工作日内给您回复。
          </p>
          <p>
            2 、如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益， 您还可以向网信、电信、公安、工商及其他监管部门进行投诉或举报。
          </p>
          <p>
            附：个人信息及敏感个人信息示例（非爱回收APP或网站所收集的个人信息，仅作示例说明）
            <br />
            个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹的信息，以及不满十四周岁未成年人的个人信息。（本定义出自于《中华人民共和国个人信息保护法》）。
            <br />
            <br />
            <a href={ossImg('/privacy/personal-info-sample.png')}>
              <img src={ossImg('/privacy/personal-info-sample.png')} alt='' />
            </a>
            <br />
            <br />
            <a href={ossImg('/privacy/personal-security-info-sample.png')}>
              <img src={ossImg('/privacy/personal-security-info-sample.png')} alt='' />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyV11;
