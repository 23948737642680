import PrivacyV1 from './PrivacyV1';
import PrivacyV2 from './PrivacyV2';
import PrivacyV3 from './PrivacyV3';
import PrivacyV4 from './PrivacyV4';
import PrivacyV5 from './PrivacyV5';
import PrivacyV6 from './PrivacyV6';
import PrivacyV7 from './PrivacyV7';
import PrivacyV8 from './PrivacyV8';
import PrivacyV9 from './PrivacyV9';
import PrivacyV10 from './PrivacyV10';
import PrivacyV11 from './PrivacyV11';
import PrivacyV12 from './PrivacyV12';
import PrivacyV13 from './PrivacyV13';
import PrivacyV14 from './PrivacyV14';
import PrivacyV15 from './PrivacyV15';
import PrivacyV16 from './PrivacyV16';
import PrivacyV17 from './PrivacyV17';
import PrivacyV18 from './PrivacyV18';
import PrivacyV19 from './PrivacyV19';
import PrivacyV20 from './PrivacyV20';
import PrivacyV21 from './PrivacyV21';
import PrivacyV22 from './PrivacyV22';

const routerVersion = [
  {
    path: 'v1',
    component: PrivacyV1,
  },
  {
    path: 'v2',
    component: PrivacyV2,
  },
  {
    path: 'v3',
    component: PrivacyV3,
  },
  {
    path: 'v4',
    component: PrivacyV4,
  },
  {
    path: 'v5',
    component: PrivacyV5,
  },
  {
    path: 'v6',
    component: PrivacyV6,
  },
  {
    path: 'v7',
    component: PrivacyV7,
  },
  {
    path: 'v8',
    component: PrivacyV8,
  },
  {
    path: 'v9',
    component: PrivacyV9,
  },
  {
    path: 'v10',
    component: PrivacyV10,
  },
  {
    path: 'v11',
    component: PrivacyV11,
  },
  {
    path: 'v12',
    component: PrivacyV12,
  },
  {
    path: 'v13',
    component: PrivacyV13,
  },
  {
    path: 'v14',
    component: PrivacyV14,
  },
  {
    path: 'v15',
    component: PrivacyV15,
  },
  {
    path: 'v16',
    component: PrivacyV16,
  },
  {
    path: 'v17',
    component: PrivacyV17,
  },
  {
    path: 'v18',
    component: PrivacyV18,
  },
  {
    path: 'v19',
    component: PrivacyV19,
  },
  {
    path: 'v20',
    component: PrivacyV20,
  },
  {
    path: 'v21',
    component: PrivacyV21,
  },
  {
    path: 'v22',
    component: PrivacyV22,
  },
];

const historyVersion = [
  {
    version: 'v21',
    date: '2024年05月28日版本',
  },
  {
    version: 'v20',
    date: '2024年02月22日版本',
  },
  {
    version: 'v19',
    date: '2023年11月14日版本',
  },
  {
    version: 'v18',
    date: '2023年08月28日版本',
  },
  {
    version: 'v17',
    date: '2023年07月20日版本',
  },
  {
    version: 'v16',
    date: '2023年05月19日版本',
  },
  {
    version: 'v15',
    date: '2022年12月15日版本',
  },
  {
    version: 'v14',
    date: '2022年10月17日版本',
  },
  {
    version: 'v13',
    date: '2022年07月28日版本',
  },
  {
    version: 'v12',
    date: '2022年03月21日版本',
  },
  {
    version: 'v11',
    date: '2022年01月19日版本',
  },
  {
    version: 'v10',
    date: '2022年01月07日版本',
  },
  {
    version: 'v9',
    date: '2021年12月02日版本',
  },
  {
    version: 'v8',
    date: '2021年11月29日版本',
  },
  {
    version: 'v7',
    date: '2021年11月12日版本',
  },
  {
    version: 'v6',
    date: '2021年11月04日版本',
  },
  {
    version: 'v5',
    date: '2021年07月13日版本',
  },
  {
    version: 'v4',
    date: '2021年05月31日版本',
  },
  {
    version: 'v3',
    date: '2021年05月11日版本',
  },
  {
    version: 'v2',
    date: '2020年12月11日版本',
  },
  {
    version: 'v1',
    date: '2019年07月29日版本',
  },
];

export {
  routerVersion,
  historyVersion,
};
