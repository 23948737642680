import React from 'react';

function Anchor(props: { target: Any; children: Any }) {
  const {
    target,
    children,
  } = props;

  const jump = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
    event.preventDefault();
    event.stopPropagation();
    if (target) {
      // 找到锚点
      const anchorElstrongent = document.getElementById(target);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElstrongent) {
        anchorElstrongent.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
    }
  };
  return (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href='#' onClick={jump}>
      {children}
    </a>
  );
}

export default Anchor;
