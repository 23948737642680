/* eslint-disable react/jsx-no-script-url */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from 'react';
import classNames from 'classnames';
import classes from './Index.module.scss';

export default class AhsServices extends Component {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className={classes.help}>
        <div className={classes.mainSection}>
          <h1>爱回收用户服务协议</h1>
          <div>
            <h2>
              版本日期：2023年07月20日
              <br />
              生效日期：2023年07月27日
            </h2>
            <dl>
              <h2>一、服务条款的确认及接受</h2>
              <h3>（一）爱回收网站（指上海万物新生环保科技集团有限公司及其关联方合法拥有并运营的相关网站及客户端应用程序，以下称“本网站”或“爱回收平台”或“平台”）各项电子服务的所有权和运作权归属于“爱回收”所有，本网站提供的服务将完全按照其发布的服务条款和操作规则严格执行。您确认所有服务条款并完成注册程序时，本协议在您与本网站间成立并发生法律效力，同时您成为本网站正式用户。</h3>
              <h3>（二）爱回收平台经营者是指经营爱回收平台的各法律主体，爱回收可能根据平台业务调整而发生变更或新增，您可随时查看爱回收平台各网站首页底部公示的证照信息以确定与您履约的爱回收主体。</h3>
              <h3>（三）爱回收平台隐私政策、平台规则（包括但不限于常见问题）均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用爱回收平台服务，视为您同意上述补充协议。</h3>
              <h3>（四）您在使用本网站某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），您在使用该项服务前请阅读并同意相关的单独协议；您使用前述特定服务，即视为您已阅读并同意接受相关单独协议。</h3>
              <h3>（五）根据国家法律法规变化及本网站运营需要，爱回收有权对本协议条款及相关规则不时地进行修改，修改后的内容将采取合理途径公开征求意见，并取代此前相关内容，您应不时关注本网站公告、提示信息及协议、规则等相关内容的变动。您知悉并确认，如您不同意更新后的内容，应立即停止使用本网站；如您继续使用本网站，即视为知悉变动内容并同意接受。</h3>
              <h3>（六）除非另有明确的书面说明,爱回收不对本网站的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。</h3>
              <h3>（七）本协议内容中以加粗方式显著标识的条款，请您着重阅读。您点击“同意”按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。</h3>
            </dl>
            <dl>
              <h2>二、服务须知</h2>
              <h3>（一）本网站运用自身开发的操作系统通过国际互联网络为用户提供二手商品回收、商品购买等服务。使用本网站，您必须</h3>
              <h3>1. 自行配备上网的所需设备，包括个人手机、平板电脑、调制解调器、路由器等；</h3>
              <h3>2. 自行负担个人上网所支付的与此服务有关的电话费用、网络费用等；</h3>
              <h3>3. 选择与所安装终端设备相匹配的软件版本，包括但不限于IOS、Android、Windows等多个爱回收发布的应用版本。</h3>
              <h3>（二）关于移动客户端软件的获取与更新：</h3>
              <h3>1. 您可以直接从爱回收网站上获取爱回收移动客户端软件，也可以从爱回收授权的第三方获取，如果您从未经爱回收授权的第三方获取软件或与爱回收移动客户端软件名称相同的安装程序，爱回收无法保证该软件能够正常使用，并对因此给您造成的损失不予负责；</h3>
              <h3>2. 为了改善用户体验、完善服务内容，爱回收将不断努力开发新的服务，并为您不时提供软件更新，新版本发布后，旧版本的软件可能无法使用，爱回收不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。</h3>
            </dl>
            <dl>
              <h2>三、账户注册、管理及使用</h2>
              <h2>（一）用户资格</h2>
              <h3 className={classes.indent}>
                您确认，在您开始注册程序使用爱回收平台服务前，
                <text className={classes.bold}>您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，需要在监护人的监护参与下才能注册并使用本网站，您及您的监护人应依照法律规定承担因此而导致的一切后果。</text>
              </h3>
              <h3 className={classes.bold}>此外，您还需确保您不是任何国家、地区或国际组织实施的贸易限制、经济制裁或其他法律法规限制的对象，也未直接或间接为前述对象提供资金、商品或服务，否则您应当停止使用爱回收平台服务，同时，您理解违反前述要求可能会造成您无法正常注册及使用爱回收平台服务。</h3>
              <h2>（二）账户注册</h2>
              <h3>
                1. 您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得爱回收平台账户并成为爱回收平台用户。
                <text className={classes.bold}>您设置的爱回收账号名/昵称/头像（如有）等不得违反国家法律法规及爱回收平台相关规则关于账号注册的管理规定，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等或采取其他足以让人引起混淆的方式）开设账号，不得恶意注册爱回收账号。否则爱回收有权拒绝设置或收回您的账号名/昵称。账号名的回收不影响您以手机号码登录爱回收平台并使用爱回收平台服务。</text>
              </h3>
              <h3 className={classes.bold}>
                2. 爱回收平台原则上只允许每位用户使用一个爱回收平台账户。如有证据证明或爱回收判断您存在不当注册或不当使用多个爱回收平台账户的情形，爱回收平台可采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给爱回收平台及相关方造成损失的，您还应承担赔偿责任。
              </h3>
              <h3>
                3. 在使用爱回收平台服务时，您应当按爱回收平台页面的提示准确完整地提供您的信息（包括您的姓名及电子邮件地址、联系电话、联系地址等），以便爱回收或其他服务方与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性。
              </h3>
              <h2>（三）账户登录</h2>
              <h3 className={classes.indent}>您有权使用您设置或确认的爱回收用户名、手机号码（以下简称“账户名称”）及您设置的密码（账户名称及密码合称“账户”）或通过授权使用您合法拥有的第三方软件或平台用户账号（如微信账号、QQ账号等）登录爱回收平台，但第三方软件或平台对此有限制或禁止的除外。当您以前述已有账号登录使用的，同样适用本协议中的相关条款。</h3>
              <h3 className={classes.bold}>您理解并同意，除您登录爱回收平台以外，您还可以使用爱回收账号登录爱回收及其关联方或其他合作方提供的其他软件、服务。您以爱回收账号登录前述服务的，同样应受其他软件、服务实际提供方的用户协议及其他协议条款约束。</h3>
              <h2>（四）实名认证</h2>
              <h3 className='bold indent'>作为爱回收平台经营者，为使您更好地使用爱回收平台的各项服务，保障您的账户安全，爱回收可要求您按照相关法律法规规定完成实名认证。</h3>
              <h2>（五）更新维护</h2>
              <h3 className={classes.indent}>您应当及时更新您提供的信息，在法律有明确规定要求爱回收作为平台服务提供者必须对部分用户的信息进行核实的情况下，爱回收将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。</h3>
              <h3 className={classes.bold}> 如爱回收按您最后一次提供的信息与您联系未果、您未按爱回收的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及爱回收造成的全部损失与不利后果。爱回收可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部爱回收平台服务，爱回收对此不承担责任。</h3>
              <h2>（六）账户管理</h2>
              <h3>
                1. 由于您的爱回收平台账户关联您的个人信息及爱回收平台商业信息，您的爱回收平台账户仅限您本人使用，
                <text className={classes.bold}>您应对您账户下的所有行为结果负责，不得以任何方式转让，否则爱回收平台有权追究您的违约责任，且由此产生的责任及后果均由您自行承担。您直接或间接授权第三方使用您爱回收平台账户或获取您账户项下信息的行为后果亦由您自行承担。如爱回收根据爱回收判断您爱回收平台账户的使用可能危及您的账户安全及/或爱回收平台信息安全的，爱回收平台可拒绝提供相应服务或终止本协议。</text>
              </h3>
              <h3>
                2. 您的账户为您自行设置并由您保管，爱回收任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开爱回收平台。
              </h3>
              <h3>
                3.
                <text className={classes.bold}>账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，爱回收并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</text>
                如发现任何未经授权使用您账户登录爱回收平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知爱回收，
                <text className={classes.bold}>您理解爱回收对您的任何请求采取行动均需要合理时间，且爱回收应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除爱回收存在法定过错外，爱回收不承担责任。</text>
              </h3>
              <h2>（七）账号注销</h2>
              <h3 className={classes.indent}>如您需要终止使用爱回收账号服务，符合以下条件的，您可以申请注销您的账号：（1）您仅能申请注销您本人的账号，并依照《爱回收账号注销须知》进行注销；（2）您仍应对您在注销账号前使用爱回收账号服务期间的行为承担责任。特别提示您，您申请同意注销账户的，视为您放弃账户信息以及该账户在平台的资产、虚拟权益（如有）等，且爱回收无法为您恢复前述服务。这可能对您主张售后服务带来不便。</h3>
              <h2>四、平台服务</h2>
              <h3 className={classes.bold}>（一）二手商品的回收服务：</h3>
              {/* <br/> */}
              <h3 className={classes.indent}>
                爱回收平台上的
                <text className={classes.bold}>回收方（指爱回收或第三方商家，第三方商家的信息具体以爱回收平台展示的商家信息为准）</text>
                为您提供二手或闲置物品（包括但不限于手机、笔记本、奢侈品、黄金等，具体以爱回收平台实时展示的内容为准）的估价、回收及其他相关服务。
              </h3>
              <h3>
                您知悉并同意，通过爱回收平台出售二手商品的，应遵循《产品质量法》、《消费者权益保护法》、《旧电器电子产品流通管理办法》、《旧货流通管理办法》等法律法规的规定；并就基于二手商品售卖交易行为与回收方或/及爱回收平台之间发生的任何争议、纠纷、索赔、处罚独立承担违法全部法律责任（包括但不限于行政、民事责任等）。
              </h3>
              <h3>
                1.
                <text className={classes.bold}>二手商品：</text>
              </h3>
              <h3 className={classes.bold}>（1）您应保证您的商品具备如下条件：</h3>
              <h3 className={classes.bold}>
                a. 商品来源渠道合法正规，并非以诈骗、偷盗、抢夺、侵占等非法方式获得；
              </h3>
              <h3 className={classes.bold}>
                b. 您对商品拥有合法、完整的处分权利；
              </h3>
              <h3 className={classes.bold}>
                c. 您应承诺并保证所出售的二手商品不存在任何危及人身、财产安全的危险并不侵犯任何第三方（含商品品牌方）的合法权益，包括但不限于人身权、财产权、知识产权、隐私等；
              </h3>
              <h3 className={classes.bold}>
                d. 您应确保您的商品不含有任何非原厂零配件（不包括耳机、充电器等外接设备）
              </h3>
              <h3 className={classes.bold}>
                e. 您的商品应满足法律法规对二手商品售卖的其他要求；
              </h3>
              <h3 className={classes.bold}>
                f. 商品短期内在爱回收无交易记录。
              </h3>
              <br />
              <h3 className={classes.bold}>
                如回收方发现您提交的物品不符合上述要求的，有权拒绝交易，并向您主张所有的损失。如商品为非法渠道物品的，回收方有权依法向公安司法机关报案，将物品转交公安司法机关，并向您主张所有的损失。回收方先行垫付的行为不代表对您赔偿责任的豁免，也不能视为与您共同承担法律责任的默示。
              </h3>
              <h3 className={classes.bold}>（2）交易前准备：</h3>
              <h3>  为保障您的隐私安全，在您进行二手商品出售前，爱回收提醒您：</h3>
              <h3>  a. 您需及时解除并删除商品的开机密码、访问限制密码、ID账号等所有账号和密码；</h3>
              <h3>  b. 您需去除商品附带的存储卡，SIM卡等一切信息存储卡；</h3>
              <h3>  c. 您需备份并删除商品内的所有资料（包括但不限于通讯录、信息、支付工具的账号及密码、应用程序的账号、用户名及密码、聊天记录、照片、视频、音频等）；</h3>
              <h3>  d. 您需对商品恢复出厂设置。</h3>
              <h3 className={classNames(classes.underLine, classes.bold)}>  您承诺交付给回收方的商品已经完成前述步骤且不含您的任何个人信息，若您将仍保存有您个人信息的商品交给回收方，则视为您放弃了该信息，回收方有权但无义务清除该二手商品中的信息。</h3>
              <h3 className={classes.bold}>尽管回收方会出于维护用户权益等目的尝试进行二次信息清除，但并不代表回收方对前述您应尽的信息清除义务承担任何法定义务及责任（例：回收方的二次信息清除并不保证必然达成彻底清除的后果，亦并不会因此免除您交付或寄出前的自行清除义务，您是承担二手商品信息清除的唯一义务方）。 </h3>
              <h3 className={classes.bold}>（3）配件：</h3>
              <h3>
                二手商品配件是否需要随主商品一起回收，请以您选择的商品页面中的提示为准。如页面中提示设备配件需一并回收的，您需将配件与二手商品一起交付给回收方，否则回收方有权拒绝提供服务并要求您自行承担相关费用。若无需寄送但您仍然自行交付给回收方的配件、二手商品附着物或装饰物等物品（例：饰物、贴膜、保护壳、SIM卡、手机/平板充电器等），视为您放弃前述物品的所有权，回收方有权对其进行拆除、丢弃或销毁等操作。
                <text className={classes.bold}>无论您的回收订单回收成功与否，任何情形下回收方或/及爱回收平台均不对前述物品或行为承担任何责任，由此给您带来的损失将由您自行承担。 </text>
              </h3>
              <h3>
                2.
                <text className={classes.bold}>回收价格：</text>
              </h3>
              <h3 className={classes.bold}>二手商品的回收价格由您自行决定，但如您坚持按照明显输入错误或不符合常识的价格出售商品，回收方有权取消或拒绝交易。</h3>
              <br />
              <h3>您应知悉并同意：您在爱回收平台自主填写二手商品现状及基本信息所获得的该二手商品预估报价仅为参考价格，该预估报价会因市场行情变化、该二手商品质检结果与您填写内容有差异、回收方的回收意向等可能出现的客观因素导致您最终获得的成交报价与预估价格存在不一致的情形。</h3>
              <br />
              <h3>
                <text className={classes.bold}>如您在规定的时间内将出售的二手商品交付给回收方，且经回收方质检后，回收方的报价等于或高于预估价格，则交易自动成立，</text>
                回收方应将回收款支付至您指定的账户中。
              </h3>
              <br />
              <h3>
                如您未在规定的时间内将出售的二手商品交付给回收方，或经回收方质检后认为您自主填写二手商品现状及基本信息与商品的实际情况不一致，且回收方的报价低于预估价格，则回收方需就新的报价和您进行沟通。如您不接受回收方的二次报价，您有权要求回收方将商品退还给您，退回的邮费由回收方或/和爱回收承担，
                <text className={classes.bold}>但对于仿制物品、高仿物品、更换过序列号的机器、丢失模式、含非原厂配件的物品（不包括耳机、充电器等外接设备）、所有无法开机/无法进入桌面/自动关机重启/有开机密码或iCloud等账户密码无法退出的机器、短期内在爱回收有交易记录的物品等属于爱回收平台规定的不能交易范围的商品，退回的邮费由您自行承担。</text>
              </h3>
              <h3>
                3.
                <text className={classes.bold}>商品的交付</text>
              </h3>
              <h3>您提交订单后请及时按照订单要求将您出售的二手商品直接交付给或邮寄至回收方，如由于您未及时交付商品导致订单超期取消的，回收方或/和爱回收不承担任何责任。</h3>
              <h3>在快递回收的交易场景下，若您的商品在回收方签收前，因第三方快递公司的原因导致商品损坏、丢失或者其它任何的利益损害，回收方或/和爱回收不承担任何责任和损失，您可直接向第三方快递公司索赔。</h3>
              <br />
              <h3>
                3.
                <text className={classes.bold}>检测：</text>
              </h3>
              <h3>
                回收方在履行本协议的过程中，在对二手商品进行检测、寄存保管或向您交付过程中，应当保证二手商品的状态维持在接收时的状态，且无遗失、质量损毁情形（质检必然损耗及自然损耗除外）。如因回收方的操作、管理不当等过错行为，导致二手商品损毁，回收方应负责按照实际损失予以赔偿，但回收方支付的实际损失赔偿金额最高不超过回收方与您确定的二手商品回收报价，若无回收报价则最高不超过寄出前的预估价格。
              </h3>
              <h3 className={classNames(classes.underLine, classes.bold)}>
                您应知悉并同意：为获取二手商品准确、全面的情况，回收方可能会对部分二手商品进行拆机检测，黄金首饰类二手商品通过破熔等方式检测，因前述检测方式造成二手商品产生的自然损耗及必然损害后果应由您自行承担。
              </h3>
              <h3 className={classes.bold}>
                您应知悉并同意：您提供给回收方回收的所有商品均默认为二手商品，全新未拆封机器将会被拆封或/及激活等操作，由此给您造成的损失应由您自行承担。
              </h3>
              <h3 className={classes.bold}>5. 交易成功后：</h3>
              <h3 className={classes.bold}>您知悉并同意：回收交易一经成交，除本协议另有约定外，您不得因任何原因、通过任何方式要求停止交易或者拒绝交易。因您拒绝交易产生的全部风险及法律责任均由您独立承担，且回收方或/及爱回收平台有权依据平台规则对您进行处罚并追索因此给回收方或/及平台造成的全部损失。</h3>
              <h3>若回收方因二手商品质量问题（包括但不限于商品来源非法等）要求退货，由您负责自行处理，尽管爱回收出于商誉考虑可能会向回收方提供沟通的辅助渠道（如披露您的联系方式等），但不意味着爱回收代替您向回收方承担任何责任。您处理相关问题时不得以任何方式损害爱回收平台的商誉及利益，否则爱回收平台有权要求您承担全部法律责任。 </h3>
              <h3 className={classes.bold}>6. 其他</h3>
              <h3>
                对于无人认领且回收方通过物流单据和订单信息无法联系到用户的商品（下称“无主物”），
                <text className={classes.bold}>自回收方签收该无主物之日起，将免费保管30日。超过30日仍然无人认领的，回收方将按照人民币伍元每日向您计收保管费直至无主物被转售或环保处理，或被您认领。回收方保管无主物期间，非因回收方的原因造成的无主物价值减损或灭失的，由您自行承担不利后果。</text>
              </h3>
              <h3 className={classes.bold}>（二）商品/服务销售：</h3>
              <h3>
                1. 当您在爱回收平台购买商品及/或服务时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为和意思表示视为您的行为和意思表示，
                <text className={classes.bold}>您应对收货人的行为及意思表示的法律后果承担连带责任。</text>
              </h3>
              <h3 className={classes.bold}>
                2. 您的购买行为应当符合法律规定，为生活消费需要购买、使用商品或者接受服务，不得存在对商品及/或服务实施恶意购买、恶意维权等扰乱爱回收平台正常交易秩序的行为。基于维护爱回收平台交易秩序及交易安全的需要，爱回收发现上述情形时可主动执行包括但不限于暂停或停止服务、取消订单等爱回收认为有必要的管控措施及作出账户处置等操作。
              </h3>
              <h3>
                3. 尽管销售商
                <text className={classes.bold}>（指爱回收或第三方商家，第三方商家的信息具体以爱回收平台展示的商家信息为准）</text>
                做出最大的努力，但由于市场变化及各种以合理商业努力难以控制因素的影响，本网站无法避免您提交的订单信息中的商品出现缺货、价格标示错误等情况；如您下单所购买的商品出现以上情况，您有权取消订单，销售商亦有权自行取消订单，若您已经付款，则为您办理退款。
              </h3>
              <h3 className={classes.bold}>（三）配送和交付</h3>
              <h3 className={classes.bold}>
                您在本网站购买的商品将按照本网站上您所指定的送货地址进行配送或由您自行至您所选定的提货地址自提。订单信息中列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。您应当清楚准确地填写您的送货地址、联系人及联系方式等配送信息，您知悉并确认，您所购买的商品应仅由您填写的联系人接受身份查验后接收商品，因您变更联系人或相关配送信息而造成的损失由您自行承担。
              </h3>
              <h3 className={classes.bold}>
                因如下情况造成订单延迟或无法配送等，本网站将无法承担迟延配送或无法配送的责任：
              </h3>
              <br />
              <h3>1. 客户提供错误信息和不详细的地址；</h3>
              <h3>2. 货物送达无人签收或拒收，由此造成的重复配送所产生的费用及相关的后果。</h3>
              <h3>3. 不可抗力，例如：自然灾害及恶劣天气、交通戒严等政府、司法机关的行为、决定或命令、意外交通事故、罢工、法规政策的修改、恐怖事件、抢劫、抢夺等暴力犯罪、突发战争等。</h3>
              <br />
              <h3 className={classes.bold}>您在本网站购买的商品由爱回收的关联方或第三方配送公司（包括顺丰、圆通等，以下称“配送公司”）为您完成订单交付的，系统或单据记录的签收时间为交付时间；您购买服务的，生成的电子或者实物凭证中载明的时间为交付时间。</h3>
              <h3>（四）平台活动</h3>
              <br />
              <h3>
                平台会不定期开展各类活动，参与活动前，您应当进入相应活动页面仔细阅读、充分理解活动规则，您参与活动的，视为您已充分阅读、理解该活动规则，并自愿受活动规则的约束。阅读活动规则的过程中，如果您不理解或不同意其中的任何内容，请停止参与相关活动。
                <text className={classes.bold}>您理解并同意，如您的订单或您参与的活动违反限制性要求的，则爱回收有权采取取消订单、取消活动资格，撤销活动奖励等一项或多项处理措施而无须承担任何责任。</text>
              </h3>
              <br />
              <h3>（五）交易争议处理</h3>
              <h3>您在爱回收平台交易过程中与其他用户发生争议的，您或其他用户中任何一方均有权选择以下途径解决：</h3>
              <h3>1. 与争议相对方自主协商；</h3>
              <h3>2. 使用爱回收平台提供的客户服务；</h3>
              <h3>3. 请求消费者协会或者其他依法成立的调解组织调解；</h3>
              <h3>4. 向有关行政部门投诉；</h3>
              <h3>5. 根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；</h3>
              <h3>6. 向人民法院提起诉讼。</h3>
              <br />
              <h3 className={classes.bold}>您理解并同意，爱回收平台的客服并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，除存在故意或重大过失外，爱回收不对争议解决结果承担责任。如您对争议解决结果不满意，您仍有权采取其他争议处理途径解决争议。</h3>
              <br />
              <h3 className={classes.bold}>（六）信息交流及分享</h3>
              <br />
              <h3>为满足用户间分享购物心得等信息交流需求，您可通过爱回收提供的问答、晒单分享、群组及其他信息发布功能进行评论、回复、留言、点赞等公开发布信息及与其他用户互动，您须确保您所发布的所有信息均符合相关法律法规及本协议的要求。</h3>
              <br />
              <br />
              <h3 className={classes.bold}>五、用户个人信息保护及授权</h3>
              <br />
              <h3 className={classes.bold}>
                <text>
                  （一）您知悉并同意，为方便您使用本网站相关服务，本网站将存储您在使用时的必要信息。除法律法规规定的情形外，未经您的许可爱回收不会向第三方公开、透露您的个人信息。爱回收对相关信息采取专业加密存储与传输方式，利用合理措施保障用户个人信息的安全。爱回收希望通过隐私政策向您清楚地介绍爱回收对您个人信息的处理方式，因此爱回收建议您完整地阅读隐私政策
                  <a href='https://www.aihuishou.com/privacy/index'>（https://www.aihuishou.com/privacy/index）</a>
                  ，以帮助您更好地保护您的隐私权。
                </text>
              </h3>
              <br />
              <h3 className={classes.bold}>（二）您充分理解并同意：</h3>
              <br />
              <h3>
                1. 您理解并同意，通过邮件、短信、电话、消息等平台渠道形式接收相关订单信息、促销活动、商品推荐等内容
                <text className={classes.bold}>您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的商品广告信息、促销优惠等商业性信息、推广或信息（包括商业或非商业信息）；您如果不愿意接收此类信息，您有权通过爱回收提供的相应的退订功能进行退订。</text>
              </h3>
              <br />
              <h3>
                2. 为配合行政监管机关、司法机关执行工作，在法律规定范围内爱回收有权向上述行政、司法机关提供您在使用本网站时所储存的相关信息，包括但不限于您的注册信息等，或使用相关信息进行证据保全，包括但不限于公证、见证等；
              </h3>
              <h3>
                3. 爱回收依法保障您在安装或使用过程中的知情权和选择权，在您使用本网站服务过程中，涉及您设备自带功能的服务会提前征得您同意，您一经确认，爱回收有权开启包括但不限于收集地理位置、读取通讯录、使用摄像头、启用录音等提供服务必要的辅助功能。
              </h3>
              <br />
              <h3>
                4. 爱回收有权根据实际情况，在法律规定范围内自行决定单个用户在本网站及服务中数据的最长储存期限以及用户日志的储存期限，并在服务器上为其分配数据最大存储空间等。
              </h3>
              <br />
              <br />
              <h3 className={classes.bold}>六、用户行为规范</h3>
              <br />
              <h3 className={classes.bold}>（一）您同意严格遵守法律法规规章规定，依法遵守以下义务：</h3>
              <br />
              <h3>
                <text className={classes.bold}>1.不得制作、传输或发表以下违法信息资料：</text>
                反对宪法所确定的基本原则，煽动抗拒、破坏宪法和法律法规实施的；危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的，煽动推翻社会主义制度的；损害国家荣誉和利益的；歪曲、丑化、亵渎、否定英雄烈士事迹和精神，侵害英雄烈士的姓名、肖像、名誉、荣誉的；宣扬或煽动实施恐怖主义、极端主义及其活动的；煽动民族仇恨、民族歧视、破坏民族团结的言论；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱经济秩序和社会秩序的；散布淫秽、色情、暴力或者教唆犯罪的；侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；法律、行政法规禁止的其他内容。
              </h3>
              <br />
              <h3>
                <text className={classes.bold}>2.防范和抵制制作、复制、发布含有下列内容的不良信息资料：</text>
                标题严重夸张，发表内容与标题严重不符的；不当评述自然灾害、重大事故等灾难的；煽动人群歧视、地域歧视等的；宣扬低俗、庸俗、媚俗内容的；违反社会公德行为的；侵犯未成年人合法权益的；其他对网络生态造成不良影响的内容。
              </h3>
              <br />
              <h3 className={classes.bold}>（二）本协议依据国家相关法律法规规章制定，您亦同意严格遵守以下义务：</h3>
              <br />
              <h3>
                1. 从中国大陆向境外传输资料信息时必须符合中国有关法规；
              </h3>
              <br />
              <h3>
                2. 不得利用本网站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；
              </h3>
              <br />
              <h3>
                3. 不得企图干扰、破坏爱回收系统或网站的正常运转，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
              </h3>
              <br />
              <h3>
                4. 不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的、不文明的等信息资料；
              </h3>
              <br />
              <h3>
                5. 不得教唆他人从事违法违规或本协议、平台规则所禁止的行为；
              </h3>
              <br />
              <h3>
                6. 不得利用在本网站注册的账户进行不当利益获取、恶意攻击、违法经营活动、传播不良内容等违反法律法规的行为；
              </h3>
              <br />
              <h3>
                7. 不得发布任何侵犯他人个人信息、著作权、商标权等知识产权或合法权利的内容；
              </h3>
              <br />
              <h3>
                8. 不得冒充他人或利用他人的名义使用爱回收软件服务或传播任何信息，恶意使用注册账号导致其他用户误认的；
              </h3>
              <br />
              <h3>
                9. 不得存在可能破坏、篡改、删除、影响爱回收平台任何系统正常运行或未经授权秘密获取爱回收平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
              </h3>
              <br />
              <h3>
                10. 不得恶意注册爱回收账号，包括但不限于频繁、批量注册账号、注销账号；
              </h3>
              <br />
              <h3>
                11. 不得发布其他对网络生态造成不良影响的内容，及法律、行政法规禁止的其他内容。
              </h3>
              <br />
              <h3 className={classes.bold}>
                （三）您须对自己在网上的言论和行为承担法律责任，您若在本网站上散布和传播反动、色情或其它违反国家法律的信息，本网站的系统记录有可能作为您违反法律的证据。爱回收可对您发布的信息依法或依本协议进行删除或屏蔽。
              </h3>
              <br />
              <h3 className={classes.bold}>
                （四）除非法律允许或爱回收书面许可，您使用本网站过程中不得从事下列行为：
              </h3>
              <br />
              <h3>
                1. 删除本网站及其副本上关于著作权的信息；
              </h3>
              <br />
              <h3>
                2. 对本网站进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本网站的源代码；
              </h3>
              <br />
              <h3>
                3. 对爱回收拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
              </h3>
              <br />
              <h3>
                4. 对本网站或者本网站运行过程中释放到任何终端内存中的数据、网站运行过程中客户端与服务器端的交互数据，以及本网站运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经爱回收授权的第三方工具/服务接入本网站和相关系统；
              </h3>
              <br />
              <h3>
                5. 通过修改或伪造网站运行中的指令、数据，增加、删减、变动网站的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
              </h3>
              <br />
              <h3>
                6. 通过非爱回收开发、授权的第三方软件、插件、外挂、系统，登录或使用本网站及服务，或制作、发布、传播上述工具；
              </h3>
              <br />
              <h3>
                7. 自行或者授权他人、第三方软件对本网站及其组件、模块、数据进行干扰。
              </h3>
              <br />
              <br />
              <h3 className={classes.bold}>七、违约责任</h3>
              <br />
              <h3 className={classes.bold}>（一）发生如下情形之一的，视为您违约：</h3>
              <br />
              <h3>1. 使用爱回收平台服务时违反有关法律法规规定的；</h3>
              <br />
              <h3>2. 违反本协议或本协议补充协议约定的。</h3>
              <br />
              <h3>
                3. 为适应电子商务发展和满足海量用户对高效优质服务的需求，您理解并同意，爱回收
                <text className={classes.bold}>可在爱回收平台规则中约定违约认定的程序和标准。如：爱回收可依据您的登录信息、用户数据与海量用户数据的关系及普通人的正常消费、交易习惯、快递物流行业习惯、生活常识等因素来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。</text>
              </h3>
              <h3 className={classes.bold}>（二）违约处理措施</h3>
              <br />
              <h3>如果爱回收发现或收到他人举报投诉您违反本协议约定或存在任何恶意行为的，爱回收有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规账号处以包括但不限于警告、限制或禁止使用部分或全部功能。</h3>
              <br />
              <h3>
                您在爱回收平台上实施的行为，或虽未在爱回收平台上实施但对爱回收平台及其用户产生影响的行为构成违约的，爱回收
                <text className={classes.bold}>可依据相应规则对您中止向您提供部分或全部服务、划扣违约金等处理措施。如您的行为构成根本违约的，爱回收可关闭、注销您的账户，终止向您提供服务。</text>
              </h3>
              <br />
              <h3 className={classes.bold}>
                爱回收有权对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在爱回收平台上予以公示。
              </h3>
              <br />
              <h3 className={classes.bold}>
                （三）赔偿责任
              </h3>
              <br />
              <h3>
                如您的行为使爱回收及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿爱回收及/或其关联公司的上述全部损失。如您的行为使爱回收及/或其关联公司遭受第三人主张权利，爱回收及/或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。
              </h3>
              <br />
              <br />
              <h3 className={classes.bold}>八、协议终止</h3>
              <br />
              <h3 className={classes.bold}>（一）终止的情形</h3>
              <h3>您有权通过以下任一方式终止本协议：</h3>
              <h3>
                <text>1. 在满足爱回收平台公示的</text>
                <a href='https://page.aihuishou.com/amr/?activityId=MR3V6GAK&agent=ahs_m'>账户注销条件时</a>
                <text>您通过网站告知的方式注销您的账户；</text>
              </h3>
              <br />
              <h3>
                2. 变更事项生效前您停止使用并明示不愿接受变更事项的；
              </h3>
              <br />
              <h3>
                3. 您明示不愿继续使用爱回收平台服务，且符合爱回收平台终止条件的。
                出现以下情况时，爱回收可以本协议约定通知您终止本协议
              </h3>
              <br />
              <h3>
                1. 您违反本协议约定，爱回收依据违约条款终止本协议的；
              </h3>
              <br />
              <h3>
                2. 您盗用他人账户、发布违禁信息、骗取他人财物、扰乱市场秩序、采取不正当手段谋利等行为，爱回收依据爱回收平台规则对您的账户予以关闭的；
              </h3>
              <br />
              <h3>
                3. 除上述情形外，因您多次违反爱回收平台规则相关规定且情节严重，爱回收依据爱回收平台规则对您的账户予以关闭的；
              </h3>
              <br />
              <h3>4. 其它应当终止服务的情况。</h3>
              <br />
              <h3>（二）协议终止后的处理</h3>
              <br />
              <h3>本协议终止后，爱回收仍享有下列权利：</h3>
              <br />
              <h3>1. 依照法律规定保留平台应当留存的相关信息；</h3>
              <br />
              <h3>2. 对于您过往的违约行为，爱回收仍可依据本协议向您追究违约责任。</h3>
              <br />
              <h3 className={classes.bold}>3. 本协议终止后，对于您在本协议存续期间产生的交易订单，爱回收可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</h3>
              <br />
              <br />
              <h3 className={classes.bold}>九、所有权及知识产权</h3>
              <br />
              <h3 className={classes.bold}>
                （一）您一旦接受本协议，对于您提供、上传、创作、发布在本网站的文字、图片、照片、视频、音频等任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等），您免费授予爱回收及其关联公司至保护期终止为止、不可撤销的、全球范围的、排他的许可使用著作权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可许可使用的权利）、肖像权、用户ID名称等相关的全部合法权利权益，就爱回收具有诉讼主体资格的权利、权益，爱回收有权以自身名义对第三方侵权行为取证及提起诉讼。您同意爱回收及其关联公司有权存储、使用、复制、修订、编辑、发布、展示、翻译、分发、推广、出版、发行、信息网络传播您以上信息内容，有权改编制作派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。
              </h3>
              <br />
              <h3 className={classes.bold}>（二）爱回收有权再许可给其他第三方第九条第一款的所有权利。</h3>
              <br />
              <h3 className={classes.bold}>（三）您应确保在爱回收网站上发表的第九条第一款各类信息内容均不涉及侵犯第三方肖像、隐私、知识产权或其他合法权益。否则爱回收有权随时采取包括但不限于删除、断开链接等措施。</h3>
              <br />
              <h3 className={classes.bold}>（四）本协议已经构成《中华人民共和国著作权法》及相关法律规定的著作财产权等权利许可使用的书面协议，其效力及于您在本网站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后</h3>
              <br />
              <h3 className={classes.bold}>（五）您同意并已充分了解本协议的条款，承诺不将已发表于本网站的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。</h3>
              <br />
              <h3 className={classes.bold}>（六）除法律另有强制性规定外，未经爱回收明确的特别书面同意,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本网站的信息内容，否则，爱回收有权追究其法律责任。</h3>
              <br />
              <br />
              <h3 className={classes.bold}>（七）本网站所刊登的资料信息（包括但不限于文字、图表、标识、按钮图标、图像、照片、声音视频文件及其片段、数字下载物、数据编辑和软件），均是爱回收或其内容提供者的财产，受中国著作权法和国际版权相关条约的保护。本网站上所有内容的汇编是爱回收的专有财产，受中国著作权法和国际版权相关条约的保护。本网站上所有软件都是爱回收或其关联公司或其软件供应商的财产，受中国著作权法和国际版权相关条约的保护。</h3>
              <br />
              <br />
              <h3 className={classes.bold}>十、通知送达</h3>
              <br />
              <h3>
                （一）有效地址。您在爱回收用户账户填写的联系电话、联系地址视为您的有效通知地址。若上述信息发生变更您应当及时进行更新，如果因您提供的联系方式不准确，或不及时告知变更后的联系方式，使相关通知（包括但不限于协议变更、法律文书等）无法送达或未及时送达，由您自行承担可能产生的法律后果。
              </h3>
              <br />
              <h3>
                （二）通知送达。爱回收通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮箱发送电子邮件，向您的爱回收用户账户发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
              </h3>
              <br />
              <h3>
                （三）法律文书的送达。对于与爱回收有关的任何纠纷，您同意行政、公安、司法等有权机关可以通过手机短信、电子邮件、系统消息或站内信等方式向您送达法律文书，有权机关向上述联系方式发出法律文书即视为送达；若同时采取以上多种方式向您送达法律文书，送达时间以最先送达的为准。您同意上述送达方式适用于行政、仲裁、司法等法定程序的全过程。
              </h3>
              <br />
              <br />
              <h3 className={classes.bold}>十一、不可抗力或其他免责事由</h3>
              <br />
              <h3>（一）您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务协议下的服务发生中断或终止。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于信息网络设备维护、信息网络连接故障、电脑、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、战争、政府行为、法律法规变动、司法行政机关的命令、其他不可抗力或第三方的不作为而造成的不能服务或延迟服务等行为。出现上述情况时，爱回收将努力在第一时间与相关部门配合，及时进行修复，但是由此给您造成的损失，爱回收在法律允许的范围内免责。</h3>
              <br />
              <h3>（二）您理解并同意，在法律允许的范围内，爱回收对以下事由所导致的服务中断或终止不承担责任：</h3>
              <br />
              <h3>1. 受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</h3>
              <br />
              <h3>2. 用户或爱回收的电脑软件、系统、硬件和通信线路出现故障；</h3>
              <br />
              <h3>3. 用户操作不当；</h3>
              <br />
              <h3>4. 用户通过非爱回收授权的方式使用本服务；</h3>
              <br />
              <h3>5. 其他爱回收无法控制或合理预见的情形。</h3>
              <br />
              <br />
              <h3 className={classes.bold}>十二、法律管辖适用及其他</h3>
              <br />
              <h3 className={classes.bold}>（一）本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，应向协议签订地有管辖权的人民法院提起诉讼。本协议签订地为中华人民共和国上海市杨浦区。</h3>
              <br />
              <h3 className={classes.bold}>（二）如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</h3>
              <br />
              <h3 className={classes.bold}>（三）本协议未明示授权的其他权利仍由爱回收保留，您在行使这些权利时须另外取得爱回收的书面许可。爱回收如果未行使前述任何权利，并不构成对该权利的放弃。</h3>
              <br />
              <br />
              <h3>附，本协议发布时平台上为您提供回收服务或销售商品给您的第三方商家信息列明：</h3>
            </dl>
            <table>
              <thead>
                <tr>
                  <th>业务类型</th>
                  <th>服务内容</th>
                  <th>商家信息</th>
                  <th>用户协议/服务说明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={9}>回收</td>
                  <td>腕表</td>
                  <td rowSpan={3}>
                    <a
                      href='https://page.aihuishou.com/amr/?activityId=5UU7DLCB&agent=ahs_m'
                      className='text-underline'
                    >
                      上海晋盼网络科技有限公司
                    </a>
                  </td>
                  <td rowSpan={3}>
                    <a href='https://page.aihuishou.com/amr/?activityId=95KG1BPL&agent=ahs_m'>查看</a>
                  </td>
                </tr>
                <tr>
                  <td>包装</td>
                </tr>
                <tr>
                  <td>奢配饰</td>
                </tr>
                <tr>
                  <td>金银珠宝-黄金</td>
                  <td>
                    <a
                      href='https://page.aihuishou.com/amr/?activityId=8GTTXK03&agent=ahs_m'
                      className='text-underline'
                    >
                      深圳市矢量金科技发展有限公司
                    </a>
                  </td>
                  <td>
                    <a href='https://page.aihuishou.com/amr/?activityId=BXQROAWS&agent=ahs_m'>查看</a>
                  </td>
                </tr>
                <tr>
                  <td>金银珠宝-钻石</td>
                  <td rowSpan={2}>
                    <a
                      href='https://page.aihuishou.com/amr/?activityId=IOHBYUXE&agent=ahs_m'
                      className='text-underline'
                    >
                      臻玺阁（上海）商贸有限公司
                    </a>
                  </td>
                  <td rowSpan={2}>
                    <a href='https://page.aihuishou.com/amr/?activityId=OW0UITMD&agent=ahs_m'>查看</a>
                  </td>
                </tr>
                <tr>
                  <td>名酒礼品</td>
                </tr>
                <tr>
                  <td>乐器</td>
                  <td>
                    <a
                      href='https://page.aihuishou.com/amr/?activityId=ZEMNNMQ1&agent=ahs_m'
                      className='text-underline'
                    >
                      广州乐人回收乐器有限公司
                    </a>
                  </td>
                  <td>
                    <a href='https://page.aihuishou.com/amr/?activityId=J6KGJWO0&agent=ahs_m'>查看</a>
                  </td>
                </tr>
                <tr>
                  <td>卡券</td>
                  <td>
                    <a
                      href='https://ahs.shoukala.com/mine/license'
                      className='text-underline'
                    >
                      苏州收卡啦信息技术有限公司
                    </a>
                  </td>
                  <td>
                    <a href='https://ahs.shoukala.com/mine/agreement/'>查看</a>
                  </td>
                </tr>
                <tr>
                  <td>生活杂物</td>
                  <td>
                    <a
                      href='https://page.aihuishou.com/amr/?activityId=VMXTUMKR&agent=ahs_m'
                      className='text-underline'
                    >
                      上海悦鲲环保科技有限公司
                    </a>
                  </td>
                  <td>
                    <a href='https://page.aihuishou.com/amr/?activityId=HNQR4RSK&agent=ahs_m'>查看</a>
                  </td>
                </tr>
                <tr>
                  <td>维修</td>
                  <td>维修业务</td>
                  <td>
                    <a
                      href='javascript:void(0)'
                      className='text-underline'
                    >
                      均由第三方提供，具体以实际执行方为准
                    </a>
                  </td>
                  <td>
                    <a href='https://page.aihuishou.com/amr/?activityId=BJROI2CZ&agent=ahs_m'>查看</a>
                  </td>
                </tr>
                <tr>
                  <td>邻里闲置</td>
                  <td>个人闲置交流</td>
                  <td>
                    <a
                      href='https://page.aihuishou.com/amr/?activityId=VMXTUMKR&agent=ahs_m'
                      className='text-underline'
                    >
                      上海悦鲲环保科技有限公司
                    </a>
                  </td>
                  <td>
                    <a href='https://page.aihuishou.com/amr/?activityId=RZ54COMO&agent=ahs_m'>查看</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
