import React from 'react';
import classes from './Index.module.scss';
import titleOne from './aboutus/title-one.png';
import titleTwo from './aboutus/title-two.png';
import partner from './aboutus/partner.png';
import timeline from './aboutus/timeline.png';

function AboutUs() {
  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <div className={classes.content}>
        <div className={classes.block}>
          <img className={classes.titleone} src={titleOne} alt='' />
          <img className={classes.timeline} src={timeline} alt='' />
        </div>
        <div className={classes.block}>
          <img className={classes.titletwo} src={titleTwo} alt='' />
          <img className={classes.partner} src={partner} alt='' />
        </div>
      </div>

    </div>
  );
}

export default AboutUs;
