import React, { useCallback, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { getValue as getScreenValue, ScreenProvider } from 'utils/screen';

import ScrollToTop from 'components/ScrollToTop/Index';
import AppHeader from 'components/AppHeader/Index';
import AppFooter from 'components/AppFooter/Index';
import Permissions from 'pages/Secret/Permissions';
import VendorPersonData from 'pages/Secret/VendorPersonData';
import LogOut from 'pages/Secret/LogOut';
import useWindowResize from './hooks/view';

import Home from './pages/Home/Index';
import AboutUs from './pages/AboutUs/Index';
import AhsServices from './pages/AhsServices/Index';
import Secret from './pages/Secret/Index';
import History from './pages/Secret/Privacies';
import NotFound from './pages/NotFound';

const PAD_BASE_WIDTH = 1360;
const PC_CONTAINER_WIDTH = 1200;
const MOBILE_CONTAINER_WIDTH = 768;
const viewportElement = document.getElementById('viewport') as HTMLMetaElement;

function App() {
  const [screenValue, setScreenValue] = useState(getScreenValue());

  const resetViewportScale = useCallback(() => {
    if (!screenValue) return;
    const { width } = screenValue;
    if (width > MOBILE_CONTAINER_WIDTH && width < PAD_BASE_WIDTH) {
      const scale = (width / PAD_BASE_WIDTH).toFixed(5);
      viewportElement!.content = `width=${PC_CONTAINER_WIDTH}, initial-scale=${scale},user-scalable=no`;
    } else {
      viewportElement!.content = 'width=device-width, initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no';
    }
    if (!window.fixedScrollPosition) {
      window.fixedScrollPosition = true;
      requestAnimationFrame(() => {
        window.scrollTo(0, 0);
      });
    }
  }, [ screenValue ]);
  resetViewportScale();

  useWindowResize(() => {
    const newScreenValue = getScreenValue();
    setScreenValue(newScreenValue);
    resetViewportScale();
  });
  useEffect(
    () => {
      window.dispatchEvent(new Event('resize'));
    },
    [],
  );

  return (
    <ScreenProvider value={screenValue}>
      <Router>
        <div>
          <AppHeader />
          <Switch>
            <Route path='/' exact component={Home} />
            <ScrollToTop>
              <Switch>
                <Route path='/about-us' exact component={AboutUs} />
                <Route path='/ahs-services' exact component={AhsServices} />
                <Route path='/privacy' exact>
                  <Redirect to='/privacy/index' />
                </Route>
                <Route path='/privacy/index' exact component={Secret} />
                <Route path='/privacy/history' exact component={History} />
                <Route path='/privacy/permissions' exact component={Permissions} />
                <Route path='/privacy/vendor-person-data' exact component={VendorPersonData} />
                <Route path='/privacy/log-out' exact component={LogOut} />
                <Route path='/privacy/:version/content' exact component={Secret} />
                <Route path='/not-found' exact component={NotFound} />
                <Redirect to='/' />
              </Switch>
            </ScrollToTop>
          </Switch>
          <AppFooter />
        </div>
      </Router>
    </ScreenProvider>
  );
}

export default App;
