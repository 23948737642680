import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import type { Location } from 'history';

interface Props {
  children: ReactNode;
  location: Location;
}
function ScrollToTop(props: Props) {
  useEffect(
    () => {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          // @ts-ignore
          behavior: 'instant',
        });
      }, 64);
    },

    [ props.location.pathname ],
  );
  return props.children;
}

// @ts-ignore
export default withRouter(ScrollToTop);
