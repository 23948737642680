import React, { useEffect, useState } from 'react';

import classes from './NotFound.module.scss';

function NotFound() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    ready
      ? (
        <div className={classes.NotFound}>
          页面不存在
        </div>
      )
      : null
  );
}

export default NotFound;
