import logoDark from '../assets/logo-dark.png';
import logoWhite from '../assets/logo-white.png';
import KeFu from '../assets/kefu-icon.png';
import code from '../assets/copyRight/code.png';
import zfb from '../assets/copyRight/zfb.png';
import shxz from '../assets/copyRight/shxz.png';
import police from '../assets/copyRight/police.png';
import cft from '../assets/copyRight/cft.png';

function getLocale() {
  return {
    code: 'zh-cn',
    logo: logoDark,
    whiteLogo: logoWhite,
    kefu: KeFu,
    label: '中文',
    title: '万物新生',
    footer: {
      groups: [{
        title: '帮助中心',
        links: [{
          text: '常见问题',
          link: '',
          bold: false,
          block: false,
          type: 'modal',
          scrollTop: false,
        }, {
          text: '用户协议',
          link: '/ahs-services',
          bold: false,
          block: false,
          type: 'link',
          scrollTop: false,
        }, {
          text: '隐私协议',
          link: '/privacy/index',
          bold: false,
          block: false,
          type: 'link',
          scrollTop: false,
        }],
      }, {
        title: '商务合作',
        links: [
          {
            text: '开放平台',
            link: 'https://neon.aihuishou.com/',
            bold: false,
            block: false,
            type: 'link',
            scrollTop: false,
          },
          {
            text: '企业回收',
            link: 'https://www.aihuishoutob.com/',
            bold: false,
            block: false,
            type: 'link',
            scrollTop: false,
          },
          {
            text: '企业租赁',
            link: 'https://www.aihuishoutob.com/coahs/lease',
            bold: false,
            block: false,
            type: 'link',
            scrollTop: false,
          },
          {
            text: '回收商合作',
            link: 'https://www.paijitang.com/',
            bold: false,
            block: false,
            type: 'link',
            scrollTop: false,
          },
        ],
      }, {
        title: '关于我们',
        links: [{
          text: '关于爱回收',
          link: '/about-us',
          bold: false,
          block: false,
          type: 'link',
          scrollTop: false,
        }, {
          text: '投资者关系',
          link: 'https://ir.atrenew.com/',
          bold: false,
          block: false,
          type: 'link',
          scrollTop: false,
        }, {
          text: '加入我们',
          link: 'https://activity.aihuishou.com/n/recruit',
          bold: false,
          block: false,
          type: 'link',
          scrollTop: false,
        }, {
          text: '商家入驻',
          link: 'https://page.aihuishou.com/amr/?activityId=MWXYQEXY&agent=ahs_m',
          bold: false,
          block: false,
          type: 'link',
          scrollTop: false,
        }],
      }, {
        links: [{
          text: '联系我们',
          block: true,
          bold: false,
          link: '',
          type: 'link',
          scrollTop: false,
        }, {
          text: '人工客服：9:00-21:00',
          block: true,
          bold: false,
          link: '',
          type: 'link',
          scrollTop: false,
        }, {
          text: '品牌合作：brand@atrenew.com',
          block: true,
          bold: false,
          link: '',
          type: 'link',
          scrollTop: false,
        }, {
          text: '在线客服：周一至周日 24小时在线',
          block: true,
          bold: false,
          link: '',
          type: 'link',
          scrollTop: false,
        }],
      }],
      copyRights: [ {
        text: 'Copyright 2010 - %YEAR% 上海万物新生环保科技集团有限公司',
        link: 'https://sr.aihuishou.com/c2b/zy-fe/public/pc-official/certificate.jpg',
      } ],
      copyRightsImage: [
        {
          type: 'code',
          image: code,
        },
        {
          type: 'zfb',
          image: zfb,
        },
        {
          type: 'shgs',
          image: shxz,
        },
        {
          type: 'police',
          image: police,
        },
        {
          type: 'cft',
          image: cft,
        },
      ],
      securityLinks: [{
        text: '沪ICP备10043802号-2',
        link: 'https://beian.miit.gov.cn/',
      }, {
        text: '沪公网安备 31011002002333 号',
        link: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002002333',
      }],
      securityLinksExtra: [
        {
          text: '违法不良信息举报电话：0519-88236605',
          link: undefined,
        }, {
          text: '上海市互联网举报中心',
          link: 'http://www.shjbzx.cn',
        }, {
          text: '|',
          link: '',
        }, {
          text: '网上有害信息举报专区',
          link: 'https://www.12377.cn',
        },
      ],
    },
    pages: {
      Business: { title: '商务合作' },
      News: { title: '投资者关系 Investor' },
      Investors: { title: '帮助中心' },
      ESG: { title: '加入我们' },
      AboutUs: { title: '关于我们' },
    },
  };
}

export default getLocale;
