import React from 'react';
import className from './Index.module.scss';
import topTitle from '../../assets/moreWays/title.png';
import onDoor from '../../assets/moreWays/onDoor.png';
import kuaidi from '../../assets/moreWays/kuaidi.png';
import huishouji from '../../assets/moreWays/huishouji.png';

function MoreWay() {
  return (
    <div className={className.background}>
      <div className={className.title}>
        <img className={className.titleImg} src={topTitle} alt='' />
        <div className={className.text}>全国1500+门店 覆盖200+城市</div>
      </div>
      <div className={className.block}>
        <div className={className.blockItem}>
          <img src={onDoor} alt='' className={className.blockImg} />
          <div className={className.desc}>免费上门</div>
        </div>
        <div className={className.blockMiddleItem}>
          <img src={kuaidi} alt='' className={className.blockImg} />
          <div className={className.desc}>快递回收</div>
        </div>
        <div className={className.blockItem}>
          <img src={huishouji} alt='' className={className.blockImg} />
          <div className={className.desc}>智能回收机</div>
        </div>
      </div>
    </div>
  );
}

export default MoreWay;
