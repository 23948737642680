import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { historyVersion } from './historyMap';
import classes from './Index.module.scss';

export default class Secret extends Component {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={classes.help}>
        <div className={classes.mainSection}>
          <div className={classes.privacyContent}>
            {
              historyVersion.map((item) => {
                return (
                  <div key={item.version}>
                    <Link to={`/privacy/${item.version}/content`}>{item.date}</Link>
                    <br />
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}
