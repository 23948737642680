import type { LocaleConfig as OriginLocaleConfig } from './context';
import LocaleProvider, { useLocale, useLocaler } from './context';
import zhCN from './zh-cn';

export type LocaleConfig = OriginLocaleConfig;

export function getLocales() {
  return [
    zhCN(),
  ];
}
export {
  LocaleProvider,
  useLocaler,
  useLocale,
};

export default LocaleProvider;
