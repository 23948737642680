/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useLocale } from 'locales';
import Main from 'components/Main/Index';
import Link from 'components/NavButton/Link';
import Modal from 'antd/es/modal/Modal';
import classNames from 'classnames';
import help from '../../assets/help.jpeg';
import closeIcon from '../../pages/Home/assets/icons/close-icon.png';

import classes from './Index.module.scss';

function Footer() {
  const locale = useLocale();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const copyRightsVisible = useMemo(
    () => Boolean(locale.footer.copyRights.length
      || locale.footer.securityLinks.length),
    [locale.footer.copyRights.length, locale.footer.securityLinks.length],
  );

  // 调用在线客服
  const onlineServer = () => {
    window?.ud('init');
    window?.ud('showPanel');
  };

  return (
    <div className={classes.box}>
      <Main>
        <div className={classes.friendLink}>
          <div className={classes.logo}>
            <NavLink to='/'>
              <img src={locale.logo} alt={locale.title} />
            </NavLink>
          </div>
          <div className={classes.groups}>
            {
              locale.footer.groups.map((group, Index) => (
                <div key={group.links[0].text} className={classes.group}>
                  <div>
                    {group.title && <div className={classes.groupTitle}>{group.title}</div>}
                  </div>
                  <div className={classes.groupLinks}>
                    {
                      group.links.map((link) => (
                        <Link
                          className={
                            classNames(classes.groupItem, {
                              [classes.groupTitle]: link.bold,
                              [classes.groupLink]: !link.bold,
                              [classes.block]: !link.bold && link.block,
                              [classes.paragraph]: link.text.length > 30,
                            })
                          }
                          key={link.text}
                          to={link.link}
                          onClick={
                            () => {
                              if (link.type === 'modal') {
                                setIsModalOpen(true);
                              }
                            }
                          }
                        >
                          {link.text}
                        </Link>
                      ))
                    }
                    {
                      Index === (locale.footer.groups.length - 1) ? (
                        <div id='kefu_question' className={classes.kefu} onClick={onlineServer}>
                          <img src={locale.kefu} alt={locale.title} />
                          客服助手
                        </div>
                      ) : null
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </Main>
      {
        copyRightsVisible
          ? (
            <div className={classes.copyRights}>
              <div className={classes.copyRightsImage}>
                {
                  locale.footer.copyRightsImage.map((item) => (
                    <div className={classes.imgItem} key={item.type}>
                      <img src={item.image} alt={item.type} />
                    </div>
                  ))
                }
              </div>
              <p className={classes.address}>宝山运营中心：上海市宝山区纪蕴路588弄2号楼1楼</p>
              <p>
                {
                  locale.footer.copyRights.map((link) => (
                    <Link
                      to={link.link}
                      className={classes.copyRightLink}
                      key={link.text}
                    >
                      {link.text.replace('%YEAR%', new Date().getFullYear().toString())}
                    </Link>
                  ))
                }
                {
                  locale.footer.securityLinks.map((link) => (
                    <Link
                      to={link.link!}
                      className={classes.copyRightLink}
                      key={link.text}
                    >
                      {link.text.replace('%YEAR%', new Date().getFullYear().toString())}
                    </Link>
                  ))
                }
              </p>
              <p>
                {
                  locale.footer.securityLinksExtra.map((link) => (
                    <Link
                      to={link.link!}
                      className={classes.copyRightLink}
                      key={link.text}
                    >
                      {link.text.replace('%YEAR%', new Date().getFullYear().toString())}
                    </Link>
                  ))
                }
              </p>
            </div>
          )
          : null
      }
      <Modal
        title={
          (
            <div
              style={
                {
                  textAlign: 'center',
                  color: '#373A36',
                  fontSize: '22px',
                  fontWeight: 600,
                  marginBottom: 0,
                  paddingBottom: '20px',
                  borderBottom: '1px solid #ECECEC',
                }
              }
            >
              扫码查看常见问题
            </div>
          )
        }
        open={isModalOpen}
        footer={[]}
        onCancel={() => { setIsModalOpen(false); }}
        width={435}
        centered
        bodyStyle={
          { height: 340 }
        }
        closeIcon={
          (
            <img
              src={closeIcon}
              style={
                {
                  height: '20px',
                  width: '20px',
                }
              }
              alt='关闭'
            />
          )
        }
        style={
          { textAlign: 'center' }
        }
      >
        <img src={help} alt='' className={classes.helpCode} />
      </Modal>
    </div>
  );
}

export default Footer;
