/* eslint-disable max-len */
import React from 'react';

import classes from './Index.module.scss';

function LogOut() {
  return (
    <div className={classes.help}>
      <div className={classes.mainSection}>
        <h1>《爱回收账户注销须知》</h1>
        <div className={classes.privacyContent}>
          <dl>
            <dd>您在申请注销流程中点击同意前，应当认真阅读《爱回收账户注销须知》（以下称“《注销须知》”）。</dd>
            <dt>
              <b>
                请您务必审慎阅读、充分理解协议中相关条款内容，其中包括：
                <br />
                1、与您约定免除或限制责任的条款；
                <br />
                2、其他以粗体下划线标识的重要条款。
              </b>
            </dt>
            <dd>
              如您对本《注销须知》有任何疑问，可通过爱回收的在线客服系统或拨打我们的客服电话与我们联系。
              <br />
              【特别提示】当您按照注销页面提示填写信息、阅读并同意本《注销须知》及相关条款与条件且完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销须知》的全部内容。阅读本《注销须知》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账户注销程序。
            </dd>
            <dt><b>亲爱的爱回收个人消费者用户：</b></dt>
            <dd>我们在此善意地提醒您，您注销账户的行为会给您的后续维权带来诸多不便，且注销爱回收账户后，您的个人信息我们只会在爱回收的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓并理解，您在使用爱回收服务期间提供或产生的信息，爱回收仍需按照监管要求的时间进行保存，且在该保存期间内依法配合有权机关的查询。</dd>
            <dt><b>1. 如果您仍执意注销账户，您的账户需同时满足以下条件：</b></dt>
            <dd>
              （1）在最近一个月内，账户没有进行更改密码、更改绑定信息等敏感操作，账户没有被盗、被封等风险；
              {' '}
              <br />
              （2）账户在爱回收中无资产、无欠款；如您的账户中存在未使用的资产或虚拟权益（包括但不限于优惠券、积分等）的，视为您自动放弃；
              {' '}
              <br />
              （3）账户内无未完成的订单、服务；
              {' '}
              <br />
              （4）账户无任何纠纷，包括投诉举报或被投诉举报；
              {' '}
              <br />
              （5）账户为正常使用中的账户且无任何账户被限制的记录；
              {' '}
              <br />
              （6）账户已经解除与其他网站、其他APP的授权登录或绑定关系。
            </dd>
            <dt><b>2. 爱回收账户一旦被注销将不可恢复，请您在操作之前自行备份爱回收账户相关的所有信息和数据。请您保存好订单的交易凭证等资料，否则您有可能须支付额外的账户和订单查询费用，或无法享受客服服务。</b></dt>
            <dd>3 .在爱回收账户注销期间，如果您的爱回收账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，爱回收有权自行终止本爱回收账户的注销而无需另行得到您的同意。</dd>
            <dt><b>4. 注销爱回收账户，您将无法再使用本爱回收账户，也将无法找回您爱回收账户中及与账户相关的任何内容或信息，包括但不限于：</b></dt>
            <dd>
              （1）您将无法登录、使用本爱回收账户；
              <br />
              （2）本爱回收账户的个人资料和历史信息（包括但不限于用户名、头像、交易记录等）都将无法找回；
            </dd>
            <dt><b>5. 注销本爱回收账户并不代表本爱回收账户注销前的账户行为和相关责任得到豁免或减轻。</b></dt>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default LogOut;
