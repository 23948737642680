import React from 'react';
import className from './Index.module.scss';
import category from '../../assets/categorys/category.png';
import phone from '../../assets/categorys/phone.png';
import ipad from '../../assets/categorys/ipad.png';
import mac from '../../assets/categorys/mac.png';
import camera from '../../assets/categorys/camera.png';
import smart from '../../assets/categorys/smart.png';
import iwatch from '../../assets/categorys/iwatch.png';
import bag from '../../assets/categorys/bag.png';
import jewelry from '../../assets/categorys/jewelry.png';
import alcohol from '../../assets/categorys/alcohol.png';
import gold from '../../assets/categorys/gold.png';

function Category() {
  return (
    <div className={className.container}>
      <div className={className.titleContainer}>
        <img src={category} alt='' className={className.titleImg} />
      </div>
      <div className={className.itemContainer}>
        <div className={className.item}>
          <img src={phone} alt='' />
          <div className={className.desc}>手机</div>
        </div>
        <div className={className.item}>
          <img src={ipad} alt='' />
          <div className={className.desc}>平板</div>
        </div>
        <div className={className.item}>
          <img src={mac} alt='' />
          <div className={className.desc}>笔记本</div>
        </div>
        <div className={className.item}>
          <img src={camera} alt='' />
          <div className={className.desc}>摄影摄像</div>
        </div>
        <div className={className.item}>
          <img src={smart} alt='' />
          <div className={className.desc}>智能数码</div>
        </div>
      </div>
      <div className={className.itemContainer}>
        <div className={className.item}>
          <img src={iwatch} alt='' />
          <div className={className.desc}>腕表</div>
        </div>
        <div className={className.item}>
          <img src={bag} alt='' />
          <div className={className.desc}>箱包</div>
        </div>
        <div className={className.item}>
          <img src={jewelry} alt='' />
          <div className={className.desc}>饰品</div>
        </div>
        <div className={className.item}>
          <img src={alcohol} alt='' />
          <div className={className.desc}>名酒</div>
        </div>
        <div className={className.item}>
          <img src={gold} alt='' />
          <div className={className.desc}>黄金</div>
        </div>
      </div>
    </div>
  );
}

export default Category;
