import { isSmall } from './view';

/**
 * OSS资源图片格式化
 * 相关文档： https://help.aliyun.com/document_detail/44688.html?spm=a2c4g.11186623.6.1147.7fd4573e4GXxfr
 * @param imgUrl 图片地址
 * @param options 选项
 * @param isFormatJPG 是不是强制转成 jpg
 */
export const ossIMGResize = (
  imgUrl: string,
  options: Partial<{
    /**
     * lfit (默认)：等比缩放，限制在指定w与h的矩形内的最大图片。
     * mfit：等比缩放，延伸出指定w与h的矩形框外的最小图片。
     * fill：固定宽高，将延伸出指定w与h的矩形框外的最小图片进行居中裁剪。
     * pad：固定宽高，缩放填充。
     * fixed：固定宽高，强制缩放。
     */
    m: 'lfit' | 'mfit' | 'fill' | 'pad' | 'fixed';
    /** 指定目标缩略图的宽度 1~4096 */
    w: number;
    /** 指定目标缩略图的高度 1~4096 */
    h: number;
    /** 倍数百分比。小于100为缩小，大于100为放大 */
    p: number;
    /** 指定目标缩略图的最长边 1~4096 */
    l: number;
    /** 指定目标缩略图的最短边 1~4096 */
    s: number;
    /** 指定当目标缩略图大于原图时是否处理。值是1表示不处理；值是0表示处理 。默认值：1 */
    limit: number;
    /**
     * 当缩放模式选择为pad（缩放填充）时，可以选择填充的颜色，默认是白色
     *
     * 参数的填写方式：采用16进制颜色码表示，例如00FF00（绿色）
     */
    color: string;
  }>,
  isFormatJPG: boolean = false,
): string => {
  if (!(imgUrl && typeof imgUrl === 'string')) return '';

  const separator = imgUrl.indexOf('?') < 0 ? '?' : '&';
  const imgSrc = imgUrl.replace(/^http:/, 'https:'); // http -> https

  const optionsList = [];
  if (options.p) {
    optionsList.push(`p_${options.p}`);
    return `${imgSrc}${separator}x-oss-process=image/resize,${optionsList.join(',')}`;
  }

  if (options.m) {
    optionsList.push(`m_${options.m}`);
  }

  if (options.w) optionsList.push(`w_${options.w}`);
  if (options.h) optionsList.push(`h_${options.h}`);
  if (options.l) optionsList.push(`l_${options.l}`);
  if (options.s) optionsList.push(`s_${options.s}`);
  if (options.limit === 0 || options.limit === 1) optionsList.push(`limit_${options.limit}`);
  if (options.color) optionsList.push(`color_${options.color}`);

  let result = `${imgSrc}${separator}x-oss-process=image/resize,${optionsList.join(',')}/quality,Q_80`;
  if (isFormatJPG) result = `${result}/format,jpg`;

  return result;
};

/**
 * 开发环境没有缓存
 *
 * 生产环境每 5 分钟更新图片缓存
 */
function getVersion() {
  if (process.env.NODE_ENV === 'development') return Date.now();

  const fiveMinutes = 1000 * 60 * 5;
  return `${process.env.REACT_APP_VERSION}_${Math.ceil(Date.now() / fiveMinutes)}`;
}

/**
 * 获取资源路径
 * @param relativePath 相对(`https://www.atrenew.com/resources`)路径：
 * @returns 处理后的路径
 */
export function ossImg(relativePath: string, options?: { width?: number }) {
  if (!relativePath) return '';
  const dpr = isSmall() ? window.devicePixelRatio : 1;
  const url = relativePath.startsWith('http')
    ? relativePath
    : `${process.env.REACT_APP_RESOURCE}${relativePath}`;

  const separator = url.indexOf('?') >= 0 ? '&' : '?';
  if (!options) return `${url}${separator}_v=${getVersion()}`;
  const { width = 0 } = options;
  return ossIMGResize(url, {
    m: 'lfit',
    w: Math.ceil(Number(width * Math.max(1, dpr))),
  }, false);
}
