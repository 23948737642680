/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import SwiperInstance from 'components/Swiper/Index';
import classNames from 'classnames';

import { Modal } from 'antd';
import a1 from '../assets/banner/a1.jpeg';
import a2 from '../assets/banner/a2.png';
import a3 from '../assets/banner/a3.png';
import imgArr from '../assets/code';
import shadowBanner from '../assets/banner/shadow-banner.png';

import download from '../assets/icons/download-icon.png';
import qrcode from '../assets/icons/qrcode-icon.png';
import wechat from '../assets/icons/wechat-icon.png';
import closeIcon from '../assets/icons/close-icon.png';

import classes from './Banner.module.scss';

const banners = [{
  imageUrl: a1,
  key: 'a1',
}, {
  imageUrl: a2,
  key: 'a2',
}, {
  imageUrl: a3,
  key: 'a3',
  videoUrl: 'https://sr.aihuishou.com/c2b/zy-fe/aihuishou-pc/home/ahs-Introduction.mp4',
}];

function Banner() {
  const titleArr = ['下载官方app', '扫码估价下单', '扫码关注'];
  const descArr = ['下单享回收大礼包', '下单享回收大礼包', '订阅更多惊喜福利'];
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [currentCode, setCurrentCode] = useState(1);
  const [videoVisible, setVideoVisible] = useState(false);
  const [codeVisible, setCodeVisible] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const swiperItem = useMemo(() => banners[swiperIndex], [ swiperIndex ]);

  useEffect(() => {
    if (!videoVisible) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  }, [ videoVisible ]);

  const toggleVideoModal = useCallback((newIndex: number) => {
    if (newIndex !== 2) return;
    setVideoVisible(!videoVisible);
  }, []);

  return (
    <div
      className={classes.Banner}
    >
      <div className={classes.wrapper}>
        <SwiperInstance
          onChange={(index) => setSwiperIndex(index)}
          visible={videoVisible}
        >
          {
            banners.map((banner, index) => (
              <SwiperInstance.Item key={banner.key} onClick={() => toggleVideoModal(index)}>
                <div className={
                  classNames([
                    classes.slideItem,
                    classes.pointer,
                  ])
                }
                >
                  <img
                    src={banner.imageUrl}
                    alt=''
                    className={classes.banner}
                  />
                </div>
              </SwiperInstance.Item>
            ))
          }
        </SwiperInstance>
        <img
          src={shadowBanner}
          alt=''
          className={classes.shadowBanner}
        />
        <div className={classes.download}>
          <div
            className={classes.button}
            onClick={
              () => {
                setCurrentCode(1);
                setCodeVisible(true);
              }
            }
          >
            APP下载
            <img src={download} alt='' />
          </div>
          <div
            className={classes.button}
            onClick={
              () => {
                setCurrentCode(2);
                setCodeVisible(true);
              }
            }
          >
            微信小程序
            <img src={qrcode} alt='' />
          </div>
          <div
            className={classes.button}
            onClick={
              () => {
                setCurrentCode(3);
                setCodeVisible(true);
              }
            }
          >
            微信公众号
            <img src={wechat} alt='' />
          </div>
        </div>
      </div>
      <Modal
        title=''
        open={videoVisible}
        footer={[]}
        onCancel={() => { setVideoVisible(false); }}
        centered
        closeIcon={null}
        width={800}
        className={classes.videoModal}
      >
        <video
          src={swiperItem?.videoUrl}
          controls
          preload='load'
          ref={videoRef}
        />
      </Modal>
      <Modal
        title={
          (
            <div
              style={
                {
                  textAlign: 'center',
                  color: '#373A36',
                  fontSize: '22px',
                  fontWeight: 600,
                  marginBottom: 0,
                  paddingBottom: '20px',
                  borderBottom: '1px solid #ECECEC',
                }
              }
            >
              {titleArr[currentCode - 1]}
            </div>
          )
        }
        open={codeVisible}
        footer={[]}
        onCancel={() => { setCodeVisible(false); }}
        width={435}
        centered
        destroyOnClose
        bodyStyle={
          {
            height: 340,
            textAlign: 'center',
          }
        }
        closeIcon={
          (
            <img
              src={closeIcon}
              style={
                {
                  height: '20px',
                  width: '20px',
                }
              }
              alt='关闭'
            />
          )
        }
      >
        <img src={imgArr[currentCode - 1]} alt='' className={classes.helpCode} />
        <p className={classes.desc}>{descArr[currentCode - 1]}</p>
      </Modal>
    </div>
  );
}

export default Banner;
