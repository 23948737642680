import React from 'react';

import Banner from './components/Banner';
import Category from './components/Category/Index';
import MoreWay from './components/MoreWay/Index';
import MoreServices from './components/MoreService/Index';
import Charity from './components/Charity/Index';
import Partner from './components/Partner/Partner';
import Download from './components/Download/Index';

import classes from './Index.module.scss';

function Home() {
  return (
    <div className={classes.Home}>
      <Banner />
      <Category />
      <MoreWay />
      <MoreServices />
      <Charity />
      <Partner />
      <Download />
    </div>
  );
}

export default Home;
