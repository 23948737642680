/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import type { ReactNode } from 'react';
import React, { useEffect, useMemo, useRef } from 'react';
import type { SwiperRefNode } from 'react-id-swiper';
import Swiper from 'react-id-swiper';
import classNames from 'classnames';
import classes from './Index.module.scss';

interface ItemProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

function SwiperItem(props: ItemProps) {
  const { onClick } = props;
  return (
    <div
      className={
        classNames(
          'swiper-slide',
          classes.swiperItem,
          props.className,
        )
      }
      onClick={() => onClick?.()}

    >
      {props.children}
    </div>
  );
}

type Children = ReturnType<typeof SwiperItem>;

interface Props {
  onChange: (index: number) => void;
  children: Array<Children>;
  className?: string;
  visible?: boolean;
}

function SwiperInstance(props: Props) {
  const {
    onChange,
    children,
    className,
    visible,
  } = props;
  const swiperRef = useRef<SwiperRefNode>(null);
  const availableChildren = useMemo(
    () => children.filter((child) => child.type === SwiperItem),
    [ children ],
  );
  useEffect(
    () => {
      const swiperInstance = swiperRef.current?.swiper;
      if (swiperInstance) {
        swiperInstance.on('slideChange', () => {
          onChange(swiperInstance.activeIndex);
        });
      }

      return () => {
        if (swiperInstance) {
          swiperInstance.off('slideChange');
        }
      };
    },
    [
      onChange,
      availableChildren,
    ],
  );

  useEffect(
    () => {
      const swiperInstance = swiperRef.current?.swiper;
      if (swiperInstance) {
        if (visible) {
          swiperInstance?.autoplay?.stop();
        } else {
          swiperInstance?.autoplay?.start();
        }
      }
    },
    [
      visible,
    ],
  );

  useEffect(() => {
    // @ts-ignore
    swiperRef.current?.swiper?.init();
  }, []);

  return (
    <div className={classes.bannerSwiper}>
      <Swiper
        ref={swiperRef}
        init={false}
        effect='fade'
        speed={1200}
        autoplay={
          children.length > 1 ? {
            disableOnInteraction: false,
            delay: 5000,
          } : false
        }
        pagination={
          {
            type: 'bullets',
            clickable: true,
            el: '.swiper-pagination',
          }
        }
        containerClass={classNames(className, classes.swiperBox)}
      >
        {children}
      </Swiper>
    </div>
  );
}

SwiperInstance.Item = SwiperItem;
export default SwiperInstance;
