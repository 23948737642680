/* eslint-disable max-len */
import React from 'react';

import classes from './VendorPerson.module.scss';

function VendorPersonDate() {
  return (
    <div className={classes.help}>
      <div className={classes.mainSection}>
        <div className={classes.privacyContent}>
          <h4 className={classes.title}>
            个人信息共享清单
          </h4>
          <p>
            为保障爱回收App 相关功能（比如帐号登录、支付、消息推送、运营统计、安全防护等功能）的实现与应用安全稳定的运行，我们的产品可能会包含由第三方提供的软件开发包（SDK）或我们可能会使用其他第三方产品/服务以实现前述目的。合作方可能有其独立的隐私政策和用户协议，我们建议您阅读并遵守第三方的用户协议及个人信息保护政策。
          </p>
          <p>
            <strong>一、 第三方 SDK</strong>
            <br />
            <strong className={classes.emphasize}>
              第三方 SDK 收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的 Cookie 或使用其他技术手段，这些 Cookie 或其他技术手段不受我们的控制，且它们的使用不受本政策的约束。为了最大程度保障您的信息安全，我们建议您在使用任何第三方 SDK 类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这等 SDK 或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系。
              <br />
              为了向您提供更多服务、持续优化我们提供服务的稳定性、提升服务质量或者升级相关功能，我们可能会调整我们接入的第三方 SDK 并更新 SDK 目录。我们会严格遵守相关法律法规与监管要求，在接入 SDK 前评估第三方 SDK 收集您的个人信息的合法性、正当性、必要性，以保护您的个人信息。以下为第三方 SDK 的具体信息：
              <br />
            </strong>
          </p>
          <p className={classes.underLine}>
            <strong>1. 百度地图定位SDK（Android 端、iOS 端)</strong>
            <br />
            第三方公司名称：北京百度网讯科技有限公司
            <br />
            使用目的：向用户提供附近爱回收线下门店的距离、地理位置信息、上门人员位置
            <br />
            使用场景：为用户展示门店距离、门店信息、上门人员位置信息时
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>WLAN状态信息、BSSID、位置信息、MAC地址、Android ID、IDFA、设备型号、读取SIM卡状态、扫描wifi列表、获取wifi名称</strong>
            <br />
            使用的敏感权限：
            <strong>位置权限、存储权限</strong>
            <br />
            第三方隐私政策或官网链接：https://lbs.baidu.com/index.php?title=openprivacy
          </p>
          <p>
            <strong>2. 个推（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：每日互动股份有限公司
            <br />
            使用目的：消息推送
            <br />
            使用场景：用户开启接收通知推送后可正常接收重要推送通知消息
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备识别码（包含IMEI、IDFA、Android ID、MAC、OAID、SN、IMSI、UAID、SSID、BSSID）、网络信息（包含IP地址、WIFI状态信息、WIFI BSSID、WIFI SSID、基站信息）、粗略位置、</strong>
            <strong className={classes.underLine}>位置信息、</strong>
            <strong>应用列表信息</strong>
            <br />
            使用的敏感权限：
            <strong>推送权限、存储权限</strong>
            <br />
            第三方隐私政策或官网链接：https://docs.getui.com/privacy/
          </p>
          <p className={classes.underLine}>
            <strong>3. Alipay（支付宝）SDK（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：支付宝（中国）网络技术有限公司
            <br />
            使用目的：支持使用支付宝支付
            <br />
            使用场景：用户使用支付宝支付或提现时
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备信息（IMEI/IMSI、SIM卡序列号/MAC地址、android_id、SSID、BSSID、OAID、IDFA、IDFV、设备基础信息【机型\厂商】）、IP地址、网络信息、运营商信息、已安装应用包名信息、读取传感器列表</strong>
            <br />
            使用的敏感权限：
            <strong>存储权限</strong>
            <br />
            第三方隐私政策或官网链接：https://docs.open.alipay.com/54
          </p>
          <p>
            <strong>4. 神策数据 SDK（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：神策网络科技（北京）有限公司
            <br />
            使用目的：运营数据分析
            <br />
            使用场景：用户访问页面时收集非用户个人敏感信息的访问数据时使用
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备信息（IMEI、MAC地址、android_id、IDFA、IDFV、OAID、设备基础信息【机型\厂商】）、网络信息</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://www.sensorsdata.cn/market/privacy_policy.html
          </p>
          <p>
            <strong>5. Sentry错误统计（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：Sentry
            <br />
            使用目的：异常错误数据统计
            <br />
            使用场景：发生未知异常错误进行异常记录及上报时
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备基础信息【机型\厂商】、运行中进程信息、旋转矢量传感器、加速度传感器、线性加速传感器、陀螺仪、传感器列表、IDFA、IDFV</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://sentry.io/privacy/
          </p>
          <p>
            <strong>6. 中国移动一键登录（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：中国移动通信集团有限公司
            <br />
            使用目的：电话号码一键登录
            <br />
            使用场景：用户进行电话号码一键登录时
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>本机号码、手机设备类型、手机操作系统、硬件厂商、国际移动设备识别码、运营商类型、网络类型、sim卡数量、国际移动用户识别码、网络地址、屏幕尺寸、屏幕分辨率</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://wap.cmpassport.com/resources/html/contract.html
          </p>
          <p>
            <strong>7. 中国联通一键登录（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：中国联合网络通信有限公司
            <br />
            使用目的：电话号码一键登录
            <br />
            使用场景：用户进行电话号码一键登录时
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>网络类型、网络地址、运营商类型、本机手机号、国际移动用户标识码、sim卡数量、手机设备类型、手机操作系统、硬件厂商、屏幕尺寸、屏幕分辨率信息</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true
          </p>
          <p>
            <strong>8. 中国电信一键登录（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：中国电信集团有限公司
            <br />
            使用目的：电话号码一键登录
            <br />
            使用场景：用户进行电话号码一键登录时
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>本机号码、国际移动用户标识码、应用进程信息、网络连接类型、网络状态信息、网络地址、运营商类型、手机设备类型、手机设备厂商、手机操作系统类型及版本、网络IP地址、网络类型</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://e.189.cn/sdk/agreement/detail.do?hidetop=true
          </p>
          <p>
            <strong>9. 阿里云Web应用防火墙APP防护SDK（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：阿里云计算有限公司
            <br />
            使用目的：对业务流量进行恶意特征识别及防护
            <br />
            使用场景：客户端正常运行时进行防护
            <br />
            使用频次：每小时10次以下的数据采集（包含静默状态），收集的信息包含（电话状态采集设备标识符、接入网络的方式、类型和状态，Android ID）
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备标识符（MAC&nbsp;地址、IMEI、android_id、IDFA、OAID）、网络信息、</strong>
            <strong className={classes.underLine}>位置信息、</strong>
            <strong>设备基础信息（品牌、型号、操作系统）、读取传感器列表、设备加速度器软硬件信息</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202103181143_65567.html?spm=a2c4g.11186623.0.0.3a262a4a5lcYAw
          </p>
          <p>
            <strong>10. 360加固保SDK（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：北京鸿享技术服务有限公司
            <br />
            使用目的：防止应用被反编译和恶意篡改
            <br />
            使用场景：客户端正常运行时进行防护
            <br />
            使用频次：每20分钟获取1次Android ID（包含静默状态）
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备基础信息（Android ID、设备标识符的MD5值、OAID、AAID、网络类型、系统运行语言环境、操作系统版本名称、操作系统版本、设备型号、系统版本、厂商、终端主板名称、品牌、设备内存大小、手机屏幕分辨率、运行环境）、网络信息（DNS 服务器IP、是否开启VPN）、运营商信息、SIM卡状态信息、已安装应用包名信息、wifi状态信息、获取wifi参数（SSID、BSSID）</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://jiagu.360.cn/#/global/help/295
          </p>
          <p className={classes.underLine}>
            <strong>11. 微信开放平台 SDK（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：深圳市腾讯计算机系统有限公司
            <br />
            使用目的：支持微信分享、微信支付
            <br />
            使用场景：用户使用分享到微信功能、使用微信支付或提现时
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>手机 WIFI 信息、MAC 地址、手机 ID 信息、已安装应用包名信息</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl
          </p>
          <p>
            <strong>12. React Native SDK（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：Facebook Inc.
            <br />
            使用目的：辅助开发
            <br />
            使用场景：图片显示、页面搭建
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备序列号、IMEI、IMSI、旋转矢量传感器、加速度传感器、线性加速传感器、陀螺仪</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://zh-cn.facebook.com/privacy/explanation
          </p>
          <p>
            <strong>13. Chromium SDK（Android 端）</strong>
            <br />
            第三方公司名称：Google Inc.
            <br />
            使用目的：用于辅助开发
            <br />
            使用场景：开发框架
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备版本、IMSI、设备应用安装列表、网络设备制造商、MAC地址、旋转矢量传感器、加速度传感器、线性加速传感器、陀螺仪</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://policies.google.cn/privacy
          </p>
          <p>
            <strong>14. 华为推送 SDK（Android 端）</strong>
            <br />
            第三方公司名称：华为软件技术有限公司
            <br />
            使用目的：华为厂商推送
            <br />
            使用场景：华为手机用户接收App推送消息
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>应用匿名标识（AAID）、用户的标识符HMS Core openId、SIM卡序列号、运营商信息</strong>
            <br />
            使用的敏感权限：
            <strong>推送权限</strong>
            <br />
            第三方隐私政策或官网链接：https://developer.huawei.com/consumer/cn/doc/HMSCore-Guides/sdk-data-security-0000001050042177
          </p>
          <p>
            <strong>15. 小米推送 SDK（Android 端）</strong>
            <br />
            第三方公司名称：小米科技有限责任公司
            <br />
            使用目的：小米厂商推送
            <br />
            使用场景：小米手机用户接收App推送消息
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>OAID、加密的Android ID、应用基本信息（包括应用包名、版本号和运行状态）、设备相关信息（设备厂商、设备型号、设备内存、操作系统版本、小米推送SDK版本、设备归属地、SIM卡序列号、运营商信息、当前网络类型、通知栏设置信息）</strong>
            <br />
            使用的敏感权限：
            <strong>推送权限</strong>
            <br />
            第三方隐私政策或官网链接：https://dev.mi.com/console/doc/detail?pId=1822
          </p>
          <p>
            <strong>16. 魅族推送 SDK（Android 端）</strong>
            <br />
            第三方公司名称：珠海市魅族通讯设备有限公司
            <br />
            使用目的：魅族厂商推送
            <br />
            使用场景：魅族手机用户接收App推送消息
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备信息（设备名称、设备型号、地区和语言设置、设备识别码、IMEI、设备硬件信息与状态、使用习惯、IP 地址、Mac 地址、操作系统版本）、应用信息（应用列表、应用程序版本、应用状态记录、软件识别码、以及应用设置信息）、</strong>
            <strong className={classes.underLine}>位置信息（设备位置、WiFi地理位置信息）</strong>
            <br />
            使用的敏感权限：
            <strong>推送权限</strong>
            <br />
            第三方隐私政策或官网链接：https://i.flyme.cn/privacy
          </p>
          <p>
            <strong>17. OPPO推送 SDK（Android 端）</strong>
            <br />
            第三方公司名称：广东欢太科技有限公司
            <br />
            使用目的：OPPO厂商推送
            <br />
            使用场景：OPPO手机用户接收App推送消息
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备相关信息（IMEI或OAID，Serial Number，IMSI，User ID，Android ID，Google Advertising ID, 手机Region设置，设备型号，手机电量，手机操作系统版本及语言）、使用推送服务的应用信息（APP包名及版本号，运行状态）、推送SDK版本号、网络相关信息（IP或域名连接结果，当前网络类型）、消息发送结果、通知栏状态（通知栏权限、用户点击行为），锁屏状态（如是否锁屏，是否允许锁屏通知）</strong>
            <br />
            使用的敏感权限：
            <strong>推送权限</strong>
            <br />
            第三方隐私政策或官网链接：https://open.oppomobile.com/wiki/doc#id=10288
          </p>
          <p>
            <strong>18. VIVO推送 SDK（Android 端）</strong>
            <br />
            第三方公司名称：维沃移动通信有限公司
            <br />
            使用目的：VIVO厂商推送
            <br />
            使用场景：VIVO手机用户接收App推送消息
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备类型、设备型号、操作系统</strong>
            <br />
            使用的敏感权限：
            <strong>推送权限</strong>
            <br />
            第三方隐私政策或官网链接：https://www.vivo.com.cn/about-vivo/privacy-policy
          </p>
          <p>
            <strong>19. 荣耀推送 SDK（Android 端）</strong>
            <br />
            第三方公司名称：荣耀终端有限公司
            <br />
            使用目的：荣耀厂商推送
            <br />
            使用场景：荣耀手机用户接收App推送消息
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备信息、应用的基本信息</strong>
            <br />
            使用的敏感权限：
            <strong>推送权限</strong>
            <br />
            第三方隐私政策或官网链接：https://developer.hihonor.com/cn/kitdoc?category=base&kitId=11002&navigation=guides&docId=sdk-data-security.md
          </p>
          <p>
            <strong>20. 移动智能终端补充设备标识体系统一调用SDK（Android 端）</strong>
            <br />
            第三方公司名称：中国信息通信研究院
            <br />
            使用目的：实现Android系统补充设备标识体系的统一调用
            <br />
            使用场景：Android客户端获取系统补充设备标识符时
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>设备制造商、设备型号、设备品牌、设备网络运营商名称、OAID、VAID、AAID、已安装应用包名信息及签名信息，或在对应应用商店的APPID</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：http://www.msa-alliance.cn/col.jsp?id=122
          </p>
          <p>
            <strong>21. 环信即时通讯SDK（Android 端、iOS 端）</strong>
            <br />
            第三方公司名称：上海兆言网络科技有限公司
            <br />
            使用目的：支持用户之间进行即时通讯
            <br />
            使用场景：附近二手闲置商品交易中，买卖双方进行私聊
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：
            <strong>用户ID、APPID（应用包名）、设备随机ID、设备硬件类型、设备型号、系统类型、SDK版本、设备网络类型、登录用时</strong>
            <br />
            使用的敏感权限：
            <strong>存储权限</strong>
            <br />
            第三方隐私政策或官网链接：https://www.easemob.com/protocol
          </p>
          <p>
            <strong>22. RxJava（Android 端）</strong>
            <br />
            第三方主体：ReactiveX
            <br />
            使用目的：RxJava主要用于进行异步操作，属于app行为
            <br />
            使用场景：发起网络请求时
            <br />
            共享方式：本机自行收集
            <br />
            共享信息名称：无
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://github.com/ReactiveX/RxJava
          </p>
          <p>
            <strong>23. RxPermission（Android 端）</strong>
            <br />
            第三方主体：个人开发者（tbruyelle等）
            <br />
            使用目的：RxPermission用于异步申请Android系统权限，RxPermission SDK内部逻辑不会涉及应用权限的申请，它只是一个帮助申请系统权限的工具
            <br />
            使用场景：需要申请系统权限时，如相机、推送等
            <br />
            共享方式：本机自行收集
            <br />
            共享信息名称：无
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://github.com/tbruyelle/RxPermissions
          </p>
          <p>
            <strong>24. RxBinding（Android 端）</strong>
            <br />
            第三方主体：个人开发者（jakewharton等）
            <br />
            使用目的：RxBinding是一个Android UI交互框架，方便编写各种组件的交互事件。
            <br />
            使用场景：UI绘制和交互时
            <br />
            共享方式：本机自行收集
            <br />
            共享信息名称：无
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://github.com/JakeWharton/RxBinding
          </p>
          <p>
            <strong>25. 邦盛设备指纹SDK（微信小程序端）</strong>
            <br />
            第三方公司名称：浙江邦盛科技股份有限公司
            <br />
            使用目的：用于账号登录风险控制
            <br />
            使用场景：营销反欺诈场景
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：设备基础信息（品牌、型号、屏幕分辨率、电量、亮度、充电状态等）、系统基础信息（系统版本、微信版本号等）、运营商信息（网络型号、统一匿名标识等）
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://www.bsfit.com.cn/news/438
          </p>
          <p>
            <strong>26. 巨量引擎转化SDK（Android 端、iOS端）</strong>
            <br />
            第三方公司名称：北京巨量引擎网络技术有限公司
            <br />
            使用目的：为了更好地向用户提供服务，会收集用户注册巨量引擎广告投放平台网站时的注册信息
            <br />
            使用场景：用户点击投放广告
            <br />
            共享方式：第三方SDK 自行收集
            <br />
            共享信息名称：设备信息（【双端】设备品牌、型号、操作系统、软件系统版本、分辨率、网络信号强度、IP地址、设备语言、浏览器的类型和版本号、操作、使用、服务日志等基础信息【仅Android】AndroidID、OAID【仅iOS】手机系统重启时间、磁盘总空间、系统总内存空间、CPU数目等基础信息、IDFV）、应用信息（【仅Android】开发者应用名、应用包名、运行中的进程信息、版本号、应用前后台状态）、广告信息（【双端】对广告的展示、点击及转化等交互数据）、位置信息、性能数据（【双端】如崩溃数据、性能数据）、其他信息（【双端】运营商信息、设备时区、sim卡信息）
            <br />
            使用的敏感权限：READ_PHONE_STATE、ACCESS_WIFI_STATE
            <br />
            第三方隐私政策或官网链接：https://bytedance.larkoffice.com/docx/PfdrdczIHo0wjex3Qwdce5wYnzh
          </p>
          <p>
            <strong>二、非 SDK 主体</strong>
            <br />
          </p>
          <p>
            <strong>1. 顺丰速运</strong>
            <br />
            第三方公司名称：顺丰速运有限公司
            <br />
            使用目的：支持顺丰上门收取旧机或配送
            <br />
            使用场景：用户提交快递上门订单时
            <br />
            共享方式：通过去标识化、加密传输和处理的安全处理方式
            <br />
            共享信息名称：
            <strong>取件\收货地址、联系人及联系方式</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://www.sf-express.com/cn/sc/Privacy_Policy/
            <br />
          </p>
          <p>
            <strong>2. Udesk智能客服</strong>
            <br />
            第三方公司名称：北京沃丰时代数据科技有限公司
            <br />
            使用目的：提供在线客服对话能力
            <br />
            使用场景：用户每次使用在线客服功能时
            <br />
            共享方式：第三方网页自行收集
            <br />
            共享信息名称：
            <strong>设备型号</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://www.udesk.cn/terms_service.html
          </p>
          <p>
            <strong>3. 爱回收爱分类</strong>
            <br />
            第三方公司名称：上海悦鲲环保科技有限公司
            <br />
            使用目的：提供用户使用线下回收机的能力
            <br />
            使用场景：用户每次使用爱分类回收机时
            <br />
            共享方式：通过去标识化、加密传输和处理的安全处理方式
            <br />
            <text className={classes.underLine}>共享信息名称：</text>
            <strong className={classes.underLine}>爱回收账号，位置信息，订阅信息</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://www.aifenlei.com/law/privacy
          </p>
          <p>
            <strong>4. 叮咚买菜</strong>
            <br />
            第三方公司名称：上海壹佰米网络科技有限公司
            <br />
            使用目的：用户参与合作活动时进行奖励发放
            <br />
            使用场景：合作活动联合登录时
            <br />
            共享方式：通过去标识化、加密传输和处理的安全处理方式
            <br />
            <text className={classes.underLine}>共享信息名称：</text>
            <strong className={classes.underLine}>注册手机号</strong>
            <br />
            使用的敏感权限：无
            <br />
            第三方隐私政策或官网链接：https://cms.api.ddxq.mobi/cms-service/client/page/v1/getPageInfo?cmsTag=preload&is_nav_hide=true&themeColor=&uuid=798d26d7bc994f31
          </p>
        </div>
      </div>
    </div>
  );
}

export default VendorPersonDate;
